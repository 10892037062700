import styles from "./DashboardSlider.module.css";
import React, { useCallback, useEffect, useState } from "react";

import { Menu, Modal, Layout, Avatar, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  ContainerOutlined,
  PieChartOutlined,
  UsergroupAddOutlined,
  HomeOutlined,
  UnorderedListOutlined,
  ReconciliationOutlined,
  SettingFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";

import CreatableSelect from "react-select/creatable";
import { useNavigate } from "react-router-dom";
import { getrequest, postrequest } from "../Middleware/managerequest";
import { setCategory } from "../../store/Reducer";
const { Sider } = Layout;
const DashboardSlider = ({ customStyles, activekey }) => {
  const data = useSelector((state) => state.Data);
  const { user, clientdetails, access, category } = data;
  const [messageApi, contextHolder] = message.useMessage();
  const { imagesurl } = useSelector((state) => state.StudentProfile);
  const [categoryList, setCategoryList] = useState([]);
  const [Segment, setSegment] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [IsSegment, setIsSegment] = useState(false);
  // Warning
  const notificationmessage = useCallback(
    (val, err) => {
      messageApi.open({
        type: err,
        content: val,
      });
    },
    [messageApi]
  );

  // Menu
  useEffect(() => {
    let list = category.map((ele) => {
      return {
        isDisabled: true,
        label: ele,
        value: ele,
      };
    });
    setCategoryList(list);
  }, []);
  const updateCategory = () => {
    getrequest(`/viewmocks/${user?.subdomain}/?pageno=${"1"}`)
      .then((res) => {
        dispatch(setCategory(res.data?.allcategory?.Category));
      })
      .catch((err) => {
        notificationmessage("server error code-174");
      });
  };
  //  Get Particular Category

  useEffect(() => {
    if (user) {
      updateCategory();
    }
  }, [user]);
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const menuItemsToDisplay = [
    getItem(
      <span className={styles.menulabel}>Dashboard</span>,
      "dashboard",
      <HomeOutlined style={{ fontWeight: "600", fontSize: "17px" }} />
    ),
    getItem(
      <span className={styles.menulabel}>Create One Time Test</span>,
      "createmock",
      <ContainerOutlined style={{ fontWeight: "600", fontSize: "17px" }} />,
      null,
      null,
      true
    ),
    getItem(
      <span className={styles.menulabel}>Test Bank</span>,
      "section",
      <PieChartOutlined style={{ fontWeight: "600", fontSize: "17px" }} />,
      [
        ...category?.slice(1)?.map((ele, index) => {
          return getItem(ele, ele);
        }),
        getItem(
          <span className={styles.menulabel}>Create Segment</span>,
          "Create Segment",
          <PlusCircleOutlined
            style={{ fontWeight: "600", fontSize: "17px" }}
          />,
          null,
          null,
          true
        ),
      ]
    ),
    getItem(
      <span className={styles.menulabel}>Question Bank</span>,
      "QuestionBank",
      <UnorderedListOutlined style={{ fontWeight: "600", fontSize: "17px" }} />,
      [
        getItem("Personal Bank", "personal", null, null, null),
        user?.subdomain !== "main" &&
          getItem("Global Bank", "global", null, null, null),
      ],
      "groups"
    ),
    access &&
      access?.role === "Admin" &&
      getItem(
        <span className={styles.menulabel}>Manage Users</span>,
        "manageusers",
        <SettingFilled style={{ fontWeight: "600", fontSize: "17px" }} />,
        null,
        null
      ),
    clientdetails?.isgroup &&
      getItem(
        <span className={styles.menulabel}>Batch</span>,
        "managegroups",
        <UsergroupAddOutlined
          style={{ fontWeight: "600", fontSize: "17px" }}
        />,
        null,
        null
      ),
    clientdetails?.isgroup &&
      getItem(
        <span className={styles.menulabel}>Test Series</span>,
        "testseries",
        <UsergroupAddOutlined
          style={{ fontWeight: "600", fontSize: "17px" }}
        />,
        null,
        null
      ),
    "main" === user?.subdomain &&
      getItem(
        <span className={styles.menulabel}>Manage Organizations</span>,
        "my_organizations",
        <UsergroupAddOutlined
          style={{ fontWeight: "600", fontSize: "17px" }}
        />,
        null,
        null
      ),
    access &&
      access?.role === "Admin" &&
      getItem(
        <span className={styles.menulabel}>Logs</span>,
        "logs",
        <SettingFilled style={{ fontWeight: "600", fontSize: "17px" }} />,
        null,
        null
      ),
  ];
  // Sidebar Navigation
  const handlerClick = (e) => {
    if (
      [
        "createmock",
        "managegroups",
        "manageusers",
        "my_organizations",
        "testseries",
        "logs",
      ].includes(e.key)
    ) {
      navigate(`/${e.key}`);
    } else if (e.key === "Create Segment") {
      setIsSegment(true);
    } else if (e.key === "dashboard") {
      navigate("/admin");
    } else if (e.key === "personal" || e.key === "global") {
      navigate(`/questions?type=${e.key}`);
    } else {
      navigate(`/predefinetest?category=${e.key}`);
    }
  };
  // Create new segment
  const createNewSegment = () => {
    postrequest(`/createnewcat/${user?.subdomain}`, {
      newcat: Segment?.label,
    })
      .then((res) => {
        setIsSegment(false);
        updateCategory();
        notificationmessage("New Segement created successfully.", "success");
      })
      .catch((err) => {
        notificationmessage("Error while creating createNewSegment", "error");
      });
  };
  return (
    <div className={styles.DashboardBox}>
      {contextHolder}
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        width={"100%"}
        style={{ backgroundColor: "#ffffff", height: "100vh" }}
      >
        <div className={styles.userProfile}>
          <Avatar
            src={imagesurl ?? ""}
            size={80}
            icon={<UserOutlined style={{ color: "black" }} />}
          />
          <label style={{ color: "black" }}>
            {user?.FullName?.length >= 14
              ? user?.FullName.trim().split(" ")[0]
              : user?.FullName}
          </label>
          <span style={{ color: "black" }}>{user?.Email}</span>
        </div>
        <Menu
          onClick={(e) => {
            handlerClick(e);
          }}
          mode="vertical"
          theme="light"
          items={menuItemsToDisplay}
          className={styles.menuitems}
          selectedKeys={activekey}
        />
      </Sider>
      {/* Add Segments */}
      <Modal
        title="Create Segment"
        onCancel={() => setIsSegment(false)}
        open={IsSegment}
        onOk={() => createNewSegment()}
        centered
        width={500}
        maskClosable={false}
      >
        <CreatableSelect
          options={categoryList}
          onChange={(e) => setSegment(e)}
          placeholder="Enter new segment name"
          isClearable={true}
          closeMenuOnSelect={true}
          isSearchable={true}
          isMulti={false}
          className="Add_user"
          styles={customStyles}
        />
      </Modal>
    </div>
  );
};

export default DashboardSlider;
