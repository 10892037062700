import React, { useState, useEffect, useCallback } from "react";

import { Avatar, Dropdown, message, Flex, Select } from "antd";
import "../../styles/manageuser.css";
// import Button from "react-bootstrap/Button";
import styles from "./manageusers.module.css";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  HomeOutlined,
  ContainerOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  MoreOutlined,
  UserOutlined,
} from "@ant-design/icons";
import DashboardSlider from "../Navigator_Footer/DashboardSlider";
import ProfileSlider from "../Navigator_Footer/ProfileSlider";
import {
  getrequest,
  postrequest,
  putrequest,
} from "../Middleware/managerequest";
import LatestNavbar from "../Navigator_Footer/LatestNavbar";
import { Layout, Table, Input, Button, Modal, Form } from "antd";
const { Sider, Content } = Layout;
const key = "change";
const Manageusers = () => {
  const [messageApi, contentHolder] = message.useMessage();
  const { access, IsSuperAdmin } = useSelector((state) => state.Data);
  const [IsLoading, setIsLoding] = useState(false);
  const data = useSelector((state) => state.Data);
  const { user } = data;
  const [userData, setUserdata] = useState([]);
  const [AdminData, setAdmindata] = useState([]);
  const [search, setSearch] = useState("");
  const [isuserreg, setIsuserreg] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [openDwa, setOpenDwa] = useState(false);
  const [menuKey, setMenuKey] = useState("");
  const [newuserdata, setNewUserdata] = useState({
    name: "",
    email: "",
    mobile: "",
    staffType: "",
  });
  const [show, setShow] = useState(-1);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    if (newuserdata.name === "") {
      setShow(0);
    } else if (newuserdata.email === "") {
      setShow(1);
    } else if (newuserdata.mobile === "" && newuserdata.mobile.length !== 12) {
      setShow(2);
    } else {
      setIsLoding(true);
      let obj = {
        name: newuserdata.name,
        email: newuserdata.email.toLowerCase(),
        mobile: "+" + newuserdata.mobile,
        role: newuserdata.staffType,
        subdomain: user?.subdomain,
      };

      putrequest(`/home/SignUp`, obj)
        .then((res) => {
          setIsLoding(false);
          if (res.status === 201) {
            fetcher("create");
            setIsModalOpen(false);
          }
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            messageApi.error({
              content: "User already Exist.",
              key,
            });
            setIsModalOpen(false);
          }

          if (err?.response?.status === 500) {
            setShow(1);
            setIsLoding(false);
            setIsModalOpen(false);
            setIsuserreg(true);
            toast.error("Server Not Responding", {
              position: "top-center",
              autoClose: 1500,
            });
          } else {
            setIsLoding(false);
            setIsModalOpen(false);
            toast.error("Server Not Responding", {
              position: "top-center",
              autoClose: 1500,
            });
          }
        });
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const fetcher = useCallback(
    (val) => {
      if (user) {
        setLoaded(true);
        getrequest(`/getallusers/${user?.subdomain}`)
          .then((res) => {
            setUserdata(res.data.students);
            if (val === "delete") {
              messageApi.success({
                content: "Deleted User Successfully.",
                key,
              });
            } else if (val === "change") {
              messageApi.success({
                content: "Changed Role Successfully.",
                key,
              });
            } else if (val === "create") {
              messageApi.success({
                content: "Created Staff Successfully.",
                key,
              });
            }

            setAdmindata(res.data.Admins);
            setLoaded(false);
          })
          .catch((err) => {
            console.log(err);
            setLoaded(false);
          });
      }
    },
    [user, messageApi]
  );

  useEffect(() => {
    fetcher();
  }, [fetcher]);

  const handlerClick = (e) => {
    setMenuKey(e.key);
    if (e.key === "dashboard") {
      navigate("/admin");
    } else if (e.key === "createtest") {
      navigate("/createmock");
    } else if (e.key === "Question Bank") {
      navigate("/questions");
    } else {
      navigate("/managegroups");
    }
    setOpenDwa(false);
  };

  const menuItems = [
    getItem(
      <span className={styles.menulabel}>Dashboard</span>,
      "dashboard",
      <HomeOutlined />
    ),
    getItem(
      <span className={styles.menulabel}>Create One Time Test</span>,
      "createtest",
      <ContainerOutlined />,
      null,
      null
    ),
    getItem(
      <span className={styles.menulabel}>Question Bank</span>,
      "QuestionBank",
      <UnorderedListOutlined style={{ fontWeight: "600", fontSize: "17px" }} />,
      null,
      null
    ),
    getItem(
      <span className={styles.menulabel}>Batches</span>,
      "Batches",
      <UsergroupAddOutlined style={{ fontWeight: "600", fontSize: "17px" }} />,
      null,
      null,
      true
    ),
  ];

  const DeleteUser = (email, role) => {
    if (access.role !== "Admin" && !IsSuperAdmin) {
      messageApi.warning({
        content: "You don't have permission to delete the user.",
      });
      return;
    }

    let confirm = window.confirm("Are you sure to delete the user");
    if (confirm) {
      let obj = {
        email: email.Email,
      };

      messageApi.loading({
        content: "Deleting User",
        key,
      });
      postrequest(
        `/delete/${user?.subdomain}/?isadmin=${role === "Mentor"}`,
        obj
      )
        .then(() => fetcher("delete"))
        .catch((err) => {
          messageApi.error({
            content: err.response.data,
            key,
          });
        });
    }
  };

  const ChangeRole = (email, value) => {
    if (access.role !== "Admin" && !IsSuperAdmin) {
      messageApi.warning({
        content: "You don't have permission to change the role.",
      });
      return;
    }
    let obj = {
      email: email,
      role: value,
    };

    messageApi.loading({
      content: "Changing Role",
      key,
    });
    postrequest(`/changerole/${user?.subdomain}`, obj)
      .then((res) => {
        fetcher("change");
      })
      .catch((err) => {
        messageApi.error({
          content: err.response.data,
          key,
        });
      });
  };

  const options = (role, email) => {
    return IsSuperAdmin
      ? [
          {
            key: 1,
            label:
              role === "User" ? (
                <label
                  style={{
                    cursor:
                      access.role !== "Admin" || !IsSuperAdmin
                        ? "not-allowed"
                        : "pointer",
                  }}
                  className={styles.dropdown_item}
                  onClick={() => ChangeRole(email, "Mentor")}
                >
                  Make Staff
                </label>
              ) : (
                <label
                  style={{
                    cursor: access.role !== "Admin" ? "not-allowed" : "pointer",
                  }}
                  onClick={() => ChangeRole(email, "User")}
                >
                  Make User
                </label>
              ),
          },
          {
            key: 3,
            label:
              role === "Admin" && IsSuperAdmin ? (
                <label
                  style={{
                    cursor: access.role !== "Admin" ? "not-allowed" : "pointer",
                  }}
                  onClick={() => ChangeRole(email, "Mentor")}
                >
                  Make Staff
                </label>
              ) : (
                <label
                  style={{
                    cursor: access.role !== "Admin" ? "not-allowed" : "pointer",
                  }}
                  onClick={() => ChangeRole(email, "Admin")}
                >
                  Make Admin
                </label>
              ),
          },
          {
            key: 2,
            label: (
              <label
                style={{
                  color: "red",
                  cursor: access.role !== "Admin" ? "not-allowed" : "pointer",
                }}
                onClick={() => DeleteUser(email, role)}
              >
                Delete
              </label>
            ),
          },
        ]
      : [
          {
            key: 1,
            label:
              role === "Mentor" ? (
                <label
                  style={{
                    cursor:
                      access.role !== "Admin" || !IsSuperAdmin
                        ? "not-allowed"
                        : "pointer",
                  }}
                  className={styles.dropdown_item}
                  onClick={() => ChangeRole(email, "User")}
                >
                  Make User
                </label>
              ) : (
                <label
                  style={{
                    cursor:
                      access.role !== "Admin" || !IsSuperAdmin
                        ? "not-allowed"
                        : "pointer",
                  }}
                  className={styles.dropdown_item}
                  onClick={() => ChangeRole(email, "Admin")}
                >
                  Make Admin
                </label>
              ),
          },
        ];
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 350,

      render: (text, { images }) => {
        return (
          <div>
            <Avatar
              size={35}
              src={images}
              icon={<UserOutlined />}
              className="mx-2"
            />
            <label>{text}</label>
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 300,
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      width: 200,
      align: "center",
    },
    {
      title: "Role",
      dataIndex: "role",
      align: "center",
      filters: [
        { value: "Mentor", text: "Mentor" },
        { value: "User", text: "User" },
        { value: "Admin", text: "Admin" },
      ],
      onFilter: (value, record) => record.role.indexOf(value) === 0,
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
    },
    {
      title: "",
      dataIndex: "action",
      align: "center",
      render: (text, { role, email, name }) => {
        return (
          (IsSuperAdmin || role !== "Admin") && (
            <Dropdown
              placement="left"
              menu={{ items: options(role, { Email: email, Name: name }) }}
              trigger={["click"]}
            >
              <MoreOutlined className={styles.menu} />
            </Dropdown>
          )
        );
      },
    },
  ];
  
  const tableData = () => {
    const filteredAdminData = IsSuperAdmin
      ? AdminData.filter((ele) => ele.Email !== user.Email)
      : AdminData;
    const filteredUserData = IsSuperAdmin
      ? userData.filter((ele) => ele.email !== user.Email)
      : userData;
    return (
      filteredUserData.length > 0 &&
      filteredUserData
        .filter(
          (item) =>
            item.FullName.includes(search) || item.email.includes(search)
        )
        .map((item) => {
          return {
            name: item.FullName,
            email: item.email,
            mobile: item.Mobile ?? "-",
            role:
              filteredAdminData.find((val) => val.Email === item.email)?.role ??
              "User",
            status: item?.status ?? "Active",
            images: item?.ImageURL ?? "",
            key: item.email,
          };
        })
    );
  };

  return (
    <>
      {contentHolder}
      <Layout>
        <Sider
          className={styles.slider}
          breakpoint="lg"
          collapsedWidth="0"
          width={window.innerWidth < 350 ? 200 : "18%"}
          trigger={null}
        >
          <DashboardSlider
            menuItems={menuItems}
            handlerClick={handlerClick}
            // setMenuKey={setMenuKey}
            activekey={menuKey}
          />
        </Sider>
        <Layout>
          <LatestNavbar openDwa={openDwa} setOpenDwa={setOpenDwa} />
          <Content style={{ paddingTop: "65px", width: "97%", margin: "auto" }}>
            <div>
              <Flex align="flex-start" gap="small">
                <Input
                  placeholder="Search by Name or Email"
                  allowClear
                  style={{ width: "200px", marginBottom: 16 }}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <Button type="primary" onClick={showModal}>
                  Add Staff
                </Button>
                <Modal
                  title="Add Organization User"
                  open={isModalOpen}
                  onCancel={handleCancel}
                  footer={[
                    <Button key="submit" type="primary" onClick={handleOk}>
                      Add User
                    </Button>,
                    <Button key="cancel" onClick={handleCancel}>
                      Cancel
                    </Button>,
                  ]}
                >
                  <Form
                    name="basic"
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Full Name"
                      name="username"
                      placeholder="What we call you ?"
                      rules={[
                        {
                          required: true,
                          message: "Please input your username!",
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => {
                          setNewUserdata({
                            ...newuserdata,
                            name: e.target.value,
                          });
                          setShow(-1);
                        }}
                        value={newuserdata.name}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your email!",
                          type: "email", // Ensures it's a valid email format
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => {
                          setNewUserdata({
                            ...newuserdata,
                            email: e.target.value,
                          });
                          setShow(-1);
                        }}
                      />
                    </Form.Item>

                    <Form.Item label="Staff Type" name="stafftype">
                      <Select
                        onChange={(e) => {
                          setNewUserdata({ ...newuserdata, staffType: e });
                          setShow(-1);
                        }}
                        placeholder="Select a role"
                      >
                        <option value="Admin">Admin</option>
                        <option value="Mentor">Mentor</option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      wrapperCol={{
                        offset: 8,
                        span: 16,
                      }}
                    >
                      <PhoneInput
                        country={"in"}
                        enableSearch={true}
                        inputStyle={{
                          width: "100%",
                          marginLeft: "0px",
                          height: "40px",
                          border: "1px solid #CEE8FF",
                          borderRadius: "10px",
                        }}
                        searchClass={styles.search}
                        onChange={(e) => {
                          setNewUserdata({ ...newuserdata, mobile: e });
                          setShow(-1);
                        }}
                        value={newuserdata.mobile}
                      />
                    </Form.Item>
                  </Form>
                </Modal>
              </Flex>
            </div>
            <Table
              scroll={{
                y: "68vh",
              }}
              size="small"
              dataSource={tableData()}
              bordered={false}
              columns={columns}
              loading={loaded}
            />
          </Content>
        </Layout>
      </Layout>

      <ProfileSlider setOpenDwa={setOpenDwa} openDwa={openDwa} />
    </>
  );
};

export default Manageusers;
