import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { useState, useRef } from "react";
import {  Spin } from 'antd';
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { postrequest } from "../Middleware/managerequest";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie"
import { useDispatch } from "react-redux";
import {
  setIslogin,
  setAdmin,
  setuser
} from "../../store/Reducer";

const UserVerify = () => {
  const { state } = useLocation();
  const dispatch=useDispatch()
  const [data] = useState([1, 2, 3, 4]);
  const inputRefs = useRef([1, 2, 3, 4]);
  const data1 = useSelector((state) => state.Data);
  const [isotpentered,setIsotpentered]=useState(false)
  const { Temp } = data1;
  const navigate = useNavigate();
  const handleKeyPress = (index) => {
    let nextIndex = null;
    if (inputRefs.current[index].value.length === 1) {
      nextIndex = index + 1;
    } else if (index === 0) {
      nextIndex = 0;
    } else {
      nextIndex = index - 1;
    }
    if (nextIndex !== inputRefs.current.length) {
      inputRefs.current[nextIndex].focus();
    }
    if (nextIndex === inputRefs.current.length) {
      Submit();
    }
  };
  const Submit = () => {
    let OTP = "";
    inputRefs.current.map((item) => (OTP += item.value));
    let obj = {
      ...Temp,
      otp: OTP,
    };
    setIsotpentered(true)
    postrequest(`/home/userverification`, obj)
      .then((res) => {
        setIsotpentered(false)
        if (res.status === 201) {
          toast.error("OTP is Expired", {
            position: "top-center",
            autoClose: 1500,
          });
        } else {
          toast.success("OTP verified", {
            position: "top-center",
            autoClose: 1500,
          });
          if (state?.org) {
            navigate("/subscribed");
          } else {


            const decodetoken = jwtDecode(res.data.token);
            if(process.env.REACT_APP_ENVIRONMENT==="local"){
              Cookies.set('digikull_local', res.data.token, { expires: 1 });

            }

          let obj = {
            Email: decodetoken.Email,
            FullName: res.data.FullName??decodetoken.FullName,
            subdomain: decodetoken.subdomain,
            Mobile:decodetoken.Mobile,
            IsAllowTest:decodetoken.IsAllowTest
          };
          dispatch(setuser(obj));
          dispatch(setAdmin(decodetoken.isAdmin));
          localStorage.setItem("FullName", res.data.FullName??decodetoken.FullName);
          sessionStorage.setItem("Email", decodetoken.Email);
          sessionStorage.setItem("secret", decodetoken.isAdmin);
          sessionStorage.setItem("subdomain", decodetoken.subdomain);
          let flag = true;
          dispatch(setIslogin(flag));
         
 
          if (state?.id) {
              navigate(`/MyMock/${state?.id}`,{state:{external:state?.external}});
          } else {
            navigate("/user");
          }


    
          }
        }
      })
      .catch((err) => {
        setIsotpentered(false)
        if (err?.response?.status === 400) {
          toast.error("Please check OTP", {
            position: "top-center",
            autoClose: 1500,
          });
        } else {
          toast.error("Server not responding", {
            position: "top-center",
            autoClose: 1500,
          });
        }
      });
  };
  const resend = () => {
    let obj = {
      email: Temp.email,
      subdomain: Temp.subdomain,
    };
    //  setIsotpentered(true)
    postrequest(`/home/userverification`,obj).then((res) => {

      if (res.status === 201) {
        // setIsotpentered(false)
        toast.success("OTP send successfully", {
          position: "top-center",
          autoClose: 1500,
        });
      }
    });
  };
  return (
    <Row className="mt-5 pt-5">
      <ToastContainer />

      <Row>
        <Col
          lg={{ span: 6, offset: 5 }}
          sm={{ span: 6, offset: 5 }}
          xs={{ span: 6, offset: 4 }}
        >
          <h2> Email Verification</h2>
        </Col>
      </Row>
      <Row>
        <Col
          md={{ offset: 3 }}
          lg={{ span: 6, offset: 4 }}
          sm={{ span: 12, offset: 2 }}
          xs={{ span: 10, offset: 1 }}
        >
          <p>
            We send One-Time-Password to your Email. Please Check and Enter.
          </p>
        </Col>
      </Row>
      <Row>
        <Col
          lg={{ span: 2, offset: 5 }}
          sm={{ span: 4, offset: 4 }}
          xs={{ span: 6, offset: 3 }}
        >
          <div className="d-flex justify-content-around">
            {data.map((_, index) => (
              <div key={index} style={{ width: "50px" }}>
                <Form.Control
                  type="text"
                  maxLength={1}
                  onChange={() => handleKeyPress(index)}
                  ref={(element) => (inputRefs.current[index] = element)}
                  style={{ textAlign: "center" }}
                  disabled={isotpentered}
                />
              </div>
            ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          lg={{ span: 5, offset: 7 }}
          sm={{ span: 5, offset: 7 }}
          xs={{ span: 5, offset: 8 }}
        >
          <p onClick={() =>!isotpentered && resend()} style={{ cursor:isotpentered?"not-allowed": "pointer" }}>
            <u>Resend OTP.</u>
          </p>
        </Col>
      </Row>
      <div className="mt-3 d-flex justify-content-center">
       <div className="d-flex flex-column">


       <Spin size="medium"spinning={isotpentered}>

       </Spin>

      {isotpentered && <span>We are verifying your OTP and signing you in. Please wait.</span>} 
       </div>
         

         
      </div>
    </Row>
  );
};

export default UserVerify;
