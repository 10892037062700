import { Button, Input, message, Modal, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import styles from "./UploadOMR.module.css";
import { postrequest } from "../../Middleware/managerequest";
import { useParams } from "react-router-dom";
import axios from "axios";
const UploadORM = () => {
  const videoRef = useRef(null); // Reference for the video element
  const canvasRef = useRef(null); // Reference for the canvas element
  const [loding, setLoding] = useState(false); // State to store the captured photo
  const [selectedFile, setSelectedFile] = useState(null);
  const [data, setData] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  const [email, setEmail] = useState("");
  const { clientname, mockid, setno } = useParams();
  const url = process.env.REACT_APP_BACKEND_SERVER;
  // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleUpload = async () => {
    setShowInfo(false);
    setLoding(true);
    if (!selectedFile) return;
    if (!email) {
      message.warning("Enter your registered email id");
      return;
    }
    const formData = new FormData();
    formData.append("omrImage", selectedFile);
    try {
      const response = await axios.post(
        `${url}/home/omr_sheet/${mockid}/${email}/${setno}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        }
      );
      setLoding(false);
      setData(response.data);
      if (!response.data?.success) {
        message.warning(response.data?.error);
      } else {
        message.success("OMR Uploaded Successfully");
      }
    } catch (error) {
      console.error("Error uploading file", error);
      setLoding(false);
    }
  };

  return (
    <>
      {loding ? (
        <div className={styles.container}>
          <Spin />
        </div>
      ) : (
        <div className={styles.container}>
          <h1>Upload you OMR Sheet</h1>
          <div className={styles.uploadGallery}>
            <h5>Upload from Gallery</h5>
            <input onChange={handleFileChange} type="file" />
          </div>
          <Button onClick={() => setShowInfo(true)}>Upload Image</Button>

          <Modal
            title="Enter Basic Details"
            open={showInfo}
            onOk={() => handleUpload()}
            onCancel={() => setShowInfo(false)}
            cancelButtonProps={{
              disabled: true,
            }}
          >
            <Input placeholder="Enter frist name" />
            <Input
              placeholder="Enter last name"
              style={{ margin: "5px auto" }}
            />
            <Input
              placeholder="Enter your registered email id"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </Modal>
        </div>
      )}
    </>
  );
};
export default UploadORM;
