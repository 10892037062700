import React, { useEffect, useState, useCallback, useRef } from "react";
import { Collapse, Progress, Result } from "antd";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styles from "./MyTestSeries.module.css";
import { getrequest } from "../../Middleware/managerequest";
// import ReactPlayer from "react-player";
import { Spin } from "antd";
import { MdOutlineFindInPage } from "react-icons/md";
import { TiPencil } from "react-icons/ti";
import { useSelector } from "react-redux";

import {
  LockOutlined,
  PieChartOutlined,
  ProductOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
// import UserDashbord from "../UserDashbord";
import LatestNavbar from "../../Navigator_Footer/LatestNavbar";
import ProfileSlider from "../../Navigator_Footer/ProfileSlider";
import { MdOutlineOndemandVideo } from "react-icons/md";

function getItem(label, key, icon, children, type, disabled) {
  return {
    key,
    icon,
    children,
    label,
    type,
    disabled,
  };
}
const MyTestSeries = () => {
  const hasaccess = useRef(false);
  const [activeKey, setActiveKey] = React.useState(0);
  const [searchparams] = useSearchParams();
  const [menuKey, setMenuKey] = useState(searchparams.get("tab"));
  const navigate = useNavigate();
  const [openDwa, setOpenDwa] = useState(false);
  const [courseData, setCourseData] = useState([]);
  const [itemToRender, setItemToRender] = useState([]);
  const [mycourses, setMycourses] = useState([]);
  const [totalTopic, setTotalTopic] = useState({});
  const [IsLoding, setIsLoding] = useState(false);
  const [videoIndex, setVideoIndex] = useState(0);
  const { Data, StudentProfile } = useSelector((state) => state);
  const { user } = Data;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [isChecked, setIsChecked] = useState(false);
  const [showUpgradModal, setShowUpgradModal] = useState(false);

  const handleSpanClick = (event) => {
    if (event.target.tagName !== "INPUT") {
      setIsChecked(!isChecked);
    }
  };
  const handleVideoSelect = (index) => {
    setVideoIndex(index);
    setShowUpgradModal(true);
  };
  const handleVideoSelectDisableModal = (index) => {
    setVideoIndex(index);
    setShowUpgradModal(false);
  };
  const items = [
    getItem(
      <span className={styles.menulabel}>Dashboard</span>,
      "dashboard",
      <PieChartOutlined />
    ),

    getItem(
      <span className={styles.menulabel}>All Courses</span>,
      "courses",
      <ProductOutlined />
    ),
    mycourses?.length > 0 &&
      getItem(
        <span className={styles.menulabel}>My Course</span>,
        "mycourse",
        <ProductOutlined />,
        mycourses?.map((course) => {
          return getItem(
            <span className={styles.menulabel}>{course?.coursetitle}</span>,
            `course_${course?.courseid}`,
            <ScheduleOutlined />,
            null,
            null,
            false
          );
        })
      ),

    getItem(
      <span className={styles.menulabel}>Job Posts</span>,
      "sub2",
      <LockOutlined />,
      [
        getItem("Frontend Developer", "9"),
        getItem("FullStack Developer", "10"),
        getItem("Other Role", "11"),
      ],
      null,
      true
    ),
  ];
  const calculateTotalSession = (courseData) => {
    let topic = {};

    // if (courseData.length === 0) return topic;

    // courseData[0]?.sections?.forEach((ele) => {
    //   ele?.content?.forEach((item) => {
    //     if (topic[item.type] === undefined) {
    //       topic[item.type] = 1;
    //     } else {
    //       topic[item.type]++;
    //     }
    //   });
    // });
    setTotalTopic(topic);
  };
  useEffect(() => {
    const fetchData = async () => {
      setIsLoding(true);
      try {
        const response = await getrequest(`/user/getcourse/${id}`);
        setIsLoding(false);
        hasaccess.current = response?.data?.access;
        setCourseData(response?.data?.course);
        setMycourses(response?.data?.mycourses);
      } catch (error) {
        setIsLoding(false);
        console.error("Error fetching course data:", error);
      }
    };
    fetchData();
  }, [id]);

  const navigateToTest = useCallback(
    (details) => {
      navigate(`/MyMock/${details?.Testid}`, {
        state: {
          title: details?.Test_Name,
          username: user?.Email,
          subdomain: user?.subdomain,
        },
      });
    },
    [navigate, user]
  );

  const navigateToPractice = useCallback(
    (details, qid) => {
      navigate(`/instructions/${qid}/${details?.Testid}/${0}`);
    },
    [navigate]
  );

  const onChange = (key) => {
    setActiveKey(key[0]);
    setVideoIndex(0);
  };
  useEffect(() => {
    // if (courseData?.length === 0) return; // Avoid processing if data hasn't been fetch
    setItemToRender(
      ["sdfjh", "jhf"]?.sections?.map((ele, sectionind) => ({
        key: sectionind,
        details: ele?.content?.map((item) => {
          return {
            link: item?.external_link?.link,
            dics: item?.Description,
          };
        }),

        label: (
          <label className={styles.listPoint}>
            {ele?.sectionName || "No name provided"}
            <span>
              {!hasaccess.current && sectionind > 0 ? (
                <LockOutlined />
              ) : (
                ele?.content?.filter((ele) => ele.type === "lession").length +
                " Lesson"
              )}{" "}
            </span>
          </label>
        ),
        children: ele?.content?.map((para, index) => {
          return (
            <p
              className={videoIndex === index ? styles.para2 : styles.para}
              key={index}
              onClick={() => {
                if (hasaccess.current) {
                  para.type === "practice"
                    ? navigateToPractice(para.Test_Details, para?.content_id)
                    : para.type === "test"
                    ? navigateToTest(para.Test_Details)
                    : handleVideoSelect(index);
                } else if (
                  !hasaccess.current &&
                  sectionind === 0 &&
                  index < 3
                ) {
                  para.type === "practice"
                    ? navigateToPractice(para.Test_Details, para?.content_id)
                    : para.type === "test"
                    ? navigateToTest(para.Test_Details)
                    : handleVideoSelectDisableModal(index);
                } else {
                  setShowUpgradModal(true);
                }
              }}
            >
              <span>
                {para.type === "practice" ? (
                  <TiPencil />
                ) : para.type === "test" ? (
                  <MdOutlineFindInPage />
                ) : (
                  <MdOutlineOndemandVideo />
                )}
              </span>{" "}
              {para?.content_Name}
            </p>
          );
        }),
      }))
    );
    calculateTotalSession(courseData);
  }, [courseData, videoIndex, navigateToPractice, navigateToTest]);

  useEffect(() => {
    let key = searchparams.get("tab");

    if (searchparams.size > 0) {
      setMenuKey(key);
    }
  }, [searchparams, location, navigate]);
  const handleClick = (e) => {
    const params = new URLSearchParams();
    params.set("tab", e.key);

    setMenuKey(params.toString());
    navigate({
      search: params.toString(),
    });

    if (e.key === "courses") {
      navigate({
        pathname: "/allcourses",
        search: `tab=${e.key}`, // Use template literal for clarity
      });
    } else if (e.key === "dashboard") {
      navigate({
        pathname: "/user",
        search: `tab=${e.key}`, // Use template literal for clarity
      });
    }
  };
  //   const PriceContainerHandler = () => {
  //     return (
  //       <div className={styles.upgrade}>
  //         <main>
  //           <LockOutlined
  //             twoToneColor="#eb2f96"
  //             style={{ marginBottom: "10px", fontSize: "40px" }}
  //           />
  //           <h4>Join now to continue learning</h4>
  //           <label>
  //             As an Exponent member, you'll get access to the rest of this course,
  //             and so much more.
  //           </label>
  //           <button>Upgrade now</button>
  //         </main>
  //         <div className={styles.mainBox}>
  //           <div>
  //             <h5>Entire course library</h5>
  //             <label>
  //               Get full access to our course library, with hours of lessons and
  //               example answers.
  //             </label>
  //           </div>
  //           <div>
  //             <h5>Mock interview</h5>
  //             <label>
  //               Watch experts answer real interview questions in hundreds of
  //               videos.
  //             </label>
  //           </div>
  //           <div>
  //             <h5>Private Slack access</h5>
  //             <label>
  //               Join our daily discussions with 10,000 other Exponent members on
  //               Slack.
  //             </label>
  //           </div>
  //           <div>
  //             <h5>Live daily practice</h5>
  //             <label>
  //               With our new peer practice tool, you can practice with other
  //               members every day.
  //             </label>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   };
  return (
    <div className={styles.main}>
      <div className={styles.dashbord}>
        {/* <UserDashbord
          handlerClick={handleClick}
          items={items}
          menuKey={menuKey}
        /> */}
      </div>
      {IsLoding && <Spin tip="Loading..." style={{ margin: "auto" }}></Spin>}
      {false && (
        <div className={styles.errorMessage}>
          <Result
            status="warning"
            title="Course is not avaliable now"
            extra={
              <button
                className={styles.btnNavigaton}
                onClick={() => navigate("/allcourses")}
              >
                All Courses
              </button>
            }
          />
        </div>
      )}
      {!IsLoding && (
        <>
          <div className={styles.navbar}>
            <LatestNavbar openDwa={openDwa} setOpenDwa={setOpenDwa} />
            <ProfileSlider
              setOpenDwa={setOpenDwa}
              openDwa={openDwa}
              user={user}
              handlerClick={handleClick}
              setMenuKey={setMenuKey}
              menuItems={items}
              imageurl={StudentProfile?.imagesurl}
            />
          </div>
          <section>
            {!showUpgradModal ? (
              <div className={styles.video}>
                {/* <ReactPlayer
                  height={"100%"}
                  width={"100%"}
                  url={itemToRender[activeKey]?.details[videoIndex]?.link}
                  controls={true}
                /> */}
              </div>
            ) : (
              ""
            )}
            {!showUpgradModal && (
              <main className={styles.dics}>
                <h3>
                  {" "}
                  Description{" "}
                  <span onClick={handleSpanClick}>
                    <input
                      type="checkbox"
                      onChange={() => setIsChecked(!isChecked)}
                      checked={isChecked}
                    />
                    Mark as read
                  </span>
                </h3>
                <label>
                  {/* {itemToRender[activeKey]?.details[videoIndex]?.dics} */}
                </label>
              </main>
            )}
          </section>
          <aside>
            <div className={styles.details}>
              <main>
                <Progress
                  type="dashboard"
                  steps={8}
                  percent={50}
                  trailColor="rgba(0, 0, 0, 0.06)"
                  strokeWidth={20}
                />
              </main>
              <label>
                {totalTopic?.lession > 0 && (
                  <span>
                    <MdOutlineOndemandVideo style={{ marginRight: "10px" }} />
                    Lession : {totalTopic?.lession}
                  </span>
                )}
                {totalTopic?.test > 0 && (
                  <span>
                    <MdOutlineFindInPage style={{ marginRight: "10px" }} />
                    Test : {totalTopic?.test}
                  </span>
                )}
              </label>
              <label>
                {totalTopic?.practice > 0 && (
                  <span>
                    <TiPencil style={{ marginRight: "10px" }} />
                    Assignment : {totalTopic?.practice}
                  </span>
                )}
              </label>
            </div>
            <Collapse
              accordion
              defaultActiveKey={[0]}
              items={itemToRender}
              bordered={false}
              style={{ background: "white" }}
              onChange={(e) => {
                if (hasaccess.current && e[0]) {
                  onChange(e);
                }
              }}
            />
          </aside>
        </>
      )}
    </div>
  );
};

export default MyTestSeries;
