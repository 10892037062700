import React, { useState, useEffect, useCallback } from "react";

import { Avatar, Dropdown, message } from "antd";
// import Button from "react-bootstrap/Button";
import styles from "./logs.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  HomeOutlined,
  ContainerOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import DashboardSlider from "../../Navigator_Footer/DashboardSlider";
import ProfileSlider from "../../Navigator_Footer/ProfileSlider";
import { getrequest, postrequest } from "../../Middleware/managerequest";
import LatestNavbar from "../../Navigator_Footer/LatestNavbar";
import { Layout, Table, Input } from "antd";
const { Sider, Content } = Layout;
const key = "change";
const DisplayLogs = () => {
  const [messageApi, contentHolder] = message.useMessage();
  const { access, IsSuperAdmin } = useSelector((state) => state.Data);
  const data = useSelector((state) => state.Data);
  const { user } = data;
  const [logData, setLogdata] = useState([]);
  const [search, setSearch] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [openDwa, setOpenDwa] = useState(false);
  const [menuKey, setMenuKey] = useState("");
  const navigate = useNavigate();
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const fetcher = useCallback(
    (val) => {
      if (user) {
        setLoaded(true);
        getrequest(`/logs/${user?.subdomain}`)
          .then((res) => {
            setLogdata(res.data);
            setLoaded(false);
          })
          .catch((err) => {
            console.log(err);
            setLoaded(false);
          });
      }
    },
    [user, messageApi]
  );

  useEffect(() => {
    fetcher();
  }, [fetcher]);

  const columns = [
    {
      title: "User",
      dataIndex: "User",
      width: 150,

      render: (text, { images }) => {
        return (
          <div>
            <Avatar
              size={30}
              src={images}
              icon={<UserOutlined />}
              className="mx-2"
            />
            <label>{text}</label>
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 200,
      key: "email",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 150,
      align: "center",
    },
    {
      title: "Date & Time",
      dataIndex: "timestamp",
      align: "center",
    },
    {
      title: "Details",
      width: 350,
      dataIndex: "details",
      align: "center",
    },
  ];

  const tableData = () => {
    return (
      logData.length > 0 &&
      logData.map((item) => {
        return {
          email: item.user,
          action: item.action ?? "-",
          timestamp: item.timestamp,
          details: item.details ?? "",
          key: item.email,
        };
      })
    );
  };

  return (
    <>
      {contentHolder}
      <Layout>
        <Sider
          className={styles.slider}
          breakpoint="lg"
          collapsedWidth="0"
          width={window.innerWidth < 350 ? 200 : "18%"}
          trigger={null}
        >
          <DashboardSlider />
        </Sider>
        <Layout>
          <LatestNavbar openDwa={openDwa} setOpenDwa={setOpenDwa} />
          <Content style={{ paddingTop: "65px", width: "97%", margin: "auto" }}>
            <div>
              <Input
                placeholder="Search by Name or Email"
                allowClear
                style={{ width: "200px", marginBottom: 16, alignSelf: "end" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <Table
              scroll={{
                y: "68vh",
              }}
              size="small"
              dataSource={tableData()}
              bordered={false}
              columns={columns}
              loading={loaded}
            />
          </Content>
        </Layout>
      </Layout>

      <ProfileSlider setOpenDwa={setOpenDwa} openDwa={openDwa} />
    </>
  );
};

export default DisplayLogs;
