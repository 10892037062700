import React, { useState } from "react";
import { Form, Input, Select, Radio, Button, Tooltip, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { postrequest } from "../../Middleware/managerequest";

const { TextArea } = Input;
const { Option } = Select;

const CreateAIQuestion = ({
  onFinish,
  fetcher,
  Topics,
  access,
  user,
  MainTopics,
  form,
  setShowLoader,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [typeOfQuestion, setTypeOfQuestion] = useState(""); // MCQ/Passage/Theory/Coding
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [numberOfSubQuestions, setNumberOfSubQuestions] = useState(0);
  const [contentType, setContentType] = useState("Text");
  const [MainTags, setMainTags] = useState("Select Subject");
  const [selectedsubTopic, setSelectedSubTopics] = useState([]);
  // const [bloomOption, setBloomOption] = useState("");
  const [trainingType, setTrainingType] = useState("");

  const handleQuestionNumberChange = (e) => {
    const value = parseInt(e.target.value, 10);
    let maxQuestions = typeOfQuestion === "Passage" ? 4 : 10;

    if (!value || isNaN(value)) {
      setNumberOfQuestions(""); // Reset the value if it's not a valid number
    } else if (value > maxQuestions) {
      messageApi.error(
        `Max number of questions for ${typeOfQuestion} is ${maxQuestions}`
      );
      setNumberOfQuestions(maxQuestions); // Set to max value
    } else {
      setNumberOfQuestions(value);
    }
  };
  let arrValuesInTopics = Topics.map((obj) => obj.value);
  let NewValues = selectedsubTopic.filter(
    (value) => !arrValuesInTopics.includes(value)
  );
  const arr = NewValues.map((item) => ({ value: item, label: item }));
  const handleSubQuestionChange = (e) => {
    const value = parseInt(e.target.value, 10);
    let maxQuestions = 6;

    if (value > maxQuestions) {
      messageApi.error(
        `Max number of MCQ questions Per Passage is ${maxQuestions}`
      );
      setNumberOfSubQuestions(maxQuestions); // Set to max value
    } else {
      setNumberOfSubQuestions(value);
    }
  };

  const onContentTypeChange = (e) => {
    setContentType(e.target.value);
  };
  const submit = () => {
    const formData = new FormData();
    const obj = {
      newtopics: arr || [],
      maintopics: [MainTags] || "",
      selectedsubTopic: [selectedsubTopic],
      typeOfQuestion: typeOfQuestion || "",
      numberOfQuestions: numberOfQuestions || 0,
      numberOfSubQuestions: numberOfSubQuestions || 0,
      // bloomsTaxonomy: bloomOption || [],
      trainingType: trainingType || "",
    };
    setShowLoader(true);
    formData.append("questiondata", JSON.stringify(obj));
    postrequest(`/aiquestions/${user?.subdomain}`, formData, { timeout: 30000 })
      .then((res) => {
        setShowLoader(false);
        fetcher();
      })
      .catch((err) => {
        setShowLoader(false);
        console.log("error", err);
      });
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      {/* Type of Institute */}
      {contextHolder}
      <Form.Item
        label="Type of Institute"
        name="typeOfInstitute"
        rules={[
          { required: true, message: "Please select a type of question!" },
        ]}
      >
        <Select
          placeholder="Select a question type"
          value={trainingType}
          onChange={(val) => setTrainingType(val)}
        >
          <Option value="Tech">Tech Training</Option>
          <Option value="UPSC">UPSC Training</Option>
          <Option value="Government Exams India"> Other Govt Exams</Option>
          <Option value="Engineering Institutes">Engineering Institute </Option>
        </Select>
      </Form.Item>
      {/* Main Topic */}
      <Form.Item
        label="Subject"
        name="mainTopic"
        rules={[{ required: true, message: "Please enter the Subject!" }]}
      >
        <Select
          placeholder="Select SubJect"
          onChange={(val) => setMainTags(val)}
          options={MainTopics}
          value={MainTags}
        />
      </Form.Item>

      {/* Type of Question */}
      <Form.Item
        label="Type of Question"
        name="typeOfQuestion"
        rules={[
          { required: true, message: "Please select a type of question!" },
        ]}
      >
        <Select
          placeholder="Select a question type"
          onChange={(e) => {
            setTypeOfQuestion(e); // Update the state directly
            // Execute the additional logic
          }}
        >
          <Option value="MCQ">MCQ</Option>
          <Option value="Passage">Passage</Option>
          <Option value="Theory">Theory</Option>
          <Option value="Coding">Coding</Option>
        </Select>
      </Form.Item>

      {/* Number of Questions */}
      <Form.Item
        label="Number of Questions"
        name="numberOfQuestions"
        rules={[
          { required: true, message: "Please enter the number of questions!" },
        ]}
      >
        <Input
          type="number"
          value={numberOfQuestions} // Ensure the input value is tied to state
          placeholder={`Enter number of questions (max: ${
            typeOfQuestion === "Passage" ? 4 : 10
          })`}
          onChange={handleQuestionNumberChange}
        />
      </Form.Item>

      {/* Number of Sub Questions (Conditional) */}
      <Form.Item
        label="Number of Sub Questions"
        name="numberOfSubQuestions"
        onChange={(val) => handleSubQuestionChange(val)}
      >
        <Input
          type="number"
          placeholder="Enter number of sub-questions (Only for Passage Type Questions)"
        />
      </Form.Item>

      {/* Tags */}
      <Form.Item
        label={
          <>
            {" "}
            Tags
            <Tooltip title="Tags help categorize the questions based on sub-topic, difficulty, class or exam name. You can select from existing tags or create new ones.">
              <InfoCircleOutlined style={{ marginLeft: 5 }} />
            </Tooltip>
          </>
        }
        name="tags"
        rules={[{ required: true, message: "Please select or add tags!" }]}
      >
        <Select
          mode={access && access?.role === "Admin" ? "tags" : "multiple"}
          maxTagCount={"responsive"}
          onChange={(val) => setSelectedSubTopics(val)}
          options={Topics}
          value={selectedsubTopic}
        />
      </Form.Item>

      {/* Bloom's Taxonomy */}
      {/* <Form.Item
        label="Bloom's Taxonomy"
        name="bloomsTaxonomy"
        rules={[
          {
            required: true,
            message: "Please select a Bloom's taxonomy level!",
          },
        ]}
      >
        <Select
          placeholder="Select a Bloom's taxonomy level"
          mode="multiple"
          onChange={(val) => setBloomOption(val)}
        >
          <Option value="Remembering">Remembering</Option>
          <Option value="Understanding">Understanding</Option>
          <Option value="Applying">Applying</Option>
          <Option value="Analyzing">Analyzing</Option>
          <Option value="Evaluating">Evaluating</Option>
          <Option value="Creating">Creating</Option>
        </Select>
      </Form.Item> */}

      {/* Content Type Radio */}
      <Form.Item
        label={
          <>
            {" "}
            Sample Questions
            <Tooltip title="You can provide Sample Questions either in the form of a Website Link or Text based on which questions need to be created. ">
              <InfoCircleOutlined style={{ marginLeft: 5 }} />
            </Tooltip>
          </>
        }
        name="contentType"
        rules={[{ required: true, message: "Please select a content type!" }]}
      >
        <Radio.Group onChange={onContentTypeChange}>
          <Radio value="Text">Text</Radio>
          <Radio value="URL">URL</Radio>
        </Radio.Group>
      </Form.Item>

      {/* Conditional Content Field */}
      {contentType === "Text" ? (
        <Form.Item
          label="Sample Questions"
          name="contentText"
          rules={[
            { required: true, message: "Please provide minimum two sample Questions which AI can refer!" },
          ]}
        >
          <TextArea rows={4} placeholder="Please provide minimum two sample Questions which AI can refer!" />
        </Form.Item>
      ) : (
        <Form.Item
          label="Content URL"
          name="contentURL"
          rules={[
            { required: true, message: "Please enter the content URL!" },
            { type: "url", message: "Please enter a valid URL!" },
          ]}
        >
          <Input placeholder="Enter content URL" />
        </Form.Item>
      )}

      {/* Submit Button */}
      <Form.Item>
        <Button type="primary" htmlType="submit" onClick={() => submit()}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
export default CreateAIQuestion;
