import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import { setAdmin, setuser, setIslogin } from "../../store/Reducer";
import { updateProfilePhoto } from "../../store/StudentProfileSlice";
import { getrequest } from "./managerequest";
const CheckLogin = ({ children }) => {
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [remember, setRemember] = useState(false);
  const [searchParams] = useSearchParams();

  const handleTokens = useCallback(
    (token) => {
      const decodetoken = jwtDecode(token);
      let getFullName = localStorage.getItem("FullName");
      let obj = {
        Email: decodetoken.Email,
        FullName: getFullName ?? decodetoken.FullName,
        subdomain: decodetoken.subdomain,
        Mobile:decodetoken.Mobile,
        IsAllowTest:decodetoken.IsAllowTest
      };
      dispatch(setuser(obj));

      dispatch(setAdmin(decodetoken.isAdmin));
      localStorage.setItem("FullName", getFullName ?? decodetoken.FullName);
      sessionStorage.setItem("Email", decodetoken.Email);
      sessionStorage.setItem("secret", decodetoken.isAdmin);
      let flag = true;
      dispatch(setIslogin(flag));
      if (remember) {
        Cookies.set("_remdig_", decodetoken.email);
      } else {
        Cookies.remove("_remdig_");
      }

      let params = {};
      for (const key of searchParams) {
        key[0].split("&").forEach((item) => {
          params[item.split("=")[0]] = item.split("=")[1];
        });
      }
      const { id, ref } = params;
      if (id) {
        if (decodetoken.isAdmin) {
          navigate(`/mockdetails/${id}`);
        } else {
          navigate(`/MyMock/${id}`, {
            state: { external: ref ? true : false },
          });
        }
      } else if (decodetoken.isAdmin) {
        navigate("/admin");
      } else {
        navigate("/user");
      }
    },
    [dispatch, navigate, remember, searchParams]
  );

  useEffect(() => {
    const token =
      process.env.REACT_APP_ENVIRONMENT === "local"
        ? Cookies.get("digikull_local")
        : Cookies.get("unoprep");
    if (accounts.length > 0) {
      const msal_account = localStorage.getItem("msal.account.keys");
    
      if (!msal_account) {
        instance
          .acquireTokenSilent({
            account: accounts[0],
            scopes: process.env.SCOPE,
          })
          .then((res) => {
            if (!token) {
              getrequest(
                `/home/auth/singlesignin/?name=${accounts[0].name}&username=${accounts[0].username}`
              )
                .then((res) => {
                  if (res.status === 200) {
                    handleTokens(res.data.token);
                    localStorage.setItem("my_profile_image", res.data.imagesurl);
                    dispatch(updateProfilePhoto(res.data.imagesurl));
                    if (process.env.REACT_APP_ENVIRONMENT === "local") {
                      Cookies.set("digikull_local", res.data.token, { expires: 1 });
                    }
                  }
                })
                .catch((err) => console.log(err));
            }
          });
      } else {
        if (!token) {
          getrequest(
            `/home/auth/singlesignin/?name=${accounts[0].name}&username=${accounts[0].username}`
          )
            .then((res) => {
              if (res.status === 200) {
                handleTokens(res.data.token);
                localStorage.setItem("my_profile_image", res.data.imagesurl);
                dispatch(updateProfilePhoto(res.data.imagesurl));
                if (process.env.REACT_APP_ENVIRONMENT === "local") {
                  Cookies.set("digikull_local", res.data.token, { expires: 1 });
                }
              }
            })
            .catch((err) => console.log(err));
        }
      }
    }

    if (token) {
      handleTokens(token);
    }
    let myid = Cookies.get("_remdig_");
    if (myid) {
      setRemember(true);
    }
  }, [accounts, handleTokens, instance,dispatch]);

  return <div>{children}</div>;
};

export default CheckLogin;
