import axios from "axios";
import Cookies from "js-cookie";
import store from "../../store/Store";
const url = process.env.REACT_APP_BACKEND_SERVER;

// Get clientdetails from Redux state
const getClientDetails = () => {
  const state = store.getState();
  const { user, clientdetails } = state?.Data;
  return { user, clientdetails };
};
async function getrequest(suburl) {
  let token =
    process.env.REACT_APP_ENVIRONMENT === "local"
      ? Cookies.get("digikull_local")
      : Cookies.get("unoprep");
  let res = await axios.get(url + suburl, {
    withCredentials: true,
    headers: { Authorization: `Bearer ${token}` },
  });
  return res;
}
async function postrequest(suburl, data, options = {}) {
  const details = getClientDetails();
  if (
    details?.clientdetails?.Attempted > details?.clientdetails?.mockcount &&
    details.user
  ) {
    alert(
      "You are not allowed to create or modify data. You have view-only access."
    );
    return;
  }
  let token =
    process.env.REACT_APP_ENVIRONMENT === "local"
      ? Cookies.get("digikull_local")
      : Cookies.get("unoprep");
  let res = await axios.post(url + suburl, data, {
    withCredentials: true,
    headers: { Authorization: `Bearer ${token}` },
    ...options,
  });
  return res;
}

async function putrequest(suburl, data) {
  const details = getClientDetails();
  if (
    details?.clientdetails?.Attempted > details?.clientdetails?.mockcount &&
    details.user
  ) {
    alert(
      "You are not allowed to create or modify data. You have view-only access."
    );
    return;
  }
  let token =
    process.env.REACT_APP_ENVIRONMENT === "local"
      ? Cookies.get("digikull_local")
      : Cookies.get("unoprep");
  let res = await axios.put(url + suburl, data, {
    withCredentials: true,
    headers: { Authorization: `Bearer ${token}` },
  });
  return res;
}

export { getrequest, postrequest, putrequest };
