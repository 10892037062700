import styles from "./SignUp.module.css";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import officeComapnyLogo from "../../../icons/officeComapnyLogo.png";
import studentLogo from "../../../icons/studentLogo.png";
import JustDigikullLogo from "../../../icons/logounoprep.png";

const SignUp = () => {
  const [selected, setSelected] = useState(0);
  const navigate = useNavigate();
  const [params, setParams] = useState({});
  const [searchParams] = useSearchParams();
  const { id, ref, client_id } = params;
  useEffect(() => {
    let params = {};
    for (const key of searchParams) {
      key[0].split("&").forEach((item) => {
        params[item.split("=")[0]] = item.split("=")[1];
      });
    }
    setParams(params);
  }, [searchParams]);
  console.log(params)
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.leftSidebar}>
          <div className={styles.navbar}>
            <img src={JustDigikullLogo} alt="logo" />
          </div>

          <div className={styles.rightSidebarContext}>
            <label className={styles.Mock}>Tell us about yourself</label>
            <label className={styles.rTxt}>
              We will personalize your setup experience accordingly.
            </label>

            <div
              className={styles.hand}
              onClick={() => setSelected(2)}
              style={{ border: selected === 2 ? "2px solid black" : "" }}
            >
              <div>
                <img src={studentLogo} alt="User" />
              </div>
              <div className={styles.handTxt}>
                <label className={styles.label1}>
                  I am a student/candidate{" "}
                </label>
                <br />
                <p className={styles.label2}>Looking to take Tests</p>
              </div>
            </div>

            <div
              className={styles.horn}
              onClick={() => setSelected(1)}
              style={{ border: selected === 1 ? "2px solid black" : "" }}
            >
              <div>
                <img src={officeComapnyLogo} alt="Business" />
              </div>
              <div className={styles.hornTxt}>
                <label className={styles.label1}>
                  I am an organization/training institute
                </label>
                <br />
                <p className={styles.label2}>
                  Looking to launch Tests for my students
                </p>
              </div>
            </div>

            <div className={styles.btnDiv}>
              <button
                onClick={() => {
                  if (selected === 1) {
                    navigate("/organization", {
                      state: {
                        id: id,
                        client_id: client_id,
                        external: ref ? true : false,
                      },
                    });
                  } else {
                    navigate("/personal", {
                      state: {
                        id: id,
                        client_id: client_id,
                        external: ref ? true : false,
                      },
                    });
                  }
                }}
                disabled={selected === 0}
                className={styles.btn}
                style={{ background: selected === 0 ? "#D3D3D3" : "#08213E" }}
              >
                Create Account
              </button>

              <label
                className={styles.alreadyaccount}
                onClick={() => {
                  if (id !== undefined) {
                    let encoded = ref
                      ? encodeURIComponent(
                          `id=${id}&client_id=${client_id}&ref=external`
                        )
                      : encodeURIComponent(
                          `/login/?id=${id}&client_id=${client_id}`
                        );
                    navigate(`/?${encoded}`, {
                      state: { id: id, client_id: client_id },
                    });
                  } else {
                    navigate("/");
                  }
                }}
              >
                I already have a Account.
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignUp;
