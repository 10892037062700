import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import Webcam from "react-webcam";

import axios from "axios";
import nextArrow from "../../../icons/majesticons_arrow-right.svg";
import closeIcon from "../../../icons/zondicons_close-outline.svg";
import blackNextIcon from "../../../icons/blackNextIcon.svg";
import styles from "./Question.module.css";
import Toast from "react-bootstrap/Toast";
import { QuestionwiseTimer, TestTimer } from "../../Services/countdown";
import { useParams } from "react-router-dom";
import Editor from "@monaco-editor/react";
import { Tabs, Button, Drawer, Modal, Image, Select } from "antd";
import {
  StartScreenShots,
  stopScreenshots,
  captureVideo,
  stopVideo,
  StopCamera,
  stopScreenSharing,
  StartTakingScreenshots,
  StopTakingScreenshots,
} from "../../Services/RecordingCam";
// import { TestCaseData } from "./TestCaseData";
import { GlobalOutlined } from "@ant-design/icons";

let options = [
  {
    label: "Programming Languages",
    options: [
      {
        label: "Javascript",
        value: "js-javascript",
      },

      {
        label: "Python",
        value: "py-python",
      },
      {
        label: "Java",
        value: "java-java",
      },
    ],
  },
  {
    label: "Web Language",
    options: [
      {
        label: "HTML",
        value: "html-html",
      },
    ],
  },
  {
    label: "DataBase Languages",
    options: [
      {
        label: "MySQL",
        value: "sql-mysql",
      },
      {
        label: "MongoDB",
        value: "js-mongodb",
      },
    ],
  },
];

let htmlcode = `
<html>
  <head>
    <link rel="stylesheet" href="styles.css" />
  </head>
  <body>
    
      <script src="script.js"></script>
  </body>
</html>`;

const minutesMapping = {
  Coding: {
    Easy: 600,
    Medium: 900,
    Hard: 1200,
  },
  Question: {
    Easy: 50,
    Medium: 60,
    Hard: 100,
  },
  Mcq: {
    Easy: 50,
    Medium: 70,
    Hard: 110,
  },
  Passage: {
    Easy: 30,
    Medium: 40,
    Hard: 90,
  },
};
let fd = new FormData();
const url = process.env.REACT_APP_BACKEND_SERVER;
const Question = ({ mock, setThankyou, setmockstart, Settings, duration }) => {
  const autotimer = useRef(0);
  const initialState = {
    QNO: 0,
    minutes: 600,
    IsClicked: false,
    isdisable: true,
    IsConsole: false,
    isonline: true,
    show: false,
    starttime: null,
  };
  let passageSubQuestion = 0;
  mock?.forEach((item) => {
    if (item.type === "Passage" && item?.qstn?.passageQuestions) {
      passageSubQuestion += item?.qstn?.passageQuestions?.length - 1;
    }
  });

  const [state, setState] = useState(initialState);
  const [passageIndex, setPassageIndex] = useState(1);
  const [questionCount, setQuestionCount] = useState(0);
  const [iscapturing, setIscapturing] = useState(false);
  const [isrecording, setIsrecording] = useState(false);
  const { id } = useParams();
  const { user } = useSelector((state) => state.Data);
  const {
    QNO,
    minutes,
    isonline,
    IsConsole,
    isdisable,
    IsClicked,
    show,
    starttime,
  } = state;

  //Compiler
  const [labellang, setLabelLang] = useState("Javascript");
  const [language, setLanguage] = useState("javascript");
  const [extension, setExtension] = useState("js");
  const [csscode, setCsscode] = useState("");
  const [scriptcode, setScriptCode] = useState("");
  const [srcDoc, setsrcDoc] = useState("");
  const [answer, setAnswer] = useState("");
  const [passageAnswer, setPassageAnswer] = useState([]);
  const [compilestatus, setCompilerStatus] = useState(false);
  const [Isinfinite, setInfinite] = useState(false);
  const count_tab_change = useRef(0);
  const slider = useRef(null);
  const [htmloutput, setOutput] = useState("");

  const disableRightClick = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    let flag = true;
    if (flag) {
      document.addEventListener("contextmenu", disableRightClick);
      return () => {
        document.removeEventListener("contextmenu", disableRightClick);
      };
    }
  }, []);

  useEffect(() => {
    if (Settings?.TestTimer ?? false) {
      setState((prev) => ({ ...prev, minutes: Number(duration) * 60 }));
    }
  }, [duration, Settings]);

  //setting Timing at start
  useEffect(() => {
    if (mock && mock[QNO]) {
      const type = mock[QNO].type;
      const level = mock[QNO].level;
      if (!Settings?.TestTimer ?? true) {
        if (mock[QNO]?.time !== undefined && mock[QNO]?.time > 0) {
          setState((prevState) => ({
            ...prevState,
            minutes:
              mock[QNO]?.time < minutesMapping[type]?.[level]
                ? minutesMapping[type]?.[level]
                : mock[QNO]?.time,
          }));
        } else if (minutesMapping[type] && minutesMapping[type][level]) {
          setState((prevState) => ({
            ...prevState,
            minutes:
              mock[QNO]?.time ??
              (type === "Coding" && mock[QNO].maintopics[0] === "MySQL")
                ? minutesMapping[type][level] + 600
                : minutesMapping[type][level],
          }));
        }
      }
      setCsscode("");
      setScriptCode("");
      setAnswer("");
      if (mock[QNO]?.type === "Coding") {
        // setTestCode("");
        setLabelLang(
          mock[QNO]?.maintopics[0] === "DSA"
            ? "Javascript"
            : mock[QNO]?.maintopics[0]
        );
        if (mock[QNO]?.maintopics[0] === "HTML") {
          setAnswer(htmlcode);
          setExtension("html");
          setLanguage("html");
        } else if (mock[QNO]?.maintopics[0] === "MySQL") {
          setExtension("sql");
          setLanguage("mysql");
          // setAnswer("-- Ignore The Test Cases \n-- Create Table as per need ");
        } else if (
          ["Javascript", "Python", "Java", "DSA"].includes(
            mock[QNO]?.maintopics[0]
          )
        ) {
          const findOptionByValue = () => {
            for (const category of options) {
              const foundOption = category.options.find(
                (option) =>
                  option.label ===
                  (mock[QNO]?.maintopics[0] === "DSA"
                    ? "Javascript"
                    : mock[QNO]?.maintopics[0])
              );
              if (foundOption) {
                return foundOption;
              }
            }
            return null; // Value not found
          };

          const foundOption = findOptionByValue();
          if (foundOption && foundOption.value) {
            setExtension(foundOption?.value?.split("-")[0]);
            setLanguage(foundOption?.value?.split("-")[1]);
          }
        }
      }
    }
    setsrcDoc("");
    // setIsTestPass(null);
    setInfinite(false);
    setCompilerStatus(false);

    if (mock[QNO]?.type !== "Mcq") {
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          isdisable: false,
        }));
      }, 10000);
    }

    setState((prevState) => ({
      ...prevState,
      IsConsole: false,
      IsClicked: false,
      isdisable: true,
    }));

    if (starttime === null) {
      setState((prevState) => ({
        ...prevState,
        starttime: new Date(),
      }));
    }

    fd = new FormData();
  }, [mock, QNO, starttime, Settings]);

  useEffect(() => {
    if (QNO === 0 && (Settings?.capture_user ?? true)) {
      if (mock[0]?.type === "Question") {
        captureVideo().then(() => setIsrecording(true));
      } else {
        const timer = Settings?.TestTimer ?? false ? 8000 : 5000;
        StartScreenShots(timer).then(() => setIscapturing(true));
      }
    }
  }, [QNO, mock, Settings]);

  const StartNewQuestion = (val) => {
    const nextQuestionIndex = QNO + 1;
    setQuestionCount(questionCount + 1);
    if (Settings?.TestTimer ?? false) {
      autotimer.current = 0;
    }

    setState((prevState) => ({
      ...prevState,
      QNO: nextQuestionIndex,
    }));

    const nextQuestionType = mock[nextQuestionIndex]?.type;

    const handleCameraError = (errorMessage) => {
      const confirmMessage = `There is some issue with camera. code:${errorMessage}`;
      if (window.confirm(confirmMessage)) {
        Submit(val);
      }
    };
    if (nextQuestionType === "Coding" && window.innerWidth < 768) {
      return StartNewQuestion(val);
    }

    if (nextQuestionType === "Question" && (Settings?.capture_user ?? true)) {
      captureVideo()
        .then(() => {
          setIscapturing(false);
          setIsrecording(true);
        })
        .catch(() => {
          handleCameraError("#VRSD"); // Video recording starting error
        });
    } else {
      setsrcDoc("");
      if (Settings?.capture_user ?? true) {
        const timer = Settings?.TestTimer ?? false ? 8000 : 5000;
        StartScreenShots(timer)
          .then(() => {
            setIsrecording(false);
            setIscapturing(true);
          })
          .catch(() => {
            handleCameraError("#CSSD"); // Camera shots starting error
          });
      }
    }
  };
  const handleRequest = async (file, val) => {
    try {
      StartNewQuestion(val);
      await axios.post(
        `${url}/MyMock/${id}/${user?.subdomain}/${user?.Email}`,
        file,
        {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        }
      );
      fd = new FormData();
    } catch (err) {
      console.log(err);
    }
  };

  const QuestionData = useCallback(
    (val, reason) => {
      const {
        qstn,
        point,
        ans,
        type,
        level,
        opt1,
        opt2,
        opt3,
        opt4,
        qid,
        feed,
        attachments,
        modelans,
        title,
      } = mock[QNO];
      const isCorrectAnswer = answer === ans;
      let resultArray = [];
      let correctAnswer = 0;
      if (type === "Passage") {
        resultArray = qstn?.passageQuestions?.map((answer, index) => {
          const isCorrect = answer?.answer === passageAnswer[index];
          return {
            answer: isCorrect, // true if matched, false otherwise
            option: passageAnswer[index],
            point: isCorrect ? answer.point : 0, // User's chosen option
          };
        });
        correctAnswer = resultArray.filter((item) => item.answer).length;
        // setPassageAnswer(resultArray);
      }
      const feedback =
        val === "dont know "
          ? "Great effort! It's okay not to know everything. Keep exploring and learning more about the topic."
          : type === "Mcq" && !isCorrectAnswer
          ? feed?.incorrect ??
            `Oops! That's not quite right. ${ans} is the correct answer. Keep trying!`
          : isCorrectAnswer
          ? feed?.correct ??
            "Congratulations! Your answer is spot on. Well done!"
          : type === "Coding"
          ? "Take your time to understand the problem fully. Break it down into smaller steps and tackle each one methodically."
          : "";

      return {
        id: qid,
        Question: qstn,
        realpoints: point,
        level: level,
        title: title,
        answer:
          type === "Passage"
            ? resultArray
            : val === "dont know" ||
              (type !== "Question" && answer?.trim("") === "")
            ? "don't know"
            : answer,
        point:
          type === "Passage"
            ? correctAnswer > 0 && correctAnswer <= resultArray.length / 2
              ? point / 2
              : correctAnswer === 0
              ? 0
              : point // Full points if correctAnswer >= resultArray.length / 2
            : isCorrectAnswer
            ? point
            : 0,
        passageAnswer: passageAnswer,
        Email: user?.Email,
        concept: mock[QNO]?.concept[0],
        FullName: user?.FullName,
        starttime,
        endtime: new Date(),
        Options:
          type === "Mcq"
            ? {
                opt1,
                opt2,
                opt3,
                opt4,
              }
            : "",
        Type: type,
        extension,
        // TestCase: TestCase ? TestCase : "",
        feedback,
        Manual: feedback || answer === ans ? false : true,
        HTMLCode: {
          csscode: csscode,
          scriptcode: scriptcode,
        },
        htmloutput: htmloutput,
        language: mock[QNO]?.maintopics[0],
        Settings: Settings,
        reason: reason,
        attachments: attachments ?? [],
        modelans: modelans ?? [],
      };
    },
    [
      mock,
      QNO,
      extension,
      answer,
      starttime,
      user,
      csscode,
      scriptcode,
      // Testcode,
      htmloutput,
      Settings,
    ]
  );

  //Language Change
  const handleChange = (value) => {
    if (value?.split("-")[1] === "html") {
      setAnswer(htmlcode);
      setLabelLang("HTML");
    }
    setState((prevState) => ({
      ...prevState,
      IsConsole: false,
    }));

    if (value?.split("-")[1] === "mysql") {
      // setAnswer("-- Ignore The Test Cases \n-- Create Table as per your need ");
      setLabelLang("MySQL");
    }
    if (["javascript", "python", "java"].includes(value?.split("-")[1])) {
      const findOptionByValue = () => {
        for (const category of options) {
          const foundOption = category.options.find(
            (option) => option.value === value
          );
          if (foundOption) {
            return foundOption;
          }
        }
        return null; // Value not found
      };

      const foundOption = findOptionByValue();
      setLabelLang(foundOption.label);
      // if (mock[QNO]?.PreInputs) {
      //   let Testdata = TestCaseData(mock[QNO]?.PreInputs, value.split("-")[1]);
      //   setAnswer(Testdata?.maincode);
      //   setTestCode(Testdata?.testcode);
      // } else {
      //   setAnswer(mock[QNO].TestCase?.BaseCode);
      // }
    }
    setExtension(value?.split("-")[0]);
    setLanguage(value?.split("-")[1]);
  };

  //Compiler Onchange
  const onChange = useCallback((value) => {
    setAnswer(value);
    // setIsTestPass(null);
    setCompilerStatus(false);
    setInfinite(false);
  }, []);

  const handleEditorDidMount = (editor, monaco) => {
    // Disable copy-paste functionality

    if (Settings?.copypaste !== undefined && (!Settings?.copypaste ?? false)) {
      editor.updateOptions({
        contextmenu: false, // Disabling the default context menu
      });

      // Adding a custom context menu provider to prevent right-click
      editor.addAction({
        id: "preventRightClick",
        label: "Prevent Right Click",
        contextMenuGroupId: "navigation",
        contextMenuOrder: 1.5,
        run: (ed) => {
          return null;
        },
      });

      editor.onKeyDown((e) => {
        if (
          (e.ctrlKey &&
            (e.browserEvent.key === "c" || e.browserEvent.key === "C")) ||
          (e.ctrlKey &&
            (e.browserEvent.key === "v" || e.browserEvent.key === "V"))
        ) {
          const clipboardEvent = e.browserEvent;
          clipboardEvent.preventDefault();
        }
      });
    }
  };

  //
  const items = [
    {
      key: "1",
      label: `index.${extension}`,
      children: (
        <Editor
          height="56vh"
          value={answer}
          defaultLanguage={language}
          onChange={(e) => onChange(e)}
          onMount={handleEditorDidMount}
        />
      ),
    },
    extension === "html" && {
      key: "2",
      label: "styles.css",
      children: (
        <Editor
          height="56vh"
          defaultLanguage={"css"}
          onChange={(e) => setCsscode(e)}
          value={csscode}
          onMount={handleEditorDidMount}
        />
      ),
    },
    extension === "html" && {
      key: "3",
      label: "script.js",
      children: (
        <Editor
          height="56vh"
          defaultLanguage={"javascript"}
          onChange={(e) => setScriptCode(e)}
          value={scriptcode}
          onMount={handleEditorDidMount}
        />
      ),
    },
  ];

  //Submit
  const Submit = useCallback(
    (val, reason) => {
      const performRequest = () => {
        const data = QuestionData(val, reason);
        fd.append("sample", JSON.stringify(data));

        axios
          .post(`${url}/MyMock/${id}/${user?.subdomain}/${user?.Email}`, fd, {
            headers: { "Content-Type": "multipart/form-data" },
            withCredentials: true,
          })
          .then((res) => {
            return true;
          })
          .catch((err) => console.log(err));
        if ((Settings?.capture_screen ?? true) && window.innerWidth > 768) {
          stopScreenSharing();
        }
        if (Settings?.capture_user ?? true) {
          StopCamera();
        }
        setThankyou(true);
        setmockstart(false);
      };
      if (isrecording && (Settings?.capture_user ?? true)) {
        stopVideo()
          .then((res) => {
            setIsrecording(false);
            if (val === "know") {
              for (const [key, value] of res.entries()) {
                fd.append(key, value);
              }
            }

            setTimeout(performRequest, 1000);
          })
          .catch((err) => {
            alert(err);
          });
      }

      if (iscapturing && (Settings?.capture_user ?? true)) {
        stopScreenshots()
          .then((result) => {
            setIscapturing(false);
            if (val === "know") {
              for (const [key, value] of result.entries()) {
                fd.append(key, value);
              }
            }

            setTimeout(performRequest, 1000);
          })
          .catch((error) => {
            alert(error);
          });
      }
      if (!iscapturing && !isrecording) {
        performRequest();
      }
    },
    [
      id,
      iscapturing,
      isrecording,
      user,
      QuestionData,
      setmockstart,
      Settings,
      setThankyou,
    ]
  );
  useEffect(() => {
    // Prevent Reloading
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    // Checking Network Connection
    const handleNetworkChange = () => {
      setState((prevState) => ({
        ...prevState,
        isonline: navigator.onLine,
      }));
    };
    //Take Screen Shots
    const handleVisibilityChange = () => {
      if (document.hidden && (Settings?.capture_screen ?? true)) {
        StartTakingScreenshots(5000);
      } else if (Settings?.capture_screen ?? true) {
        StopTakingScreenshots();
      }
      if (
        document.hidden &&
        Settings?.close_on_tab?.close &&
        Settings?.close_on_tab?.limit >= count_tab_change.current
      ) {
        count_tab_change.current++;
      } else if (
        document.hidden &&
        Settings?.close_on_tab?.close &&
        Settings?.close_on_tab?.limit < count_tab_change.current
      ) {
        Submit("know", "closed on tab change");
      }
    };

    const handleCopy = (e) => {
      e.preventDefault();
    };

    const handlePaste = (e) => {
      e.preventDefault();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("online", handleNetworkChange);
    window.addEventListener("offline", handleNetworkChange);
    document.addEventListener("visibilitychange", handleVisibilityChange);
    document.addEventListener("copy", handleCopy);
    document.addEventListener("paste", handlePaste);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("online", handleNetworkChange);
      window.removeEventListener("offline", handleNetworkChange);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      document.removeEventListener("copy", handleCopy);
      document.removeEventListener("paste", handlePaste);
    };
  }, [Settings, Submit]);

  const performRequest = async (val, reason, qno) => {
    const data = QuestionData(val, reason);
    fd.append("sample", JSON.stringify(data));
    handleRequest(fd, val);
  };
  const Next = async (val) => {
    setPassageIndex(1);
    setPassageAnswer([]);
    if (isonline) {
      if (questionCount !== passageSubQuestion + mock?.length - 1) {
        try {
          if (isrecording) {
            const res = await stopVideo();
            setIsrecording(false);
            if (val === "know") {
              await Promise.all(
                [...res.entries()].map(([key, value]) => fd.append(key, value))
              );
            }
            await performRequest(val, "clicked next1", QNO);
          } else if (iscapturing) {
            const result = await stopScreenshots();
            setIscapturing(false);
            if (val === "know") {
              await Promise.all(
                [...result.entries()].map(([key, value]) =>
                  fd.append(key, value)
                )
              );
            }
            await performRequest(val, "clicked next2", QNO);
          } else {
            performRequest(val, "clicked next", QNO);
          }
        } catch (err) {
          let confirm = window.confirm("There is some issue with the camera."); // video recording Stop Next
          if (confirm) {
            Submit(val);
          }
        }
      } else {
        Submit(val);
      }
    }
  };

  //Run The Code
  const OnhandleRun = useCallback(() => {
    setsrcDoc("");

    let data = ["mysql", "postgresql", "mongodb"].includes(language)
      ? {
          language: language,
          stdin: "",
          files: [
            {
              name: `index.${extension}`,
              content: answer,
            },
          ],
        }
      : {
          files: [
            {
              name: `Main.${extension}`,
              content: answer,
            },
          ],
        };

    if (answer?.length !== 0) {
      setCompilerStatus(true);

      if (language === "html") {
        let iframeinput = `<!DOCTYPE html> <html>
  <head>
    <style>${csscode}</style>
  </head>
  <body>${answer}</body>
  <script>${scriptcode}</script>
  </html>`;
        // Get the output from the iframe
        setOutput(iframeinput);
        setCompilerStatus(false);
        setInfinite(false);
        setState((prevState) => ({
          ...prevState,
          IsConsole: true,
        }));
        // setIsTestPass(false);
      } else {
        axios
          .post(`${url}/compiler/${language}`, data)
          .then((res) => {
            setsrcDoc(res.data.data);

            setCompilerStatus(false);
            setInfinite(false);
            setState((prevState) => ({
              ...prevState,
              IsConsole: true,
            }));
          })
          .catch((err) => {
            if (err.response.status === 500) {
              setInfinite(true);
              setCompilerStatus(false);
              setState((prevState) => ({
                ...prevState,
                IsConsole: true,
              }));
            }
          });
      }
    }
  }, [answer, extension, language, csscode, scriptcode]);

  const DrwawerTitle = () => {
    return (
      <>
        {" "}
        Output{" "}
        {/* {isTestPass !== null && (
          <>
            {isTestPass ? (
              <label className="text-success">Test Case Pass</label>
            ) : (
              <label className="text-danger">Test Case Fail</label>
            )}
          </>
        )} */}
      </>
    );
  };

  return (
    <>
      <div>
        <div className={styles.outerContainer}>
          {/* Heading Data */}
          <div className={styles.questionNo}>
            <h3>
              Question No :{" "}
              <span>
                {questionCount + 1} / {passageSubQuestion + mock?.length}
              </span>
            </h3>
            {!Settings?.TestTimer ?? false ? (
              <h3>
                Remaining Time :
                <span style={{ paddingLeft: "6px" }}>
                  <QuestionwiseTimer
                    seconds={minutes}
                    movetonext={Next}
                    qno={QNO}
                  />
                </span>
              </h3>
            ) : (
              <h3>
                Overall Test Time :
                <span style={{ paddingLeft: "6px" }}>
                  <TestTimer seconds={minutes} movetonext={Next} />
                </span>
              </h3>
            )}
          </div>
          {/* Question Type = MCQ */}
          {mock && mock[QNO]?.type === "Mcq" && (
            <div className={styles.mainContainer}>
              <div className={styles.container}>
                <div className={styles.mainQuestion}>
                  <div className={styles.question}>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: mock[QNO]?.qstn?.question
                          ? mock[QNO]?.qstn?.question
                          : mock[QNO]?.title,
                      }}
                    />
                    {mock[QNO]?.attachments?.length > 0 && (
                      <>
                        <label>AttachMents :</label>
                        <br />
                        <Image.PreviewGroup>
                          {mock[QNO]?.attachments.map((item, index) => {
                            return (
                              <Image src={item} alt="attached" width={100} />
                            );
                          })}
                        </Image.PreviewGroup>
                      </>
                    )}
                  </div>
                  <div className={styles.answer}>
                    <div className={styles.option}>
                      <p>Select one option</p>
                      {[
                        mock[QNO].opt1,
                        mock[QNO].opt2,
                        mock[QNO].opt3,
                        mock[QNO].opt4,
                      ].map((item, index) => {
                        return (
                          <div key={index}>
                            <label className={styles.radio_main}>
                              <input
                                type="radio"
                                name="ans"
                                value={item}
                                id={`opt${index}`}
                                onChange={(e) => {
                                  setAnswer(e.target.value);
                                  setState((prevState) => ({
                                    ...prevState,
                                    isdisable: false,
                                    IsClicked: true,
                                  }));
                                }}
                                checked={answer === item}
                              />
                              <span className={styles.design}></span>
                              <span className={styles.value}>
                                <label
                                  htmlFor={`opt${index}`}
                                  style={{ cursor: "pointer" }}
                                >
                                  {item}
                                </label>
                              </span>
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Question Type=Theory */}
          {mock && mock[QNO]?.type === "Question" && (
            <div className={styles.theoritical}>
              <div className={styles.leftside}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: mock[QNO]?.qstn?.question
                      ? mock[QNO]?.qstn?.question
                      : mock[QNO]?.title,
                  }}
                />
                {mock[QNO]?.attachments?.length > 0 && (
                  <>
                    {" "}
                    <label>AttachMents :</label>
                    <br />
                    <Image.PreviewGroup>
                      {mock[QNO]?.attachments.map((item, index) => {
                        return <Image src={item} alt="attached" width={100} />;
                      })}
                    </Image.PreviewGroup>
                  </>
                )}
              </div>
              <div className={styles.mainCameraBox}>
                {(Settings?.TestTimer ?? false) && (
                  <center>
                    <div className="d-flex">
                      {" "}
                      <label>Remaining Question Time :</label>
                      <span style={{ paddingLeft: "6px" }}>
                        <QuestionwiseTimer
                          seconds={120}
                          movetonext={Next}
                          qno={QNO}
                        />
                      </span>
                    </div>
                    <br />
                  </center>
                )}

                <div className={styles.cameraBox}>
                  <Webcam width={400} mirrored={true} />
                </div>
                <div className={styles.warning}>
                  <h4>We are recording you.</h4>
                  <h4>Please look at screen while answering</h4>
                </div>
              </div>
            </div>
          )}

          {/* Question Type = Coding */}
          {mock && mock[QNO]?.type === "Coding" && (
            <div className={styles.CodingMainBox}>
              {/* Left Side */}
              <div className={styles.codingOuterContainer} ref={slider}>
                <div className={styles.questionWin}>
                  <div className={styles.codingquestion}>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: mock[QNO]?.qstn?.question
                          ? mock[QNO]?.qstn?.question
                          : mock[QNO]?.title,
                      }}
                    />

                    <pre className={styles.example}>
                      {mock && mock[QNO].qstn.example}
                    </pre>
                    {mock[QNO]?.attachments?.length > 0 && (
                      <>
                        {" "}
                        <label>AttachMents :</label>
                        <br />
                        <Image.PreviewGroup>
                          {mock[QNO]?.attachments.map((item, index) => {
                            return (
                              <Image
                                src={item}
                                alt="attached"
                                width={100}
                                key={index}
                              />
                            );
                          })}
                        </Image.PreviewGroup>
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.btncontainer}>
                  <button
                    onClick={() => Next("dont know", "dnk")}
                    style={
                      isonline
                        ? { backgroundColor: "rgb(188, 165, 38)" }
                        : {
                            backgroundColor: "rgb(175, 174, 174)",
                            cursor: "not-allowed",
                          }
                    }
                    disabled={!isonline}
                  >
                    Skip question
                  </button>
                </div>

                <Drawer
                  title={<DrwawerTitle />}
                  placement="bottom"
                  closable={true}
                  onClose={() =>
                    setState((prevstate) => ({
                      ...prevstate,
                      IsConsole: false,
                    }))
                  }
                  open={IsConsole && extension !== "html"}
                  getContainer={false}
                  height="90%"
                  style={{
                    width: "99.7%",
                    margin: "0 1px 0 1px",
                    overflow: "auto",
                  }}
                >
                  <pre>
                    {srcDoc && (srcDoc?.stdout || srcDoc?.stderr)}

                    {compilestatus && "Running"}
                    {Isinfinite && "Infinite Loop"}
                  </pre>
                </Drawer>

                {/* For HTML CSS JS */}

                {extension === "html" && IsConsole && (
                  <Modal
                    open={IsConsole}
                    centered
                    width="100%"
                    style={{ height: "100vh" }}
                    onCancel={() =>
                      setState((prevstate) => ({
                        ...prevstate,
                        IsConsole: false,
                      }))
                    }
                    onOk={() =>
                      setState((prevstate) => ({
                        ...prevstate,
                        IsConsole: false,
                      }))
                    }
                    title="WebView"
                  >
                    <iframe
                      title="HTML"
                      srcDoc={htmloutput}
                      width="100%"
                      style={{ height: "82vh" }}
                    />
                  </Modal>
                )}
              </div>

              {/* Right Side Window */}
              <div className={styles.codingWin}>
                <div className={styles.selectBox}>
                  <Select
                    options={options}
                    value={labellang}
                    style={{
                      width: 200,
                    }}
                    onChange={(val) => handleChange(val)}
                  />

                  <div className={styles.runBtns}>
                    <Button
                      onClick={() => OnhandleRun()}
                      disabled={!isonline}
                      style={{
                        cursor: isonline ? "pointer" : "not-allowed",
                        marginRight: "35px",
                      }}
                      className={styles.runbtn}
                      type="primary"
                      danger
                      loading={compilestatus}
                    >
                      Run
                    </Button>
                    <Button
                      onClick={() => Next("know", "k")}
                      disabled={!isonline}
                      style={{ cursor: isonline ? "pointer" : "not-allowed" }}
                    >
                      {mock && QNO === mock?.length - 1 ? "Submit" : "Next"}{" "}
                      <img src={nextArrow} alt="arrow" />
                    </Button>

                    <img
                      style={
                        !IsConsole || !isonline
                          ? { visibility: "hidden" }
                          : { visibility: "visible" }
                      }
                      onClick={() =>
                        setState((prevstate) => ({
                          ...prevstate,
                          IsConsole: false,
                        }))
                      }
                      src={closeIcon}
                      alt="closeIcon"
                    />
                  </div>
                </div>
                <Tabs
                  defaultActiveKey="1"
                  items={items}
                  style={{ backgroundColor: "white" }}
                />
              </div>
            </div>
          )}
          {mock && mock[QNO]?.type === "Passage" && (
            <div className={styles.mainContainer}>
              <div className={styles.container}>
                <div className={styles.mainQuestion}>
                  <div className={styles.question}>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: mock[QNO].qstn.question
                          ? mock[QNO].qstn.question
                          : mock[QNO].title,
                      }}
                    />
                    {mock[QNO]?.attachments?.length > 0 && (
                      <>
                        <label>AttachMents :</label>
                        <br />
                        <Image.PreviewGroup>
                          {mock[QNO]?.attachments.map((item, index) => {
                            return (
                              <Image src={item} alt="attached" width={100} />
                            );
                          })}
                        </Image.PreviewGroup>
                      </>
                    )}
                  </div>
                  <div className={styles.answer}>
                    <div className={styles.PassageOption}>
                      <p>Select one option</p>

                      {mock[QNO]?.qstn?.passageQuestions?.length > 0 &&
                        mock[QNO]?.qstn?.passageQuestions
                          .slice(passageIndex - 1, passageIndex)
                          .map((ele, index1) => {
                            return (
                              <div className={styles.allOption} key={index1}>
                                <label>
                                  Ques. {passageIndex} : {ele?.description}
                                </label>
                                {ele?.options?.map((item, index) => {
                                  return (
                                    <>
                                      <label
                                        className={styles.PassageRadio_main}
                                      >
                                        <input
                                          type="radio"
                                          name={`ans${index1}`}
                                          value={item}
                                          id={`opt${index}`}
                                          onChange={(e) => {
                                            setPassageAnswer([
                                              ...passageAnswer,
                                              e.target.value,
                                            ]);
                                            setAnswer(e.target.value);
                                            setState((prevState) => ({
                                              ...prevState,
                                              isdisable: false,
                                              IsClicked: true,
                                            }));
                                          }}
                                          checked={answer === item}
                                        />
                                        <span className={styles.design}></span>
                                        <span className={styles.value}>
                                          <label
                                            htmlFor={`opt${index}`}
                                            style={{ cursor: "pointer" }}
                                          >
                                            {item}
                                          </label>
                                        </span>
                                      </label>
                                    </>
                                  );
                                })}
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Buttons */}
          <div
            className={
              mock && mock[QNO]?.type !== "Coding"
                ? styles.bottomBtn
                : styles.hideBottombtn
            }
          >
            <button
              onClick={() => Next("dont know", "dnk2")}
              className={isonline ? styles.enablebtn : styles.disablebtn}
              disabled={!isonline}
            >
              Skip question
            </button>

            <button
              onClick={() => {
                if (
                  mock[QNO]?.type === "Passage" &&
                  passageIndex < mock[QNO]?.qstn?.passageQuestions?.length
                ) {
                  setPassageIndex(passageIndex + 1);
                  setQuestionCount(questionCount + 1);
                } else {
                  Next("know", "k2");
                }
              }}
              disabled={!isonline || (IsClicked && answer === "") || isdisable}
              className={
                !isonline || (IsClicked && answer === "") || isdisable
                  ? styles.disablebtn
                  : styles.enablebtn
              }
              onMouseEnter={() =>
                window.innerWidth > 768 &&
                setState((prevState) => ({ ...prevState, show: true }))
              }
              onMouseOut={() =>
                setState((prevState) => ({ ...prevState, show: false }))
              }
            >
              {mock && questionCount === mock?.length - 1 + passageSubQuestion
                ? "Submit"
                : "Next"}
              <img
                src={
                  !isonline || (IsClicked && answer === "") || isdisable
                    ? nextArrow
                    : blackNextIcon
                }
                alt="icon"
              />
            </button>
            <Toast show={show} className={styles.toastalign}>
              <Toast.Header>
                <strong className="me-auto">Warning</strong>
              </Toast.Header>
              <Toast.Body>
                <label>
                  Make sure you give the answer. You can't come back.{" "}
                </label>
              </Toast.Body>
            </Toast>
          </div>
        </div>
      </div>
      <Modal
        open={!isonline}
        title="No Internet Connectivity"
        maskClosable={false}
        closable={false}
        footer={null}
      >
        <center>
          <GlobalOutlined style={{ fontSize: "50px" }} />
          <br />
          <label>Kindly Connect to Internet.</label>
        </center>
      </Modal>
    </>
  );
};

export default Question;
