import React, { useEffect, useState } from "react";
import styles from "./TestSeriesDetails.module.css";
import { getrequest } from "../../Middleware/managerequest";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { Collapse, Result, Spin } from "antd";
import { BsUnlock } from "react-icons/bs";
import { MdOutlineFindInPage } from "react-icons/md";
import {  useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const TestSeriesDetails = () => {
  const [Isloading, setIsLoding] = useState(true);
  const [courseData, setCourseData] = useState([]);
  const [itemToRender, setItemToRender] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { Data } = useSelector((state) => state);
  const { user } = Data;
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  useEffect(() => {
    setIsLoding(true);
    const fetchData = async () => {
      setIsLoding(true);
      try {
        const response = await getrequest(
          `/user/testseries/${user?.subdomain}/${id}`
        );
        setIsLoding(false);
        setCourseData(response?.data?.data);
      } catch (error) {
        setIsLoding(false);
        console.error("Error fetching course data:", error);
      }
    };
    if (user) {
      fetchData();
    }
  }, [user]);
  const handlerRedirect = (id) => {
    console.log(id?.Test_Details.Testid);
    if (user) {
      navigate(`/MyMock/${id?.Test_Details?.Mockid}`, {
        state: {
          title: id?.Test_Details?.Test_Name,
          username: user?.Email,
          subdomain: user?.subdomain,
        },
      });
    }
  };

  useEffect(() => {
    if (courseData?.length === 0) return; // Avoid processing if data hasn't been fetch
    setItemToRender(
      courseData?.sections?.map((ele, index) => ({
        key: index,
        details: ele?.content?.map((item) => {
          return {
            link: item?.external_link?.link,
            dics: item?.Description,
          };
        }),
        label: (
          <label className={styles.pointText}>
            {ele?.sectionName || "No name provided"}
            <span>
              {ele?.content?.filter((ele) => ele.type === "test").length} Tests
            </span>
          </label>
        ),
        children: ele?.content?.map((para, index) => {
          const date = new Date(para?.Test_Details?.MockStart);
          const formattedDateTime = date.toLocaleString("en-US", {
            weekday: "short", // 'Mon'
            day: "numeric", // '20'
            month: "short", // 'Aug'
            year: "numeric", // '2024'
            hour: "numeric", // '10'
            minute: "2-digit", // '30'
            hour12: true, // 'AM/PM' format
          });

          return (
            <div
              key={index}
              style={
                new Date(para?.Test_Details?.MockStart) > new Date()
                  ? { cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
              className={styles.test}
              onClick={() =>
                new Date(para?.Test_Details?.MockStart) > new Date()
                  ? null
                  : handlerRedirect(para)
              }
            >
              <div className={styles.testNameWithDetails}>
                <h5>
                  <MdOutlineFindInPage style={{ marginRight: "10px" }} />
                  {para?.content_Name}
                </h5>
                <label>{formattedDateTime}</label>
              </div>
              <BsUnlock style={{ height: "20px", width: "20px" }} />
            </div>
          );
        }),
      }))
    );
  }, [courseData]);

  return (
    <>
      {Isloading ? (
        <Spin
          style={{
            margin: "160px auto auto auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      ) : courseData?.length === 0 ? (
        <div className={styles.main}>
          <Result
            style={{ fontSize: "20px" }}
            status="warning"
            title="Currently, this course is not available. Thank you for your patience."
          />
        </div>
      ) : (
        courseData && (
          <>
            <div className={styles.main}>
              <div className={styles.banner}>
                <aside>
                  <h2>{courseData?.CourseTitle}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: courseData?.description,
                    }}
                  />
                  <button>Start Learning..</button>
                </aside>
                <section>
                  <img src={courseData?.thumbnail} alt="thumbnail" />
                </section>
              </div>

              <div className={styles.pricing}>
                <h2>Pricing Plans</h2>
                <section>
                  {["Free", "Standard", "Premium"].map((plan, index) => (
                    <div key={index}>
                      <h5>{plan}</h5>
                      <span>Features</span>
                      {Array(6)
                        .fill("Horem ipsum dolor sit amet, consectetur")
                        .map((feature, idx) => (
                          <label key={idx}>
                            <div>
                              <IoMdCheckmarkCircle />
                            </div>
                            {feature}
                          </label>
                        ))}
                      <button>Buy Now</button>
                    </div>
                  ))}
                </section>
              </div>
              {courseData?.sections.length > 0 ? (
                <div className={styles.courseDetails}>
                  <h2>What you'll find in this course</h2>
                  <Collapse items={itemToRender} defaultActiveKey={[0]} />
                </div>
              ) : (
                <div
                  className={styles.courseDetails}
                  style={{ marginBottom: "50px" }}
                >
                  <h2>This course is not available now. Please wait...</h2>
                </div>
              )}
            </div>{" "}
          </>
        )
      )}
    </>
  );
};

export default TestSeriesDetails;
