import React, { useState, useCallback, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import styles from "./userhome.module.css";
import whatsapp from "../../icons/whatappIcon.webp";
import { getrequest } from "../Middleware/managerequest";
import {
  AppstoreOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  ExportOutlined,
  HistoryOutlined,
  MehOutlined,
  ProductOutlined,
  RightOutlined,
  UnorderedListOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import {
  Result,
  Avatar,
  Button,
  Layout,
  Breadcrumb,
  Skeleton,
  Timeline,
  Divider,
  Card,
  Menu,
  Progress,
  Modal,
  Typography,
  Tooltip,
  message,
} from "antd";
import {
  ContainerOutlined,
  UserOutlined,
  PieChartOutlined,
  CheckSquareOutlined,
  LockOutlined,
  CodeOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { setMockdata, setAttempted } from "../../store/Reducer";
import { replacesessions } from "../../store/Reducers/SessionsReducer";
import { FaRegCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import PracticeDetails from "../Practice_Mode/Practice_Details";
import { toast, ToastContainer } from "react-toastify";
import PracticeChart from "./PracticeChart";
import UserAttendance from "./Attendance/UserAttendance";
import ProfileSlider from "../Navigator_Footer/ProfileSlider";
import { GetMySessions } from "../Middleware/graphApiHandler";
import Profileupdate from "./ProfileComponents/profile_update";
import LatestNavbar from "../Navigator_Footer/LatestNavbar";
import AllTests from "./TestSection/AllTests";
import Meta from "antd/es/card/Meta";
const { Sider } = Layout;
const { Text } = Typography;

const months = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"];
function getItem(label, key, icon, children, type, disabled) {
  return {
    key,
    icon,
    children,
    label,
    type,
    disabled,
  };
}

const openWhatsAppChat = () => {
  // Replace with your actual WhatsApp chat link
  const chatLink = "https://api.whatsapp.com/send?phone=919315744944";
  window.open(chatLink, "_blank");
};

const UserHome = () => {
  const { accounts, instance } = useMsal();
  const [menuKey, setMenuKey] = useState("dashboard");
  const [loading, setLoading] = useState(false);
  const [PracticeData, setPracticeData] = useState([]);
  const [Current_Dashboard_item, setCurrent_Dashboard] = useState([]);
  const [groupid, setGroupid] = useState([]);
  const [IsChartOpen, setIsChartOpen] = useState(false);
  const [practicedetails, setPracticeDetails] = useState(null);
  const [testserie, setTestserie] = useState([]);
  const [weeklyPracticeProgress, setWeeklyPracticeProgress] = useState([]);
  const [pind, setPind] = useState(-1);
  const [isloading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const { Data, StudentProfile, Sessions } = useSelector((state) => state);
  const [openDwa, setOpenDwa] = useState(false);
  const { user, Reviewed, Attempted, Expired, Available, Upcoming } = Data;

  const [openPlayer, setOpenPlayer] = useState(false);

  const [isprofileupdate, setIsprofileupdate] = useState({
    ispersonal: false,
    iseducations: false,
  });

  const { imagesurl } = StudentProfile;
  const [attendanceData, setAttendanceData] = useState({
    total_sessions: 10,
    attended_sessions: 5,
    percentage: 50,
  });
  const dispatch = useDispatch();
  // Filter the data
  const filter = useCallback(
    (value) => {
      const submittedItems = value.filter(
        (item) =>
          item.users.some(
            (val) => val.value === user.Email && val.isSubmitted
          ) &&
          item.Attempted.some(
            (val) => val.Email === user.Email && !val.FeedBack
          )
      );
      const feedbackItems = value.filter((item) =>
        item.Attempted.some((val) => val.Email === user.Email && val.FeedBack)
      );
      const expiredMocks = [];
      const available = [];
      const upcoming = [];

      value.forEach((item) => {
        if (
          item.Attempted.every((val2) => val2.Email !== user.Email) &&
          new Date(item.Mockend) > new Date() &&
          new Date(item.MockStart) < new Date()
        ) {
          available.push(item);
        } else if (
          item.Attempted.every((val2) => val2.Email !== user.Email) &&
          new Date(item.Mockend) < new Date()
        ) {
          expiredMocks.push(item);
        } else if (
          item.Attempted.every((val2) => val2.Email !== user.Email) &&
          new Date(item.MockStart) > new Date()
        ) {
          upcoming.push(item);
        }
      });

      const newexpired = [...expiredMocks].sort(
        (a, b) => new Date(b.Mockend) - new Date(a.Mockend)
      );
      dispatch(
        setAttempted({
          submittedItems,
          feedbackItems,
          newexpired,
          available,
          upcoming,
        })
      );
    },
    [dispatch, user]
  );
  // Fetch The Data
  useEffect(() => {
    setLoading(true);
    if (user) {
      getrequest(`/user/mymocks/${user?.subdomain}/${user?.Email}`)
        .then(async (res) => {
          setWeeklyPracticeProgress(res.data.progress.reverse());
          dispatch(setMockdata(res.data.alldata.reverse()));
          const newdata = [...res.data.alldata];
          filter(
            newdata.sort((a, b) => new Date(a.Mockend) - new Date(b.Mockend))
          );

          setIsprofileupdate(res.data.personal_details);
          setGroupid(res.data.groupid);
          setTestserie(res.data.courses);
          setAttendanceData(res.data.attend_details);

          if (accounts.length > 0) {
            instance
              .acquireTokenSilent({
                account: accounts[0],
                scopes: process.env.SCOPE,
              })
              .then(async (res) => {
                let result = await GetMySessions(res.accessToken);
                setLoading(false);
                if (result?.upcomingSessions) {
                  dispatch(replacesessions(result));
                }
              })
              .catch((err) => console.log(err));
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          toast.error("Server Error", { position: "top-center" });
          console.log(err);
        });
    }
  }, [user, dispatch, filter, navigate, accounts, instance]);

  const Fetch_Practice_data = () => {
    if (PracticeData.length === 0) {
      setIsloading(true);
      getrequest(
        `/user/mypractice_data/${user?.subdomain}/${user?.Email}/${groupid[0].id}`
      )
        .then((res) => {
          setPracticeData(res.data.Practice_data);
          setIsloading(false);
        })
        .catch((err) => {
          toast.error("Server Error while fetching practice data", {
            position: "top-center",
          });
          setIsloading(false);
        });
    }
  };

  // Mock Start
  const MockStart = (val) => {
    let date = new Date(val);
    let startdate = String(date.getDate()).padStart(2, "0");
    let startmonth = months[date.getMonth()];
    let startyear = date.getFullYear();
    let hours = date.getHours();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return (
      <div>
        <span>{startdate + " " + startmonth + " " + startyear}</span>
        <br />
        {` ${
          hours + ":" + String(date.getMinutes()).padStart(2, "0") + " " + ampm
        }`}
      </div>
    );
  };

  // Mock Status
  const MockStatus = (val) => {
    const available = Available.find((item) => item.Mockid === val);
    const upcoming = Upcoming.find((item) => item.Mockid === val);
    const review = Reviewed.find((item) => item.Mockid === val);
    const attempted = Attempted.find((item) => item.Mockid === val);
    const expired = Expired.find((item) => item.Mockid === val);
    if (available) {
      return { code: "available" };
    } else if (upcoming) {
      return { code: "upcoming" };
    } else if (review) {
      return { code: "reviewed" };
    } else if (attempted) {
      return { code: "attempted" };
    } else if (expired) {
      return { code: "expired" };
    }
  };

  // Action
  const ActionButton = (val, title) => {
    const { code } = MockStatus(val);

    switch (code) {
      case "available":
        return (
          <span
            className={styles.action_btn}
            onClick={() =>
              user?.IsAllowTest
                ? message.warning(
                    "You are mot allowed to start the test at this time. Kindly contact to your Institute"
                  )
                : navigate(`/MyMock/${val}`, {
                    state: {
                      title: title,
                      username: user?.Email,
                      subdomain: user?.subdomain,
                    },
                  })
            }
          >
            Start Now <RightOutlined style={{ fontSize: "15px" }} />
          </span>
        );
      case "upcoming":
        return <span className={styles.action_btn}>Upcoming</span>;
      case "reviewed":
        return (
          <span
            className={styles.action_btn}
            onClick={() => navigate(`/given_mock/${val}`)}
            style={{ color: "green" }}
          >
            View Results <RightOutlined style={{ fontSize: "15px" }} />
          </span>
        );
      case "attempted":
        return <span className={styles.action_btn}>Under Review</span>;
      case "expired":
        return (
          <span className={styles.action_btn} style={{ color: "red" }}>
            Expired <MehOutlined />
          </span>
        );
      default:
        return null;
    }
  };

  // Menu Items
  const items = [
    getItem(
      <span className={styles.menulabel}>Dashboard</span>,
      "dashboard",
      <PieChartOutlined />
    ),
    getItem(
      <span className={styles.menulabel}>Tests</span>,
      "sub1",
      <ContainerOutlined />,
      ["available", "attempted", "expired"].map((item) => {
        return getItem(
          <span className={styles.menulabel}>
            {item.charAt(0).toUpperCase() + item.slice(1)} Tests
          </span>,
          item
        );
      })
    ),

    getItem(
      <span className={styles.menulabel}>My test series</span>,
      "testserie",
      <ProductOutlined />
    ),
    getItem(
      <span className={styles.menulabel}>Practice</span>,
      "practice",
      groupid.length !== 0 ? <CodeOutlined /> : <LockOutlined />,
      null,
      null,
      groupid.length === 0
    ),

    // getItem(
    //   <span className={styles.menulabel}>Job Posts</span>,
    //   "sub2",
    //   <LockOutlined />,
    //   [
    //     getItem("Frontend Developer", "9"),
    //     getItem("FullStack Developer", "10"),
    //     getItem("Other Role", "11"),
    //   ],
    //   null,
    //   true
    // ),
  ];

  const handlerClick = (e) => {
    setMenuKey(e.key);

    if (e.key === "available") {
      setCurrent_Dashboard([...Available, ...Upcoming]);
    } else if (e.key === "attempted") {
      setCurrent_Dashboard([...Reviewed, ...Attempted]);
    } else if (e.key === "expired") {
      setCurrent_Dashboard(Expired);
    } else if (e.key === "practice") {
      Fetch_Practice_data();
    }

    setOpenDwa(false);
  };

  const Skills = (val) => {
    return val.map((item, ind) => {
      return (
        <span key={ind}>
          {item.MainTags}
          {val.length - 1 !== ind && ","}
        </span>
      );
    });
  };

  // const SessionHelper = (val) => {
  //   const newdate = new Date(val);

  //   let day = weekdays[newdate.getDay()];
  //   let date = newdate.getDate();
  //   const hours = newdate.getHours().toString().padStart(2, "0");
  //   const minutes = newdate.getMinutes().toString().padStart(2, "0");
  //   const fulltime = hours + ":" + minutes;

  //   return { day, date, fulltime };
  // };

  const CountQuestion = (val, type) => {
    let objective = 0,
      coding = 0;

    if (type === "q") {
      val.forEach((item) => {
        objective += item.Question + item.Mcq;
      });
      return objective;
    } else {
      val.forEach((item) => {
        coding += item.Coding;
      });
      return coding;
    }
  };

  const updateProfile = () => {
    setIsprofileupdate({ iseducations: false, ispersonal: false });
  };

  return (
    <Layout className={styles.mainContainer}>
      {(isprofileupdate?.iseducations || isprofileupdate?.ispersonal) && (
        <Profileupdate
          details={isprofileupdate}
          updateProfile={updateProfile}
          user={user}
          accounts={accounts}
        />
      )}
      <ToastContainer />
      {/* <div className={styles.chatBox} onClick={() => openWhatsAppChat()}>
        <Popover
          content={<b>Get Help !</b>}
          placement="left"
          style={{ width: "20px" }}
        >
          <img src={whatsapp} alt="whatapp" width={70} />
        </Popover>
      </div> */}
      <Sider
        className={styles.slider}
        breakpoint="lg"
        collapsedWidth="0"
        width={window.innerWidth < 350 ? 200 : "18%"}
        style={{ backgroundColor: "#ffffff", height: "100vh" }}
      >
        <div className={styles.userProfile}>
          <Avatar
            src={imagesurl ?? ""}
            style={{ backgroundColor: "white", borderColor: "gray" }}
            icon={<UserOutlined style={{ color: "black" }} />}
            size={80}
          />
          <label>
            <label className="mx-2">{user?.FullName}</label>{" "}
            <ExportOutlined
              style={{ fontSize: "15px", cursor: "pointer" }}
              onClick={() => navigate("/profile")}
            />
          </label>
          <span>{user?.Email}</span>
        </div>
        <Menu
          onClick={(e) => handlerClick(e)}
          mode="inline"
          theme="light"
          // collapsed={collapsed}
          items={items}
          activeKey={menuKey}
        />
      </Sider>

      <div className={styles.contentSideSmall}>
        <div>
          <LatestNavbar openDwa={openDwa} setOpenDwa={setOpenDwa} />

          <ProfileSlider
            setOpenDwa={setOpenDwa}
            openDwa={openDwa}
            user={user}
            handlerClick={handlerClick}
            setMenuKey={setMenuKey}
            menuItems={items}
            imageurl={imagesurl}
          />
        </div>

        {/* All Tets */}
        {menuKey === "alltests" && (
          <div style={{ marginTop: "70px" }}>
            <AllTests />
          </div>
        )}
        {/* Breadcrumb Navigator */}
        {menuKey === "available" && (
          <Breadcrumb
            className={styles.breadcrumb}
            items={[
              {
                title: (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setMenuKey("dashboard")}
                  >
                    Dashboard
                  </span>
                ),
              },
              { title: "Available Test" },
            ]}
          />
        )}

        {menuKey === "expired" && (
          <Breadcrumb
            className={styles.breadcrumb}
            items={[
              {
                title: (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setMenuKey("dashboard")}
                  >
                    Dashboard
                  </span>
                ),
              },
              { title: "All Expired Tests" },
            ]}
          />
        )}

        {menuKey === "upcoming" && (
          <Breadcrumb
            className={styles.breadcrumb}
            items={[
              {
                title: (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setMenuKey("dashboard")}
                  >
                    Dashboard
                  </span>
                ),
              },
              { title: "All Upcoming Tests" },
            ]}
          />
        )}
        {menuKey === "attempted" && (
          <Breadcrumb
            className={styles.breadcrumb}
            items={[
              {
                title: (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setMenuKey("dashboard")}
                  >
                    Dashboard
                  </span>
                ),
              },
              { title: "All Attempted Tests" },
            ]}
          />
        )}
        {/* Dashboard */}
        {menuKey === "dashboard" && (
          <section className={styles.dashboard_new}>
            {/* Left Side */}

            <div className={styles.left_side}>
              {/* First Row */}
              <section className={styles.first_row}>
                <div className={styles.first_row_first_card}>
                  <div className={styles.first_card_title}>
                    <label className={styles.card_icon}>
                      <CheckSquareOutlined />
                    </label>

                    <label className={styles.card_title}>All Tests</label>
                  </div>
                  <div className={styles.card_details}>
                    <div>
                      <label
                        title="Click to View"
                        onClick={() => handlerClick({ key: "available" })}
                        className={styles.details_label}
                      >
                        Available Tests
                      </label>
                      <label onClick={() => handlerClick({ key: "available" })}>
                        <b>
                          {loading ? (
                            <Skeleton.Avatar
                              shape="block"
                              style={{
                                backgroundColor: "white",
                                height: "15px",
                              }}
                              active={loading}
                            />
                          ) : (
                            Available.length + Upcoming.length
                          )}
                        </b>
                      </label>
                    </div>
                    <div>
                      <label
                        title="Click to View"
                        onClick={() => handlerClick({ key: "attempted" })}
                        className={styles.details_label}
                      >
                        Attempted Tests
                      </label>
                      <label onClick={() => handlerClick({ key: "attempted" })}>
                        <b>
                          {loading ? (
                            <Skeleton.Avatar
                              shape="block"
                              style={{
                                backgroundColor: "white",
                                height: "15px",
                              }}
                              active={loading}
                            />
                          ) : (
                            Reviewed.length + Attempted.length
                          )}
                        </b>
                      </label>
                    </div>
                    <div>
                      <label
                        title="Click to View"
                        onClick={() => handlerClick({ key: "expired" })}
                        className={styles.details_label}
                      >
                        Expired Tests
                      </label>
                      <label onClick={() => handlerClick({ key: "expired" })}>
                        <b>
                          {loading ? (
                            <Skeleton.Avatar
                              shape="block"
                              style={{
                                backgroundColor: "white",
                                height: "15px",
                              }}
                              active={loading}
                            />
                          ) : (
                            Expired.length
                          )}
                        </b>
                      </label>
                    </div>
                  </div>
                  <div className="mx-4 mt-2">
                    <InfoCircleOutlined /> For more details, please open{" "}
                    <b>Tests</b> section.
                  </div>
                </div>
                <div className={styles.first_row_first_card1}>
                  <div className={styles.first_card_title}>
                    <label className={styles.card_icon2}>
                      <ScheduleOutlined />
                    </label>

                    <label className={styles.card_title}>Attendance</label>
                  </div>
                  <div>
                    <div className={styles.card_details}>
                      <div>
                        <label className={styles.details_label}>
                          Total Sessions{" "}
                        </label>
                        <b>
                          {loading ? (
                            <Skeleton.Avatar
                              shape="block"
                              style={{
                                backgroundColor: "white",
                                height: "15px",
                              }}
                              active={loading}
                            />
                          ) : (
                            attendanceData?.total_sessions
                          )}
                        </b>
                      </div>
                      <div>
                        <label className={styles.details_label}>
                          Attended Sessions{" "}
                        </label>
                        <b>
                          {loading ? (
                            <Skeleton.Avatar
                              shape="block"
                              style={{
                                backgroundColor: "white",
                                height: "15px",
                              }}
                              active={loading}
                            />
                          ) : (
                            attendanceData?.attended_sessions
                          )}
                        </b>
                      </div>
                      <div>
                        <label className={styles.details_label}>
                          Percentage
                        </label>
                        <b>
                          {loading ? (
                            <Skeleton.Avatar
                              shape="block"
                              style={{
                                backgroundColor: "white",
                                height: "15px",
                              }}
                              active={loading}
                            />
                          ) : (
                            attendanceData?.percentage + "%"
                          )}
                        </b>
                      </div>
                    </div>
                    <div
                      onClick={() => handlerClick({ key: "attendance" })}
                      className={styles.detail_view}
                    >
                      {" "}
                      See Attendance Details
                    </div>
                  </div>
                </div>
              </section>
              <div className={styles.practice_chart}>
                <PracticeChart data={weeklyPracticeProgress} />
              </div>
            </div>

            {/* Right Side */}

            <div className={styles.right_side}>
              <div>
                <CalendarOutlined style={{ fontSize: "18px" }} />{" "}
                <label className={styles.details_label2}>Upcoming Tests</label>
              </div>
              {[...Upcoming, ...Available]?.length === 0 && loading ? (
                <div className={styles.skeleton}>
                  <Skeleton.Input
                    style={{ width: "380px", height: "80px" }}
                    active={loading}
                  />
                  <Skeleton.Input
                    style={{ width: "380px", height: "80px" }}
                    active={loading}
                  />
                </div>
              ) : (
                !loading &&
                [...Upcoming, ...Available].length === 0 && (
                  <>
                    <div className={styles.right_upcomingSessions}>
                      <LockOutlined style={{ fontSize: "20px" }} />
                      <label className="mx-3">No Upcoming Tests</label>
                    </div>
                  </>
                )
              )}
              {[...Upcoming, ...Available].length > 0
                ? [...Available, ...Upcoming].slice(0, 2).map((item, ind) => {
                    const date = new Date(item.MockStart);
                    const formattedDate = date.toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "2-digit",
                    });
                    const splitvalue = formattedDate.split(" ");
                    const formattedTime = date.toLocaleTimeString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    });
                    const isBtn = MockStatus(item?.Mockid);
                    return (
                      <div
                        className={styles.right_upcomingSessions}
                        style={
                          isBtn.code === "available"
                            ? { background: "#8BC34A" }
                            : { background: "rgb(30, 136, 229)" }
                        }
                        title="Upcoming Test"
                        key={ind}
                      >
                        <div className={styles.right_upcomingSessions_dates}>
                          <label>{splitvalue[0]}</label>
                          <label id={styles.month}>{splitvalue[1]}</label>
                          <label>{date.getFullYear()}</label>
                        </div>
                        <div className={styles.sessions_title}>
                          <Text
                            className={styles.session_title}
                            ellipsis={{ tooltip: formattedTime }}
                          >
                            {formattedTime}
                          </Text>

                          <label>{item?.MockTitle}</label>
                          {item.type === "Offline OMR" && (
                            <label>{"OMR Based"}</label>
                          )}
                        </div>
                        <div>
                          {isBtn.code === "available" ? (
                            <Tooltip
                              placement="topRight"
                              title={"Start your test now"}
                            >
                              <a
                                rel="noreferrer"
                                onClick={() =>
                                  user?.IsAllowTest
                                    ? message.warning(
                                        "You are mot allowed to start the test at this time. Kindly contact to your Institute"
                                      )
                                    : navigate(`/MyMock/${item?.Mockid}`, {
                                        state: {
                                          title: item?.MockTitle,
                                          username: user?.Email,
                                          subdomain: user?.subdomain,
                                        },
                                      })
                                }
                                className={styles.Join_btn}
                              >
                                Start
                              </a>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              placement="topRight"
                              title={"View all the upcoming test"}
                            >
                              <a
                                onClick={() =>
                                  handlerClick({ key: "available" })
                                }
                                className={styles.Join_btn}
                              >
                                View
                              </a>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    );
                  })
                : ""}
              {/* Expired Sessions */}
              <div>
                <CarryOutOutlined style={{ fontSize: "18px" }} />{" "}
                <label className={styles.details_label2}>Attempted Tests</label>
              </div>

              {loading && [...Attempted, ...Reviewed].length === 0 ? (
                <div className={styles.skeleton}>
                  <Skeleton.Input
                    style={{ width: "380px", height: "80px" }}
                    active={loading}
                  />
                  <Skeleton.Input
                    style={{ width: "380px", height: "80px" }}
                    active={loading}
                  />
                </div>
              ) : (
                !loading &&
                [...Attempted, ...Reviewed].length === 0 && (
                  <>
                    <div className={styles.right_expiredSessions}>
                      <LockOutlined
                        style={{ fontSize: "20px", color: "black" }}
                      />{" "}
                      <label className="mx-3" style={{ color: "black" }}>
                        No Attempted Tests
                      </label>
                      <label
                        style={{ visibility: "hidden", width: "105px" }}
                      ></label>
                    </div>
                  </>
                )
              )}

              {[...Attempted, ...Reviewed].length > 0 &&
                [...Attempted, ...Reviewed].slice(0, 3).map((item, ind) => {
                  const date = new Date(item.MockStart);
                  const formattedDate = date.toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "2-digit",
                  });
                  const splitvalue = formattedDate.split(" ");
                  const formattedTime = date.toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  });
                  const isBtn = MockStatus(item.Mockid);

                  return (
                    <div
                      className={styles.right_expiredSessions}
                      title="Attempted Tests"
                      key={ind}
                    >
                      <div className={styles.right_upcomingSessions_dates2}>
                        <label>{splitvalue[0]}</label>
                        <label id={styles.month}>{splitvalue[1]}</label>
                        <label>{date.getFullYear()}</label>
                      </div>

                      <div
                        className="d-flex flex-column mx-3"
                        style={{ color: "black" }}
                      >
                        <Text
                          className={styles.session_title1}
                          ellipsis={{ tooltip: formattedTime }}
                        >
                          {formattedTime}
                        </Text>

                        <label>{item?.MockTitle}</label>
                        {item?.type === "Offline OMR" && (
                          <label>{"OMR Based"}</label>
                        )}
                      </div>

                      <div
                        style={{
                          color: "black",
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                      >
                        <Tooltip
                          placement="topRight"
                          title={
                            isBtn.code === "reviewed"
                              ? "View your feedback"
                              : "Your test is under review"
                          }
                        >
                          <a
                            onClick={() =>
                              isBtn.code === "reviewed" &&
                              navigate(`/given_mock/${item?.Mockid}`)
                            }
                            className={styles.Join_btn}
                          >
                            {isBtn?.code === "reviewed" ? "View" : "Reviewing"}
                          </a>
                        </Tooltip>
                      </div>
                      <div></div>
                    </div>
                  );
                })}

              <div className={styles.practice_chart2}>
                <PracticeChart data={weeklyPracticeProgress} />
              </div>
            </div>
          </section>
        )}

        {["available", "upcoming", "attempted", "expired"].includes(
          menuKey
        ) && (
          <div id={styles.center}>
            {/* optimized code */}
            {Current_Dashboard_item.length === 0 ? (
              <Result
                style={{ margin: "auto" }}
                extraMargin="auto"
                status="403"
                subTitle={`Sorry,There are no ${menuKey} test.`}
                subtitleFontSize={22}
              />
            ) : (
              Current_Dashboard_item.map((ele, index) => {
                return (
                  <Card
                    className={styles.mockCard}
                    key={index}
                    hoverable
                    style={
                      ele.type === "Scholarship"
                        ? {
                            border: "1.5px solid green ",
                          }
                        : { background: "white" }
                    }
                    styles={{ body: { padding: "0px" } }}
                    size="small"
                  >
                    <Text
                      className={styles.mockheading}
                      ellipsis={{ tooltip: ele?.MockTitle }}
                    >
                      {ele?.MockTitle}
                    </Text>
                    {ele.type === "Offline OMR" && (
                      <h6
                        style={{ textAlign: "right", fontSize: "14px" }}
                        className={styles.mockheading}
                        ellipsis={{ tooltip: "OMR Based" }}
                      >
                        OMR Based
                      </h6>
                    )}

                    <div className={styles.description}>
                      <span className="d-flex align-items-center">
                        <label className={styles?.description_icon}>
                          <HistoryOutlined
                            style={{
                              color:
                                MockStatus(ele?.Mockid)?.code === "reviewed"
                                  ? "green"
                                  : MockStatus(ele?.Mockid)?.code === "expired"
                                  ? "red"
                                  : null,
                            }}
                          />
                        </label>
                        <label className={styles?.description_title}>
                          Duration
                        </label>
                        <label> {ele?.Duration} Minutes</label>
                      </span>
                      {ele?.type !== "Offline OMR" && (
                        <span className="d-flex align-items-center">
                          <label className={styles?.description_icon}>
                            <AppstoreOutlined
                              style={{
                                color:
                                  MockStatus(ele?.Mockid)?.code === "reviewed"
                                    ? "green"
                                    : MockStatus(ele?.Mockid)?.code ===
                                      "expired"
                                    ? "red"
                                    : null,
                              }}
                            />
                          </label>
                          <label className={styles?.description_title}>
                            Objective
                          </label>
                          <label>{CountQuestion(ele?.MockData, "q")}</label>
                        </span>
                      )}

                      {/* <span className="d-flex align-items-center">
                        <label className={styles?.description_icon}>
                          <CodeOutlined
                            style={{
                              color:
                                MockStatus(ele?.Mockid)?.code === "reviewed"
                                  ? "green"
                                  : MockStatus(ele?.Mockid)?.code === "expired"
                                  ? "red"
                                  : null,
                            }}
                          />
                        </label>
                        <label className={styles?.description_title}>
                          Coding
                        </label>
                        <label>{CountQuestion(ele?.MockData, "c")}</label>
                      </span> */}
                      <span className="d-flex align-items-center">
                        <label className={styles?.description_icon}>
                          <UnorderedListOutlined
                            style={{
                              color:
                                MockStatus(ele?.Mockid)?.code === "reviewed"
                                  ? "green"
                                  : MockStatus(ele?.Mockid)?.code === "expired"
                                  ? "red"
                                  : null,
                            }}
                          />
                        </label>
                        <label className={styles?.description_title}>
                          Skills
                        </label>
                        <Text
                          ellipsis={{ tooltip: Skills(ele?.MockData, "c") }}
                          className={styles.skill_label}
                        >
                          {Skills(ele?.MockData, "c")}
                        </Text>
                      </span>

                      {(MockStatus(ele?.Mockid)?.code === "available" ||
                        MockStatus(ele?.Mockid)?.code === "expired") && (
                        <span className="d-flex align-items-start">
                          <label className={styles?.description_icon}>
                            <CalendarOutlined
                              style={{
                                color:
                                  MockStatus(ele?.Mockid)?.code === "reviewed"
                                    ? "green"
                                    : MockStatus(ele?.Mockid)?.code ===
                                      "expired"
                                    ? "red"
                                    : null,
                              }}
                            />
                          </label>
                          <label
                            className={`${styles?.description_title} mt-1`}
                          >
                            End
                          </label>
                          <label className="mt-1">
                            {MockStart(ele?.Mockend)}
                          </label>
                        </span>
                      )}
                      {MockStatus(ele?.Mockid)?.code === "upcoming" && (
                        <span className="d-flex align-items-start">
                          <label className={styles?.description_icon}>
                            <CalendarOutlined
                              style={{
                                color:
                                  MockStatus(ele?.Mockid)?.code === "reviewed"
                                    ? "green"
                                    : MockStatus(ele?.Mockid)?.code ===
                                      "expired"
                                    ? "red"
                                    : null,
                              }}
                            />
                          </label>
                          <label
                            className={`${styles?.description_title} mt-1`}
                          >
                            Start
                          </label>
                          <label className="mt-1">
                            {MockStart(ele?.MockStart)}
                          </label>
                        </span>
                      )}
                    </div>
                    {MockStatus(ele?.Mockid)?.code !== "expired" && (
                      <>
                        <hr style={{ width: "100%" }} />
                        <center className="mt-2">
                          {ActionButton(ele?.Mockid, ele?.MockTitle)}
                        </center>
                      </>
                    )}
                  </Card>
                );
              })
            )}
          </div>
        )}

        {/* Practice Mode */}
        {menuKey === "practice" && (
          <div className={styles.practice_main}>
            <div className={styles.heading}>
              <Divider orientation="left">
                <h5>
                  <CodeOutlined className="mx-3" />
                  Practice Content
                </h5>
              </Divider>{" "}
            </div>
            <div className={styles.practice_submain}>
              <Timeline
                style={{
                  width: "65%",
                  padding: "5px",
                }}
                items={PracticeData.map((item, index, arr) => {
                  let totalquestion = [
                    ...item.Easy,
                    ...item.Medium,
                    ...item.Hard,
                  ];
                  let totaldone = totalquestion.filter(
                    (total) =>
                      total.answer[total.answer.length - 1]?.issubmitted ??
                      false
                  ).length;

                  return {
                    color: "blue",
                    dot: (
                      <div
                        style={{
                          backgroundColor: "#f2f5f9",
                          position: "absolute",
                          right: "-8px",
                          top: "-4px",
                        }}
                      >
                        <FaRegCircle />
                      </div>
                    ),

                    style: { fontSize: "20px", backgroundColor: "#f2f5f9" },

                    children: (
                      <Card
                        hoverable={
                          index !== 0 &&
                          ([
                            ...arr[index - 1]?.Easy,
                            ...arr[index - 1]?.Medium,
                            ...arr[index - 1]?.Hard,
                          ].filter((total) => total.answer.length > 0).length /
                            [
                              ...arr[index - 1]?.Easy,
                              ...arr[index - 1]?.Medium,
                              ...arr[index - 1]?.Hard,
                            ].length) *
                            100 <
                            50
                            ? false
                            : true
                        }
                        title={
                          <div className="d-flex justify-content-between">
                            <span>{item.maintopics}</span>{" "}
                            <Progress
                              style={{ width: "150px" }}
                              size="small"
                              percent={Math.round(
                                (totaldone / totalquestion.length) * 100
                              )}
                              strokeColor={{
                                "0%": "#ff0000",
                                "50%": "#ffe58f",
                                "100%": "#00ff00",
                              }}
                            />
                          </div>
                        }
                        style={{
                          width: "100%",
                          backgroundColor: "#fafafa",
                          cursor:
                            index !== 0 &&
                            ([
                              ...arr[index - 1]?.Easy,
                              ...arr[index - 1]?.Medium,
                              ...arr[index - 1]?.Hard,
                            ].filter((total) => total.answer.length > 0)
                              .length /
                              [
                                ...arr[index - 1]?.Easy,
                                ...arr[index - 1]?.Medium,
                                ...arr[index - 1]?.Hard,
                              ].length) *
                              100 <
                              50
                              ? "not-allowed"
                              : "cursor",
                        }}
                      >
                        <div className={styles.details_main}>
                          <section className={styles.difficulties_main}>
                            <div className={styles.each_main}>
                              <span>Easy </span>
                              <span>{item.Easy.length}</span>
                            </div>
                            <div className={styles.each_main}>
                              <span>Medium </span>
                              <span>{item.Medium.length}</span>
                            </div>
                            <div className={styles.each_main}>
                              <span>Hard </span>
                              <span>{item.Hard.length}</span>
                            </div>
                          </section>
                        </div>

                        <div className="h-100 d-flex align-items-end justify-content-between">
                          <span>
                            <InfoCircleOutlined /> You need to complete 50% of
                            the questions to proceed to the next topic.
                          </span>{" "}
                          {index !== 0 &&
                          ([
                            ...arr[index - 1]?.Easy,
                            ...arr[index - 1]?.Medium,
                            ...arr[index - 1]?.Hard,
                          ].filter((total) => total.answer.length > 0).length /
                            [
                              ...arr[index - 1]?.Easy,
                              ...arr[index - 1]?.Medium,
                              ...arr[index - 1]?.Hard,
                            ].length) *
                            100 <
                            50 ? (
                            <span className={styles.startbtn}>
                              <LockOutlined />
                            </span>
                          ) : (
                            <Button
                              className={styles.startbtn}
                              onClick={() => {
                                setPracticeDetails(item);
                                setMenuKey(0);
                                setPind(index);
                              }}
                            >
                              Start Now
                            </Button>
                          )}
                        </div>
                      </Card>
                    ),
                  };
                })}
              />
              {/* <div className={styles.right_side}>
                  <div className={styles.right_side_sub}><FaCode style={{fontSize:"20px"}}/><span className={styles.details}>{PracticeData.length} Languages</span></div>
                  <div className={styles.right_side_sub}><FaCode style={{fontSize:"20px"}}/><span className={styles.details}>3 Levels</span></div>
                  <div className={styles.right_side_sub}><FaCode style={{fontSize:"20px"}}/><span className={styles.details}>{PracticeData.length} Languages</span></div>
                </div> */}
            </div>
          </div>
        )}
        {menuKey === "practice" && PracticeData.length === 0 && !isloading && (
          <Result
            status="warning"
            title="Currently, there are no practice questions available. Thank you for your patience."
          />
        )}
        {isloading && (
          <Result
            icon={<LoadingOutlined />}
            status="info"
            title="Fetching your practice data. Please wait..."
          />
        )}
        {menuKey === 0 && practicedetails !== null && (
          <PracticeDetails
            groupid={groupid[0]?.id}
            data={practicedetails}
            index={pind}
          />
        )}
        {menuKey === "attendance" && (
          <UserAttendance username={user?.Email} gid={groupid[0]?.id} />
          // <UserAttendance username={"dk1825@digikull.com"} gid={"a784cbf3-f115-43bf-a4ed-650f9b4dfdaa"} />
        )}
        {menuKey === "testserie" && (
          <div style={{ marginTop: "70px" }}>
            <h1 style={{textAlign:"center",margin:"15px auto",fontSize:"25px"}}>Your test series</h1>
            <div className={styles.testSeries}>
              {testserie?.map((course, index) => {
                return (
                  <Card
                    onClick={() =>
                      navigate(`/testdetails?id=${course?.courseid}`)
                    }
                    key={index}
                    size="small"
                    style={{
                      width: 250,
                      margin: "5px 10px",
                    }}
                    cover={
                      <img
                        alt="course thumbnail"
                        src={course.thumbnail}
                        height={150}
                        style={{ objectFit: "cover" }}
                      />
                    }
                    hoverable
                  >
                    <Meta title={course.CourseTitle} />

                    <label
                      dangerouslySetInnerHTML={{
                        __html: course?.description.slice(0, 80),
                      }}
                    />
                  </Card>
                );
              })}
            </div>
          </div>
        )}
        <Modal
          title="Practice Progress"
          onCancel={() => setIsChartOpen(false)}
          open={IsChartOpen}
          onOk={false}
          centered
          width={"70%"}
          maskClosable={false}
          footer={null}
          closable
        >
          <PracticeChart data={weeklyPracticeProgress} />
        </Modal>
        <Modal
          title="Video Player"
          centered
          open={openPlayer}
          onCancel={() => {
            setOpenPlayer(false);
            // URL.revokeObjectURL(videourl);
          }}
          width="100%"
          // style={{ height: "80vh" }}
          footer={null}
          maskClosable={false}
        >
          <center>
            {/* {fetchingRecording && (
              <div>
                <LoadingOutlined style={{ fontSize: "80px" }} />
                <br />
                <label>Loading Recording. Please Wait....</label><br/>

                <label>For the first-time load, it may take some time. However, subsequent loads will be faster, allowing you to enjoy uninterrupted playback of the recording.</label>



              </div>
            )} */}
            <video
              style={{ width: "100%", height: "80vh" }}
              src={
                "https://interview-tool.s3.ap-south-1.amazonaws.com/4c9117bc-c6ce-482e-b941-8706d1dbd58c.mp4"
              }
              controls
            />
          </center>
        </Modal>
      </div>
    </Layout>
  );
};

export default UserHome;
