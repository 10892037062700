import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Dropdown,
  Input,
  Layout,
  Modal,
  Result,
  Select,
  message,
} from "antd";
import {
  HomeOutlined,
  ContainerOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  LoadingOutlined,
  MehOutlined,
  MoreOutlined,
  ExportOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import DashboardSlider from "../../Navigator_Footer/DashboardSlider";
import ProfileSlider from "../../Navigator_Footer/ProfileSlider";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "../AdminHome.module.css";
import styles1 from "./styles/courseHome.module.css";

import {
  getrequest,
  postrequest,
  putrequest,
} from "../../Middleware/managerequest";
import Meta from "antd/es/card/Meta";
import LatestNavbar from "../../Navigator_Footer/LatestNavbar";
const { Content, Sider } = Layout;

// Menu
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const CourseHome = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [messageApi, contentHolder] = message.useMessage();
  const data = useSelector((state) => state.Data);
  const { user, access, clientdetails } = data;
  const [openDwa, setOpenDwa] = useState(false);
  const [loading, setLoading] = useState(false);
  const [coursedata, setData] = useState([]);
  const [openmanageaccess, setOpenManageAccess] = useState(false);
  const [Access, setAccess] = useState([]);
  const [allusers, setAllUSers] = useState([]);
  const [selecteduser, setSelectedUser] = useState([]);
  const [Users, setUsers] = useState([]);
  const [SelectedUserCourse, setSelectedUserCourse] = useState([]);
  const [courseid, setCourseid] = useState();
  const [sendrequest, setRequest] = useState(false);
  const [changename, setChangename] = useState("");
  const [addUserModal, setAddUserModal] = useState(false);

  const [visibility, setVisibility] = useState(false);

  const Fetcher = useCallback(() => {
    setLoading(true);
    getrequest(`/allcourses/${user?.subdomain}`).then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, [user]);

  useEffect(() => {
    if (user) {
      Fetcher();
    }
  }, [user, Fetcher]);

  const fetchaccess = (id) => {
    setCourseid(id);
    const key = "fetch";
    messageApi.loading({
      content: "Fetching Admins",
      key,
    });
    getrequest(`/getcourse_access/${id}/${user?.subdomain}`)
      .then((res) => {
        setOpenManageAccess(true);
        messageApi.destroy(key);
        setAccess(res.data.access);
        setAllUSers(res.data.newaccess);
      })
      .catch((err) => {
        messageApi.error({
          content: err?.response?.data,
          key,
        });
      });
  };
  //Fetch All users of Particular Test
  const Getusers = (item) => {
    getrequest(`/getallusers/${user?.subdomain}`)
      .then((res) => {
        let arr = [];
        res.data?.students?.length > 0 &&
          res?.data?.students.forEach((item) => {
            let obj = {
              value: item.email,
              label: item.FullName,
              name: "users",
            };
            arr.push(obj);
            // return true;
          });
        setUsers(arr);
      })
      .catch((err) =>
        message.warning("Error while fetching the users code-754", "error")
      );
  };
  const ChangeVisibility = () => {
    putrequest(`/changevisibility/${courseid}`, {
      visible: changename === "Public" ? false : true,
    })
      .then((res) => Fetcher())
      .catch((err) => console.log(err));
    setVisibility(false);
  };
  const addUserInTestSeries = () => {
    postrequest(`/home/user/${user.subdomain}/${courseid}`, SelectedUserCourse)
      .then((res) => {
        console.log(res);
        setAddUserModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Handle Users while Inviting users
  const handleuser = (e) => {
    let find = Users.filter((val) => {
      return e.includes(val.value);
    });

    e.forEach((val) => {
      if (!Users.some((user) => user.value === val)) {
        let obj = {
          label: val.trim(),
          value: val.trim(),
          name: "users",
        };
        find.push(obj);
      }
    });
    setSelectedUserCourse(find);
  };
  const CloneCourse = (id) => {
    const key = "clone";
    message.loading({
      content: "Cloning...",
      key,
    });
    getrequest(
      `/clone_course/${id}?name=${user?.FullName}&email=${user?.Email}`
    )
      .then((res) => {
        Fetcher();
        message.destroy(key);
      })
      .catch((err) => {
        console.log(err?.response?.data);
      });
  };

  const dropdownitems = (access, id, name, visible, price) => {
    return [
      access && {
        key: 1,
        label: (
          <label onClick={() => fetchaccess(id)} style={{ cursor: "pointer" }}>
            Manage Access
          </label>
        ),
      },
      access && {
        key: 2,
        label: (
          <label
            onClick={() => navigate("/createcourse", { state: id })}
            style={{ cursor: "pointer" }}
          >
            Edit Course
          </label>
        ),
      },
      access && {
        key: 5,
        label: (
          <label
            onClick={() => {
              Getusers();
              setAddUserModal(true);
              setCourseid(id);
            }}
            style={{ cursor: "pointer" }}
          >
            Add Users
          </label>
        ),
      },

      access && {
        key: 3,
        label: (
          <label
            onClick={() => {
              setVisibility(true);
              setChangename(visible);
              setCourseid(id);
            }}
            style={{ cursor: "pointer" }}
          >
            Change Visibility{" "}
          </label>
        ),
      },
      access && {
        key: 4,
        label: (
          <label onClick={() => CloneCourse(id)} style={{ cursor: "pointer" }}>
            Clone Course
          </label>
        ),
      },
    ];
  };

  const AddAccess = () => {
    if (selecteduser.length > 0) {
      const newusers = selecteduser.map((item) => {
        const users = allusers.find((user) => user.Email === item);
        return {
          name: users.Name,
          email: users.Email,
          date: new Date(),
        };
      });
      // console.log(newusers)
      const key = "add";
      messageApi.loading({
        content: "Adding new admin.",
        key,
      });
      setRequest(true);
      postrequest(`/addaccess_course/${courseid}/${user?.subdomain}`, newusers)
        .then((res) => {
          setRequest(false);
          setAccess(res.data.access);
          setAllUSers(res.data.newaccess);
          setSelectedUser([]);
          Fetcher();
          messageApi.success({
            content: "Added successfully",
            key,
          });
        })
        .catch((err) => {
          setRequest(false);
          messageApi.error({
            content: err.response.data,
            key,
          });
        });
    } else {
      messageApi.warning({
        content: "Kindly select user",
      });
    }
  };

  const Deleteuser = (email) => {
    let confirm = window.confirm("Do you want to remove user?");
    if (confirm) {
      const key = "delete";
      messageApi.loading({
        content: "Removing accesss",
        key,
      });
      putrequest(`/deleteuser_course/${courseid}/${user?.subdomain}`, {
        email: email,
      })
        .then((res) => {
          messageApi.success({
            content: "Removed Successfully",
            key,
          });
          setAccess(res.data.access);
          setAllUSers(res.data.newaccess);
          Fetcher();
        })
        .catch((err) => {
          messageApi.error({
            content: err?.response?.data,
            key,
          });
        });
    }
  };

  return (
    <>
      {contentHolder}
      <Layout>
        <Sider
          className={styles.slider}
          breakpoint="lg"
          collapsedWidth="0"
          width={window.innerWidth < 350 ? 200 : "18%"}
          trigger={null}
        >
          <DashboardSlider />
        </Sider>
        <Layout className={styles1.left_side_course}>
          <LatestNavbar openDwa={openDwa} setOpenDwa={setOpenDwa} />

          <Content style={{ marginTop: "60px" }}>
            <div>
              {!loading && coursedata.length > 0 && (
                <div className="d-flex justify-content-between mx-5 mt-3">
                  <Input
                    onChange={(e) => setSearch(e.target.value.toLowerCase())}
                    placeholder="Search course here"
                    className={styles1.searchInput}
                  />
                  <Button
                    type="primary"
                    onClick={() => navigate("/createcourse")}
                    ghost
                  >
                    Create New
                  </Button>
                </div>
              )}

              <div className={styles1.card_main}>
                {!loading &&
                  coursedata.length > 0 &&
                  coursedata
                    .filter((val) =>
                      val.CourseTitle.toLowerCase().includes(search)
                    )
                    .map((course, ind) => {
                      return (
                        <Card
                          key={ind}
                          size="small"
                          style={{
                            width: 300,
                          }}
                          hoverable
                          actions={[
                            <Dropdown
                              placement="top"
                              arrow
                              trigger={["hover"]}
                              className={styles.menu}
                              overlayStyle={{
                                width: "180px",
                                border: "1px solid #c2c2c2",
                                borderRadius: "5px",
                              }}
                              menu={{
                                items: dropdownitems(
                                  course.access1,
                                  course.courseid,
                                  course?.CourseTitle,
                                  course?.visibility,
                                  course?.pricing?.price
                                ),
                              }}
                            >
                              <MoreOutlined />
                            </Dropdown>,
                            <EditOutlined
                              title="Edit the Course"
                              style={{
                                cursor: !course.access1
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                              onClick={() => {
                                if (course?.access1) {
                                  navigate(
                                    `/createcurriculum?id=${course.courseid}&mode=edit`
                                  );
                                } else {
                                  messageApi.warning({
                                    content:
                                      "You don't have permission to edit the course content.",
                                  });
                                }
                              }}
                            />,
                            <ExportOutlined
                              onClick={() =>
                                navigate(`/viewcourse?id=${course.courseid}`)
                              }
                              title="Click to View"
                            />,
                          ]}
                        >
                          <Meta title={course.CourseTitle} />
                          <br />

                          <label>
                            <label style={{ width: "100px" }}>Created by</label>
                            {course.createdby.name}
                          </label>
                          <br />

                          <label>
                            <label style={{ width: "100px" }}>Price</label>
                            {course?.pricing?.free
                              ? "Free"
                              : course?.pricing?.price}
                          </label>
                        </Card>
                      );
                    })}
              </div>
            </div>

            {loading && (
              <center>
                <LoadingOutlined
                  style={{ fontSize: "50px", marginTop: "150px" }}
                />
              </center>
            )}
            {!loading && coursedata.length === 0 ? (
              <div>
                <Result
                  icon={<MehOutlined />}
                  title="Oops! You don't have any course!"
                  extra={
                    <Button
                      type="primary"
                      onClick={() => navigate("/createcourse")}
                    >
                      Create New
                    </Button>
                  }
                />
              </div>
            ) : (
              ""
            )}
          </Content>
        </Layout>
      </Layout>
      <ProfileSlider setOpenDwa={setOpenDwa} openDwa={openDwa} />

      {/* Manage Access */}
      <Modal
        okText="Add"
        open={openmanageaccess}
        onCancel={() => {
          setSelectedUser([]);
          setAccess([]);
          setAllUSers([]);
          setOpenManageAccess(false);
        }}
        title="Manage Access"
        onOk={() => {
          AddAccess();
        }}
        okButtonProps={{
          loading: sendrequest,
          disabled: sendrequest,
        }}
        maskClosable={false}
      >
        <div>
          <Select
            options={allusers.map((user) => {
              return {
                label: user.Name,
                value: user.Email,
              };
            })}
            style={{ width: "250px" }}
            mode="multiple"
            onChange={(val) => setSelectedUser(val)}
            maxTagCount={"responsive"}
            value={selecteduser}
          />
        </div>
        <div className="mt-2">
          <label style={{ fontWeight: "500" }}>Admins</label>
          {Access.map((user, ind) => {
            return (
              <div className="d-flex mt-2 justify-content-between align-items-center">
                <label>{user.name}</label>
                <DeleteOutlined
                  onClick={() => Deleteuser(user.email)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            );
          })}
        </div>
      </Modal>

      {/* Change Visiblility */}
      <Modal
        onOk={() => ChangeVisibility()}
        onCancel={() => setVisibility(false)}
        maskClosable={false}
        open={visibility}
        title="Change Visibility"
        cancelText="No"
        okText="Yes"
      >
        Are you want to make it {changename === undefined && "Public"}{" "}
        {changename !== undefined &&
          (changename === "Public" ? "Private" : "Public")}
      </Modal>
      <Modal
        onOk={() => addUserInTestSeries()}
        onCancel={() => setAddUserModal(false)}
        maskClosable={false}
        open={addUserModal}
        title="Add Users"
        cancelText="No"
        okText="Yes"
      >
        {console.log(Users)}
        <Select
          mode="tags"
          style={{
            width: "100%",
            zIndex: "999",
          }}
          onChange={handleuser}
          tokenSeparators={[","]}
          options={Users}
          placeholder={"Enter the email with ',' separated"}
          value={SelectedUserCourse}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        />
      </Modal>
    </>
  );
};

export default CourseHome;
