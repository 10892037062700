import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Layout,
  Collapse,
  message,
  Select,
  Popconfirm,
  Switch,
} from "antd";
import { v4 as uuidv4 } from "uuid";

import {
  CloseOutlined,
  EditOutlined,
  HolderOutlined,
  DeleteOutlined,
  CaretRightFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import DashboardSlider from "../../Navigator_Footer/DashboardSlider";
import ProfileSlider from "../../Navigator_Footer/ProfileSlider";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "../AdminHome.module.css";
import styles1 from "./styles/createcurriculum.module.css";

import {
  getrequest,
  postrequest,
  putrequest,
} from "../../Middleware/managerequest";
import LatestNavbar from "../../Navigator_Footer/LatestNavbar";

const { Content, Sider } = Layout;

const CreateCurriculum = () => {
  const [messageApi, contentHolder] = message.useMessage();
  const [activekey, setActiveKey] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [categories, setCategories] = useState([]);
  const [searchParams] = useSearchParams();
  const data = useSelector((state) => state.Data);
  const { user } = data;
  const [openDwa, setOpenDwa] = useState(false);
  const [ind, setInd] = useState(-1);
  const [ind_section, setInd_Section] = useState(-1);
  const [CurriculumData, setCurriculum] = useState([]);
  const [CourseTitle, setCourseTitle] = useState("");
  const [Tests, setTests] = useState([]);
  const [selectedcat, setSelectedCat] = useState("");
  const [edit_content, setEdit_content] = useState({
    section_id: 0,
    content_id: 0,
  });
  const [edit_content_heading, setEdit_content_heading] = useState({
    section_id: 0,
    content_id: 0,
  });

  const [content_id, setCOntent_id] = useState(0);
  const [contentData, setContentData] = useState();
  const [content_heading, setContent_Heading] = useState("");
  const [loading, setLoading] = useState(true);
  const [sended, setRequestsended] = useState(false);

  // Fetch Content of course
  const Fetcher = useCallback(
    (type, key) => {
      getrequest(`/get_curriculum/${searchParams.get("id")}`)
        .then((res) => {
          setLoading(false);
          setRequestsended(false);
          if (type) {
            messageApi.success({
              content: "Saved Successfully",
              key,
              duration: 2,
            });
          }
          setCourseTitle(res.data.CourseTitle);
          setCurriculum(res.data.sections);
        })
        .catch((err) => {
          setLoading(false);
        });
    },
    [searchParams, messageApi]
  );

  useEffect(() => {
    Fetcher();
  }, [Fetcher]);

  const handleEditorChange = (html) => {
    setContentData((prev) => ({ ...prev, Description: html.target.value }));
  };

  // Manage Section

  const AddSection = (ind) => {
    if (ind >= 0) {
      let duplicate = [...CurriculumData];
      duplicate.splice(ind + 1, 0, {
        sectionName: `Section ${CurriculumData.length + 1}`,
        section_id: uuidv4(),
      });
      setCurriculum(duplicate);
    } else {
      setCurriculum((prev) => [
        ...prev,
        {
          sectionName: `Section ${CurriculumData.length + 1}`,
          section_id: uuidv4(),
        },
      ]);
    }
  };

  // Manage Drag and Drop
  const handleDragStart = (e, id) => {
    e.dataTransfer.setData("id", id);
  };

  const handleDragContentStart = (e, id, contentid) => {
    e.dataTransfer.setData("sectionid", id);
    e.dataTransfer.setData("content", contentid);
  };
  const handleDropContent = (e, index) => {
    const taskId = e.dataTransfer.getData("sectionid");
    const contentId = e.dataTransfer.getData("content");

    const newTasks = [...CurriculumData];
    const task = newTasks.find((task) => task.section_id === taskId);

    const content = task.content.find(
      (content) => content.content_id === contentId
    );

    task.content.splice(task.content.indexOf(content), 1);
    task.content.splice(index, 0, content);
    setCurriculum(newTasks);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, index) => {
    const taskId = e.dataTransfer.getData("id");

    const newTasks = [...CurriculumData];
    const task = newTasks.find((task) => task.section_id === taskId);
    newTasks.splice(newTasks.indexOf(task), 1);
    newTasks.splice(index, 0, task);
    setCurriculum(newTasks);
  };
  const convertDateIntoNormalend = (val) => {
    // Create a Date object from the input string
    const date = new Date(val);

    // Extract the day, month, and year components
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    // Extract the hours and minutes components
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Construct the formatted date string
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
    return formattedDate;
  };
  const convertDateIntoNormal2 = () => {
    // Create a Date object from the current date and time
    const date = new Date();

    // Extract the day, month, and year components
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    // Extract the hours and minutes components
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Construct the formatted date string
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
    return formattedDate;
  };

  // Content Handle
  const AddContent = (sectionid, type) => {
    const content_id = uuidv4();
    const duplicate = CurriculumData.map((item) => {
      if (item.section_id === sectionid) {
        let newContent = {};

        switch (type) {
          case "lession":
            const contentLength =
              item.content &&
              item.content.filter((val) => val.type === "lession")
                ? item.content.filter((val) => val.type === "lession").length
                : 0;
            newContent = {
              type: "lession",
              content_id,
              content_Name: `Lesson ${contentLength}`,
              Description: "No Description Available",
              islive_session: false,
              attachtype: "video",
            };
            break;
          case "test":
            const contentLength2 =
              item.content && item.content.filter((val) => val.type === "test")
                ? item.content.filter((val) => val.type === "test").length
                : 0;
            newContent = {
              type: "test",
              content_id,
              content_Name: `Test ${contentLength2}`,
              category: "Please Select Category",
              Test_Details: {},
            };
            break;
          case "practice":
            const contentLength3 =
              item.content &&
              item.content.filter((val) => val.type === "practice")
                ? item.content.filter((val) => val.type === "practice").length
                : 0;
            newContent = {
              type: "practice",
              content_id,
              content_Name: `Practice ${contentLength3}`,
              Test_Details: {},
            };
            break;
          default:
            break;
        }

        return {
          ...item,
          content: [...(item.content || []), newContent],
        };
      }
      return item;
    });

    setCurriculum(duplicate);
    setEdit_content_heading({ section_id: sectionid, content_id: content_id });
    setActiveKey([]);
  };

  const Save = (section_id, content_id) => {
    const updatedCurriculumData = CurriculumData.map((section) => {
      if (section.section_id === section_id) {
        return {
          ...section,
          content: section.content.map((content) => {
            if (content.content_id === content_id) {
              return {
                ...content,
                ...contentData,
              };
            }
            return content;
          }),
        };
      }
      return section;
    });

    setEdit_content({ section_id: -1, content_id: -1 });
    setContentData("");
    setCurriculum(updatedCurriculumData);
    setTests([]);
  };

  // Save Content Heading
  const savecontentHeading = (sectionid, contentid) => {
    if (content_heading === "") {
      messageApi.warning({
        content: "Please enter the right title.",
      });
      return;
    }
    let duplicate = [...CurriculumData];
    duplicate.forEach((section) => {
      if (section.section_id === sectionid) {
        section.content.map((content) => {
          if (content.content_id === contentid) {
            content.content_Name = content_heading;

            return content;
          } else {
            return content;
          }
        });
      }
    });

    setEdit_content_heading({ section_id: 0, content_id: 0 });
    setContent_Heading("");
    setCurriculum(duplicate);
  };

  // Delete the content
  const Delete_content = (e, content_id, section_id) => {
    e.stopPropagation();
    let duplicate = [...CurriculumData];

    duplicate.forEach((section) => {
      if (section.section_id === section_id) {
        section.content = section.content.filter(
          (content) => content.content_id !== content_id
        );
      }
    });

    setCurriculum(duplicate);
  };

  // Render Live and Pre Recorded Sessions
  const renderLecture = (content, section_id) => {
    const isEditing =
      content.content_id === edit_content.content_id &&
      edit_content.section_id === section_id;

    const handleSave = () => {
      Save(section_id, content.content_id);
      setEdit_content({ section_id: 0, content_id: 0 });
    };
    const handleCancel = () => {
      setEdit_content({ section_id: 0, content_id: 0 });
    };

    return (
      <div>
        <div className={styles1.first_row_content}>
          <div className={styles1.content_rows}>
            <label className={styles1.content_heading}>Description</label>
            {isEditing ? (
              <textarea
                value={contentData?.Description ?? content.Description}
                onChange={handleEditorChange}
                className={styles1.decription_input}
                placeholder="Describe about the Lesson."
              />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: content.Description }} />
            )}
          </div>
          {!isEditing && (
            <>
              <div className={styles1.content_rows}>
                <label className={styles1.content_heading}>Live Session</label>{" "}
                {content?.islive_session ? "Yes" : "NO"}
              </div>
              {!content?.islive_session && (
                <>
                  {content?.attachtype === "link" && (
                    <div className="mt-3">
                      {" "}
                      <label className={styles1.content_heading}>
                        External Link
                      </label>
                      <label>{content?.external_link?.name}</label>
                      <a href={content?.external_link?.link} className="mx-2">
                        Click Here
                      </a>
                    </div>
                  )}

                  {(content?.attachtype === "video" ||
                    content?.attachtype === "pdf") && (
                    <div className={styles1.content_rows}>
                      <label className={styles1.content_heading}>
                        Attachments
                      </label>
                      <br />
                      {(contentData?.attachments ?? content?.attachments) &&
                        Object.keys(
                          contentData?.attachments ?? content?.attachments
                        )?.length > 0 &&
                        (content?.attachtype === "video" ? (
                          <video className="mt-2" controls width={300}>
                            <source
                              src={
                                typeof content?.attachments === "object"
                                  ? URL.createObjectURL(
                                      Object.values(
                                        contentData?.attachments ??
                                          content?.attachments
                                      )[0]
                                    )
                                  : content?.attachments
                              }
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <a
                            href={
                              typeof content?.attachments === "object"
                                ? URL.createObjectURL(
                                    Object.values(
                                      contentData?.attachments ??
                                        content?.attachments
                                    )[0]
                                  )
                                : content?.attachments
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            View PDF
                          </a>
                        ))}
                    </div>
                  )}
                </>
              )}
            </>
          )}

          {isEditing && (
            <>
              <div className={styles1.content_rows}>
                <label className={styles1.content_heading}>Live Session</label>
                <Switch
                  size="small"
                  value={contentData?.islive_session ?? content?.islive_session}
                  onChange={(e) =>
                    setContentData((val) => ({
                      ...val,
                      islive_session: e,
                      attachments: e ? val?.attachments : {},
                    }))
                  }
                  className="mx-3"
                />
              </div>
              {(!contentData?.islive_session ?? content?.islive_session) && (
                <>
                  <div className="mt-3">
                    <span className="d-flex align-items-center mx-2">
                      <input
                        onChange={(e) =>
                          setContentData((val) => ({
                            ...val,
                            attachtype: "video",
                            external_link: {},
                          }))
                        }
                        name="attach"
                        type="radio"
                        id="r1"
                        style={{ cursor: "pointer" }}
                        // defaultChecked={contentData?.attachtype==="video"}
                        checked={contentData?.attachtype === "video"}
                      />
                      <label
                        htmlFor="r1"
                        style={{ cursor: "pointer" }}
                        className="mx-1"
                      >
                        Video
                      </label>
                    </span>
                    <span className="d-flex align-items-center  mx-2">
                      <input
                        name="attach"
                        type="radio"
                        onChange={(e) =>
                          setContentData((val) => ({
                            ...val,
                            attachtype: "link",
                            attachments: {},
                          }))
                        }
                        id="r2"
                        style={{ cursor: "pointer" }}
                        checked={contentData?.attachtype === "link"}
                      />
                      <label
                        htmlFor="r2"
                        style={{ cursor: "pointer" }}
                        className="mx-1"
                      >
                        External Link
                      </label>
                    </span>
                    <span className="d-flex align-items-center  mx-2">
                      <input
                        name="attach"
                        type="radio"
                        onChange={(e) =>
                          setContentData((val) => ({
                            ...val,
                            attachtype: "pdf",
                            attachments: {},
                          }))
                        }
                        id="r3"
                        style={{ cursor: "pointer" }}
                        checked={contentData?.attachtype === "pdf"}
                      />
                      <label
                        htmlFor="r3"
                        style={{ cursor: "pointer" }}
                        className="mx-1"
                      >
                        Attach PDF
                      </label>
                    </span>
                  </div>

                  {(contentData?.attachtype === "video" ||
                    contentData?.attachtype === "pdf") && (
                    <div className="mt-2 d-flex flex-column justify-content-end align-items-center mt-3">
                      <input
                        type="file"
                        accept={
                          contentData?.attachtype === "pdf" ? ".pdf" : ".mp4"
                        }
                        onChange={(e) => {
                          setContentData((prev) => ({
                            ...prev,
                            attachments: e.target.files,
                          }));
                        }}
                        style={{ display: "none" }}
                        id="files"
                      />
                      <label htmlFor="files" className={styles1.uploadbtn}>
                        + Upload
                      </label>
                      {(contentData?.attachments ?? content?.attachments) &&
                        Object.keys(
                          contentData?.attachments ?? content?.attachments
                        )?.length > 0 &&
                        (contentData?.attachtype === "video" ? (
                          <video className="mt-2" controls width={300}>
                            <source
                              src={
                                typeof contentData?.attachments === "object"
                                  ? URL.createObjectURL(
                                      Object.values(
                                        contentData?.attachments ??
                                          content?.attachments
                                      )[0]
                                    )
                                  : contentData?.attachments
                              }
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <a
                            href={
                              typeof contentData?.attachments === "object"
                                ? URL.createObjectURL(
                                    Object.values(
                                      contentData?.attachments ??
                                        content?.attachments
                                    )[0]
                                  )
                                : contentData?.attachments
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            View
                          </a>
                        ))}
                    </div>
                  )}
                  {contentData?.attachtype === "link" && (
                    <div>
                      <div className="mt-2">
                        <label style={{ width: "100px" }}>Name</label>
                        <input
                          onChange={(e) =>
                            setContentData((prev) => ({
                              ...prev,
                              external_link: {
                                ...prev.external_link,
                                name: e.target.value,
                              },
                            }))
                          }
                          type="text"
                          value={contentData?.external_link?.name}
                        />
                      </div>
                      <div className="mt-2 mb-2">
                        <label style={{ width: "100px" }}>Link</label>
                        <input
                          onChange={(e) =>
                            setContentData((prev) => ({
                              ...prev,
                              external_link: {
                                ...prev.external_link,
                                link: e.target.value,
                              },
                            }))
                          }
                          type="text"
                          value={contentData?.external_link?.link}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>

        {isEditing && (
          <div className="d-flex justify-content-end align-items-end mx-4">
            <label
              className="mx-3"
              onClick={handleCancel}
              style={{ cursor: "pointer" }}
            >
              Cancel
            </label>
            <button className={styles1.save_btn} onClick={handleSave}>
              Save
            </button>
          </div>
        )}
      </div>
    );
  };

  // Fetch Category
  const fetchCategory = useCallback(() => {
    getrequest(`/predefinedtest/${user?.subdomain}`)
      .then((res) => {
        if (res.data.Category) {
          setCategories(res.data.Category);
        }
      })
      .catch((err) => {
        messageApi.error({
          content: err.message,
        });
      });
  }, [user, messageApi]);

  useEffect(() => {
    if (user) {
      fetchCategory();
    }
  }, [fetchCategory, user]);

  // Fetch All Test
  const FetchTests = (type, value) => {
    setTests([]);
    setFetching(true);
    setSelectedCat(value);

    getrequest(`/getchallcategory/${user?.subdomain}/${value}`)
      .then((res) => {
        if (res.data.alldata) {
          const test =
            type === "test"
              ? res.data.alldata.filter((item) => {
                  if (!item?.Settings?.practice_mode ?? false) {
                    return true;
                  } else {
                    return false;
                  }
                })
              : res.data.alldata.filter((item) => {
                  if (item?.Settings?.practice_mode ?? false) {
                    return true;
                  } else {
                    return false;
                  }
                });

          setTests(test);
          setFetching(false);
        }
      })
      .catch((err) => setFetching(false));
  };

  const Skills = (val) => {
    return val.map((item, ind) => {
      return (
        <span key={ind}>
          {item.MainTags}
          {val.length - 1 !== ind && ","}
        </span>
      );
    });
  };

  const ConvertToHours = (val) => {
    if (val === 0) {
      return 0;
    } else if (val <= 60) {
      return val + " min(s)";
    } else {
      const hours = Math.floor(val / 60);
      const remainingMinutes = val % 60;
      if (hours > 0 || remainingMinutes > 0) {
        return `${hours} hr ${remainingMinutes} min(s)`;
      } else {
        return 0;
      }
    }
  };

  // Render Test and Practice Test
  const RenderTest = (content, section_id) => {
    const isEditing =
      content.content_id === edit_content.content_id &&
      edit_content.section_id === section_id;

    const handleSave = () => {
      Save(section_id, content.content_id);
      setSelectedCat("");
      setEdit_content({ section_id: 0, content_id: 0 });
    };
    return (
      <div>
        {!isEditing && (
          <div>
            <div className={styles1.test_content}>
              <span className={styles1.test_row}>
                <label style={{ width: "150px" }}>Topic</label>{" "}
                {content?.Test_Details?.type ?? "Please Select Test"}
              </span>
              <span className={styles1.test_row}>
                <label style={{ width: "150px" }}>Test Name</label>{" "}
                {content?.Test_Details?.Test_Name ?? "Please Select Test"}
              </span>
            </div>
            <div className={styles1.test_content}>
              <span className={styles1.test_row}>
                <label style={{ width: "150px" }}>Duration</label>{" "}
                {ConvertToHours(content?.Test_Details?.Duration)}
              </span>
              <span className={styles1.test_row}>
                <label style={{ width: "150px" }}>No of Questions</label>{" "}
                {content?.Test_Details?.NoofQ}
              </span>
            </div>
            <div className={styles1.test_content}>
              <span className={styles1.test_row}>
                <label style={{ width: "150px" }}>Skills</label>{" "}
                {Skills(content?.Test_Details?.MockData ?? [])}
              </span>
            </div>
            <div className={styles1.test_content}>
              <span className={styles1.test_row}>
                <label style={{ width: "150px" }}>Start Date</label>{" "}
                {convertDateIntoNormalend(content?.Test_Details?.MockStart)}
              </span>
            </div>
            <div className={styles1.test_content}>
              <span className={styles1.test_row}>
                <label style={{ width: "150px" }}>End Date</label>{" "}
                {convertDateIntoNormalend(content?.Test_Details?.Mockend)}
              </span>
            </div>
          </div>
        )}

        {/* Add New Test */}

        {isEditing && (
          <>
            <div className={styles1.testSelection}>
              <main>
                <span>
                  <label className="mx-1">Select Segment : </label>{" "}
                  <Select
                    size="small"
                    style={{ width: 200 }}
                    options={categories.slice(1).map((cat) => {
                      return {
                        label: cat,
                        value: cat,
                      };
                    })}
                    onChange={(val) => FetchTests(content.type, val)}
                  />
                </span>
                {fetching && <LoadingOutlined className="mx-2" />}

                {!selectedcat && (
                  <label className="mx-2">Select the Topic</label>
                )}
                {!fetching && selectedcat && Tests.length === 0 && (
                  <label className="mx-2">No Tests Available</label>
                )}

                {Tests.length > 0 && (
                  <span>
                    <label className="mx-1">Select Test : </label>
                    <Select
                      size="small"
                      style={{ width: 200 }}
                      options={Tests.map((cat) => {
                        return {
                          label: cat.Test_Name,
                          value: cat.Testid,
                        };
                      })}
                      onChange={(val) =>
                        setContentData({
                          Test_Details: Tests.find(
                            (test) => test.Testid === val
                          ),
                        })
                      }
                    />
                  </span>
                )}

                {Tests.length > 0 && (
                  <span>
                    <label className="mx-1">Start Date: </label>
                    <input
                      type="datetime-local"
                      value={
                        Tests.length &&
                        convertDateIntoNormalend(
                          contentData?.Test_Details?.MockStart
                        )
                      }
                      className={styles.first_row_input}
                      min={convertDateIntoNormal2()}
                      onChange={(e) =>
                        setContentData((val) => ({
                          ...val,
                          Test_Details: {
                            ...val.Test_Details, // preserve existing `Test_Details` values
                            MockStart: new Date(e.target.value), // update the `MockStart` key
                          },
                        }))
                      }
                      disabled={Tests.length === 0}
                      style={{
                        backgroundColor: Tests.length === 0 ? "#dee3e0" : null,
                      }}
                    />
                  </span>
                )}
                {Tests.length > 0 && (
                  <span>
                    <label className="mx-1">End Date: </label>
                    <input
                      type="datetime-local"
                      value={
                        Tests.length &&
                        convertDateIntoNormalend(
                          contentData?.Test_Details?.Mockend
                        )
                      }
                      className={styles.first_row_input}
                      min={convertDateIntoNormal2()}
                      onChange={(e) =>
                        setContentData((val) => ({
                          ...val,
                          Test_Details: {
                            ...val.Test_Details, // preserve existing `Test_Details` values
                            Mockend: new Date(e.target.value), // update the `MockStart` key
                          },
                        }))
                      }
                      disabled={Tests.length === 0}
                      style={{
                        backgroundColor: Tests.length === 0 ? "#dee3e0" : null,
                      }}
                    />
                  </span>
                )}
              </main>
              {Object.keys(contentData?.Test_Details).length > 0 && (
                <button
                  className={styles1.save_btn}
                  onClick={() =>
                    contentData?.Test_Details?.MockStart &&
                    contentData?.Test_Details?.Mockend
                      ? handleSave()
                      : message.warning("Please select the start and end date")
                  }
                >
                  Save
                </button>
              )}
            </div>
            <br />
            {contentData?.test_id && (
              <div>
                <div className={styles1.test_content}>
                  <span className={styles1.test_row}>
                    <label style={{ width: "150px" }}>Duration</label>{" "}
                    {ConvertToHours(
                      Tests.find((test) => test.Testid === contentData?.test_id)
                        ?.Duration
                    )}
                  </span>
                  <span className={styles1.test_row}>
                    <label style={{ width: "150px" }}>No of Questions</label>{" "}
                    {
                      Tests.find((test) => test.Testid === contentData?.test_id)
                        ?.NoofQ
                    }
                  </span>
                </div>
                <div className={styles1.test_content}>
                  <span className={styles1.test_row}>
                    <label style={{ width: "150px" }}>Skills</label>{" "}
                    {Skills(
                      Tests.find((test) => test.Testid === contentData?.test_id)
                        ?.MockData ?? []
                    )}
                  </span>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const Edit_Content_SubContent = (content_id, section_id, item) => {
    setEdit_content({
      content_id: content_id,
      section_id: section_id,
    });
    setActiveKey([content_id]);

    setContentData(item);
  };

  const Extra = (isHovered, item, section_id) => {
    let contentNotAdded = true;

    if (
      item.type === "lession" &&
      item.Description !== "No Description Available"
    ) {
      contentNotAdded = false;
    } else if (
      (item.type === "test" || item.type === "practice") &&
      Object.keys(item?.Test_Details).length !== 0
    ) {
      contentNotAdded = false;
    }

    return (
      <div
        onMouseOver={() => setCOntent_id(item.content_id)}
        onMouseOut={() => setCOntent_id(0)}
        className="d-flex"
      >
        {isHovered && (
          <div>
            {" "}
            {!contentNotAdded && (
              <EditOutlined
                onClick={(e) => {
                  e.stopPropagation();
                  Edit_Content_SubContent(item.content_id, section_id, item);
                }}
              />
            )}
            <DeleteOutlined
              className="mx-3"
              onClick={(e) => Delete_content(e, item.content_id, section_id)}
            />
          </div>
        )}
        {contentNotAdded && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              Edit_Content_SubContent(item.content_id, section_id, item);
            }}
            className={styles1.add_content}
          >
            + Content
          </div>
        )}
      </div>
    );
  };

  const content_items = (content, section_id) => {
    return content.map((item, ind) => {
      const isEditingTitle =
        edit_content_heading.content_id === item.content_id &&
        edit_content_heading.section_id === section_id;
      const isHovered = content_id === item.content_id;
      return {
        key: item.content_id,
        label: (
          <div
            className="d-flex"
            onMouseOver={() => setCOntent_id(item.content_id)}
            onMouseOut={() => setCOntent_id(0)}
          >
            {/* Content Type */}
            <div style={{ width: "120px" }}>
              <label className={styles1.predefined_title}>
                {item.type === "lession" && <>Lesson : </>}

                {item.type === "test" && <>Test : </>}

                {item.type === "practice" && <>Assignment : </>}
              </label>
            </div>

            {/* Content Name */}
            {isEditingTitle ? (
              <span>
                {/* Input for changing Content Heading */}
                <input
                  value={content_heading}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    setContent_Heading(e.target.value);
                    e.stopPropagation();
                  }}
                  className={styles1.content_heading_name}
                />
                <span className={styles1.header_btn}>
                  {/* Cancel Button for cancel heading change */}
                  <label
                    className="mx-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      setEdit_content_heading({ section_id: 0, content_id: 0 });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Cancel
                  </label>{" "}
                  {/* Save button for save content heading */}
                  <button
                    className={styles1.save_btn}
                    onClick={(e) => {
                      e.stopPropagation();
                      savecontentHeading(section_id, item.content_id);
                    }}
                  >
                    Save
                  </button>
                </span>
              </span>
            ) : (
              <div
                className={styles1.content_main_heading}
                draggable
                onDragStart={(e) =>
                  handleDragContentStart(e, section_id, item.content_id)
                }
                onDragOver={(e) => handleDragOver(e)}
                onDrop={(e) => handleDropContent(e, ind)}
              >
                <div className={styles1.content_edit_main}>
                  {/* Content Title */}
                  <label className={styles1.content_Title}>
                    {item.content_Name}{" "}
                  </label>
                  {isHovered && (
                    <EditOutlined
                      onClick={(e) => {
                        e.stopPropagation();
                        setContent_Heading(item.content_Name);
                        setEdit_content_heading({
                          section_id: section_id,
                          content_id: item.content_id,
                        });
                      }}
                    />
                  )}
                </div>
                <span>{isHovered && <HolderOutlined rotate={90} />}</span>
              </div>
            )}
          </div>
        ),
        children:
          item.type === "lession"
            ? renderLecture(item, section_id)
            : RenderTest(item, section_id),
        extra: Extra(isHovered, item, section_id),
      };
    });
  };

  const Save_Changes = () => {
    const HandleWarning = (val) => {
      messageApi.warning({
        content: val,
      });
    };

    for (const section of CurriculumData) {
      if (!section.content || section.content.length === 0) {
        HandleWarning(`Kindly add content to ${section?.sectionName}`);
        return;
      }

      for (const subcontent of section.content) {
        if (
          (subcontent.type === "test" || subcontent.type === "practice") &&
          Object.keys(subcontent.Test_Details).length === 0
        ) {
          HandleWarning(
            `Kindly Select Test in ${subcontent.content_Name} of ${section?.sectionName} `
          );
          return;
        }

        if (
          subcontent.type === "lession" &&
          subcontent.Description === "No Description Available"
        ) {
          HandleWarning(
            `Kindly add description in ${subcontent.content_Name} of ${section?.sectionName} `
          );
          return;
        }

        if (subcontent.type === "lession" && !subcontent.islive_session) {
          if (
            subcontent.attachtype === "link" &&
            (!subcontent.external_link ||
              Object.keys(subcontent.external_link).length === 0)
          ) {
            HandleWarning(
              `Kindly add external link in ${subcontent.content_Name} of ${section?.sectionName}`
            );
            return;
          } else if (
            subcontent.attachtype === "video" &&
            (subcontent.attachments === undefined ||
              Object.keys(subcontent.attachments).length === 0)
          ) {
            HandleWarning(
              `Kindly add video in ${subcontent.content_Name} of ${section?.sectionName}`
            );
            return;
          }
        }
      }
    }
    let newform = new FormData();
    CurriculumData.forEach((section) => {
      section.content.forEach((content) => {
        if (
          content.type === "lession" &&
          !content.islive_session &&
          (content?.attachtype === "video" || content?.attachtype === "pdf") &&
          typeof content.attachments === "object"
        ) {
          const file = Object.values(content.attachments)[0].name.split(".");

          newform.append(
            "attachments",
            Object.values(content.attachments)[0],
            `${section.section_id}_${content.content_id}.${
              file[file.length - 1]
            }`
          );
        }
      });
    });

    newform.append("curriculum", JSON.stringify(CurriculumData));

    const key = "course";
    messageApi.loading({
      content: "Saving Changes",
      key,
    });
    setRequestsended(true);
    putrequest(`/home/save_curriculum/${searchParams.get("id")}`, newform)
      .then((res) => {
        Fetcher("fetch", key);
        setEdit_content_heading({ section_id: 0, content_id: 0 });
        setContent_Heading("");
        Fetcher();
      })
      .catch((err) => {
        messageApi.error({
          content: err?.response.data,
          key,
        });
      });
  };
  const handlerPublish = (courseid) => {
    messageApi.loading({
      content: "Saving Changes",
      key: courseid,
    });
    postrequest(`/home/instance/${courseid}`)
      .then((res) => {
        if (res.data.success) {
          messageApi.success({
            content: res.data.message,
            key: courseid,
          });
        } else {
          messageApi.warning({
            content: res.data.message,
            key: courseid,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      {/* {console.log(CurriculumData)} */}
      {contentHolder}
      <Layout>
        <Sider
          className={styles.slider}
          breakpoint="lg"
          collapsedWidth="0"
          width={window.innerWidth < 350 ? 200 : "18%"}
          trigger={null}
        >
          <DashboardSlider />
        </Sider>
        <Layout style={{ width: "100%" }}>
          <LatestNavbar openDwa={openDwa} setOpenDwa={setOpenDwa} />
          <Content
            style={{
              overflow: "auto",
              height: "77vh",
              marginBottom: "100px",
              marginTop: "65px",
            }}
          >
            {loading && (
              <center className="mt-5">
                <LoadingOutlined style={{ fontSize: "50px" }} />
              </center>
            )}
            {!loading && (
              <div className={styles1.left_curriculum}>
                <div>
                  <label className={styles1.course_title}>{CourseTitle}</label>
                </div>
                <label className={styles1.curriculum_heading}>Curriculum</label>
                <hr style={{ width: "95%", margin: "15px auto" }} />
                {CurriculumData.map((section, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div onMouseLeave={() => setInd_Section(-1)}>
                        <section
                          key={index}
                          className={styles1.section_main}
                          draggable
                          onDragStart={(e) =>
                            handleDragStart(e, section.section_id)
                          }
                          onDragOver={(e) => handleDragOver(e)}
                          onDrop={(e) => handleDrop(e, index)}
                          onMouseOver={() => setInd_Section(section.section_id)}
                        >
                          {/* Section Header */}
                          <div className={styles1.section_header}>
                            <span className={styles1.sectionName}>
                              {ind === index ? (
                                <input
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      setInd(-1);
                                    }
                                  }}
                                  onChange={(e) => {
                                    const duplicate = [...CurriculumData];
                                    duplicate[ind].sectionName = e.target.value;
                                    setCurriculum(duplicate);
                                  }}
                                  value={section?.sectionName ?? ""}
                                />
                              ) : (
                                section?.sectionName
                              )}{" "}
                              {ind_section === section.section_id && (
                                <EditOutlined onClick={() => setInd(index)} />
                              )}
                            </span>

                            {ind_section === section.section_id && (
                              <>
                                {" "}
                                <HolderOutlined rotate={90} />
                                <CloseOutlined
                                  onClick={() => {
                                    const duplicate = [...CurriculumData];
                                    duplicate.splice(index, 1);
                                    setCurriculum(duplicate);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              </>
                            )}
                          </div>

                          {/* Content */}
                          {section.content && section.content.length > 0 && (
                            <section>
                              <Collapse
                                activeKey={activekey}
                                onChange={(key) => setActiveKey(key[0])}
                                expandIcon={({ isActive }) => (
                                  <CaretRightFilled
                                    rotate={isActive ? 90 : 0}
                                  />
                                )}
                                className={styles1.content_main}
                                expandIconPosition={"end"}
                                items={content_items(
                                  section.content,
                                  section.section_id
                                )}
                                accordion
                              />
                            </section>
                          )}

                          {/* Add Content */}

                          <div className={styles1.content_add_buttons}>
                            {/* For Adding Lecture */}
                            {/* <Button
                              className="mx-2"
                              type="dashed"
                              size="small"
                              onClick={() =>
                                AddContent(section.section_id, "lession")
                              }
                            >
                              + Lesson
                            </Button> */}

                            {/* For adding Test */}
                            <Button
                              className="mx-2"
                              type="dashed"
                              size="small"
                              onClick={() => {
                                AddContent(section.section_id, "test");
                                setActiveKey([index]);
                              }}
                            >
                              + Test
                            </Button>
                            {/* For Adding practice */}
                            {/* <Button
                              className="mx-2"
                              type="dashed"
                              size="small"
                              onClick={() =>
                                AddContent(section.section_id, "practice")
                              }
                            >
                              + Assignments
                            </Button> */}
                          </div>
                        </section>

                        {/* Add Section button between two sections */}
                        <div
                          className={styles1.content_add_buttons}
                          style={
                            section.section_id === ind_section
                              ? { visibility: "visible" }
                              : { visibility: "hidden" }
                          }
                        >
                          <Button
                            className="mx-2"
                            onClick={() => AddSection(index)}
                            type="dashed"
                            size="small"
                          >
                            + Add Section
                          </Button>
                        </div>
                      </div>
                      <div className={styles1.bottom_btns}>
                        <label className="mx-5">
                          * Make sure you are saving all changes before moving
                          to another page or reloading the page.
                        </label>
                        <Popconfirm
                          title="Your changes will not be saved"
                          onConfirm={Fetcher}
                          okText="Confirm"
                          disabled={sended}
                        >
                          <button
                            style={{
                              color: sended ? "gray" : null,
                              cursor: sended ? "not-allowed" : "pointer",
                              background: "red",
                              color: "white",
                              marginRight: "15px",
                            }}
                            className={styles1.save_btn2}
                          >
                            Cancel
                          </button>
                        </Popconfirm>

                        <Popconfirm
                          title="Do you want to save?"
                          onConfirm={() => Save_Changes()}
                          okText="Yes"
                          cancelText="No"
                        >
                          <button
                            disabled={sended}
                            style={{
                              backgroundColor: sended ? "gray" : null,
                              cursor: sended ? "not-allowed" : "pointer",
                            }}
                            className={styles1.save_btn2}
                          >
                            Save
                          </button>
                        </Popconfirm>
                        <Popconfirm
                          title="Do you want to live all the tests now?"
                          onConfirm={() =>
                            handlerPublish(searchParams.get("id"))
                          }
                          okText="Live"
                          cancelText="No"
                        >
                          <Button
                            type="primary"
                            // disabled={sended}
                            style={{
                              backgroundColor: sended ? "gray" : null,
                              cursor: sended ? "not-allowed" : "pointer",
                              background: "blue",
                              // color: "white",
                              marginLeft: "15px",
                              marginTop: "20px",
                            }}
                            // className={styles1.save_btn2}
                          >
                            Live Tests
                          </Button>
                        </Popconfirm>
                      </div>
                    </React.Fragment>
                  );
                })}

                {/* Add Section Button if there is no section */}
                {CurriculumData.length === 0 && (
                  <div className="d-flex">
                    <Button
                      className={styles1.section_btn}
                      type="dashed"
                      onClick={() => AddSection()}
                      size="small"
                    >
                      + Add Section
                    </Button>
                  </div>
                )}
              </div>
            )}
          </Content>
        </Layout>
      </Layout>
      <ProfileSlider setOpenDwa={setOpenDwa} openDwa={openDwa} />
    </>
  );
};

export default CreateCurriculum;
