import React, { useEffect, useState } from "react";
import styles from "./studentprofile.module.css";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Layout, Spin } from "antd";
import DashboardSlider from "../Navigator_Footer/DashboardSlider";
import { HomeOutlined, LoadingOutlined } from "@ant-design/icons";

import ProfileSlider from "../Navigator_Footer/ProfileSlider";
import { useSelector, useDispatch } from "react-redux";
import { replaceExperience } from "../../store/ExperienceSlice";
import { replaceEducation } from "../../store/EducationSlice";
import SplitInfo from "./ProfileComponents/SplitInfo";
import { getrequest } from "../Middleware/managerequest";
import { replaceStudentProfileData } from "../../store/StudentProfileSlice";
import LatestNavbar from "../Navigator_Footer/LatestNavbar";

const { Sider, Content } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const StudentProfile = () => {
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openDwa, setOpenDwa] = useState(false);
  const [menuKey, setMenuKey] = useState("");
  const [loading, setLoading] = useState(true);
  const { Data, StudentProfile } = useSelector((state) => state);
  const { user } = Data;

  useEffect(() => {
    if (user) {
      let url =
        accounts.length === 0
          ? `/api/v1/user/profile_details/${user?.Email}`
          : `/api/v1/user/profile_details/${user?.Email}/?type=external&fullname=${accounts[0].name}`;
      getrequest(url)
        .then((res) => {
          setLoading(false);
          dispatch(
            replaceStudentProfileData({
              ...res.data.Personal_Details,
              gitHub: res.data.Personal_Details.gitHub,
            })
          );
          dispatch(replaceExperience(res.data.Experience));
          dispatch(replaceEducation(res.data.Education));
        })
        .catch((err) => console.log(err));
    }
  }, [user, dispatch, accounts]);

  const handlerClick = (e) => {
    setMenuKey(e.key);
    if (e.key === "dashboard") {
      navigate("/user");
    }
    setOpenDwa(false);
  };
  const menuItems = [
    getItem(
      <span className={styles.menulabel}>Dashboard</span>,
      "dashboard",
      <HomeOutlined />
    ),
  ];

  return (
    <>
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          width={window.innerWidth < 350 ? 200 : "280px"}
          trigger={null}
          // style={{ backgroundColor: "#001529", height: "100vh" }}
        >
          <DashboardSlider />
        </Sider>
        <Layout style={{ width: window.innerWidth < 350 ? "200px" : "82%" }}>
          <LatestNavbar openDwa={openDwa} setOpenDwa={setOpenDwa} />
          <Content>
            <center>
              <Spin
                spinning={loading}
                className="mt-5 pt-5"
                indicator={
                  <LoadingOutlined
                    spin={loading}
                    style={{
                      fontSize: 50,
                    }}
                  />
                }
              />
            </center>
            {!loading && <SplitInfo />}
          </Content>
        </Layout>
      </Layout>

      {/* <div className={styles.pageContent}>
        

      
      </div> */}
      <ProfileSlider
        setOpenDwa={setOpenDwa}
        openDwa={openDwa}
        user={user}
        handlerClick={handlerClick}
        setMenuKey={setMenuKey}
        menuItems={menuItems}
        imageurl={StudentProfile?.imagesurl}
      />
    </>
  );
};

export default StudentProfile;
