import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Table, Popconfirm, Button, Tag, Select } from "antd";

import styles from "./userreview.module.css";
import { Modal } from "antd";
import Spinner from "react-bootstrap/esm/Spinner";
import { CheckOutlined } from "@ant-design/icons";

import QuestionReview from "./QuestionReview";
import { getrequest, postrequest } from "../../Middleware/managerequest";
import DashboardSlider from "../../Navigator_Footer/DashboardSlider";
import ProfileSlider from "../../Navigator_Footer/ProfileSlider";
import LatestNavbar from "../../Navigator_Footer/LatestNavbar";
const UserReview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [MockData, setMockData] = useState(null);
  const [newData, setNewData] = useState([]);
  const [communication, setCommunication] = useState("Average");
  const [theory, setTheory] = useState("Average");
  const [coding, setCoding] = useState("Average");
  const [openDwa, setOpenDwa] = useState("");
  const [info, setInfo] = useState(false);
  const [finaltheofeed, setFinaltheofeed] = useState("");
  const [finalcodfeed, setFinalcodfeed] = useState("");
  const [overallfeedback, setOverAllfeedback] = useState("");
  const [finalcommufeed, setFinalcommufeed] = useState("");
  const [endtime, setEndtime] = useState("");
  const [starttime, setStarttime] = useState("");
  const [duration, setDuration] = useState("");
  const [finalTag, setFinalTag] = useState([]);
  const [finalMainTag, setFinalMainTag] = useState([]);
  const { id } = useParams();
  const { user } = useSelector((state) => state.Data);
  const [show, setShow] = useState(false);
  const [ind, setInd] = useState(0);
  const [userind, setUserind] = useState(location.state.ind);
  const [ishavecodingquestion, sethavCoding] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ReviewedList, setReviewedList] = useState([]);

  const feedbackMessages = useMemo(() => {
    return {
      Theory: {
        Excellent:
          "Theory: Excellent result, please keep working on exploring the topics",
        Good: "Theory: Good result;please explore more topics to improve further",
        Average:
          "Theory: Lagging in some topics,please work hard on explaining and improving the concepts",
        Poor: "Theory:  Needs improvement in developing strong fundamentals in the concepts, please focus more on understanding and applying the theory",
      },
      Coding: {
        Excellent:
          "Coding: Excellent result; please keep practicing more difficult hard-level questions",
        Good: "Coding: Good result; please practice more medium and hard level questions to improve further",
        Average:
          "Coding: Needs further practice, please focus more on solving easy- and medium-level questions",
        Poor: "Coding: Needs improvement in problem-solving skills, please focus and practice more questions",
      },
      Communication: {
        Excellent: "Communication: Excellent, keep it up!",
        Good: "Communication: Good, please keep refining your answers to make them short, impactful and succinct",
        Average: "Communication: Please work on improveing your introduction",
        Poor: "Communication: Needs improvement, please focus on practicing more to speak in English and improving your introduction",
      },
    };
  }, []);
  const calculatePerformance = (opt, tot) => {
    const div = (opt / tot) * 100;

    return div < 101 && div > 90
      ? "Excellent"
      : div > 74 && div < 91
      ? "Good"
      : div > 49 && div < 75
      ? "Average"
      : "Poor";
  };

  const feedback = useCallback(
    (answers) => {
      let theopt = 0,
        thetot = 0;
      let codopt = 0,
        codtot = 0;
      answers.forEach((ele) => {
        if (ele.Type === "Question" || ele.Type === "Mcq") {
          theopt += Number(ele.point);
          thetot += ele.mainpoints;
        } else if (ele.Type === "Coding") {
          codopt += Number(ele.point);
          codtot += ele.mainpoints;
          sethavCoding(true);
        }
      });

      const th = thetot ? calculatePerformance(theopt, thetot) : "";
      const cod = codtot ? calculatePerformance(codopt, codtot) : "";

      setTheory(th);
      setCommunication(th);
      setCoding(cod);

      const feedbackMessageTheory = feedbackMessages.Theory[th];
      const feedbackMessageCoding =
        codtot > 0 ? feedbackMessages.Coding[cod] : "";
      const feedbackMessageCommunication = feedbackMessages.Communication[th];
      setFinalcodfeed(feedbackMessageCoding);
      setFinalcommufeed(feedbackMessageCommunication);
      setFinaltheofeed(feedbackMessageTheory);
    },
    [feedbackMessages]
  );

  const fetcher = useCallback(
    (user) => {
      setLoading(true);
      getrequest(
        `/review_mock/${id}/${user?.subdomain}/${location.state.allattempted[userind].email}`
      )
        .then((res) => {
          setLoading(false);
          setReviewedList(res.data?.findmock?.Attempted);
          if (res.data.find.length > 0) {
            setMockData(res.data.find[0].Attempted);
            setFinalTag(res.data?.find[0]?.Extra?.Tags);
            setFinalMainTag(res.data?.find[0]?.Extra?.Topics);
            deStucherData(res.data.find[0].Attempted);
            setStarttime(res.data.find[0].Attempted.starttime);
            setEndtime(res.data.find[0].Attempted.endtime);
            feedback(res.data.find[0].Attempted.Answers);
            if (res.data.find[0].Attempted.OverAllFeedback) {
              setOverAllfeedback(res.data.find[0].Attempted.OverAllFeedback);
            }
            if (res.data.find[0].Attempted.Level) {
              setTheory(res.data.find[0].Attempted.Level.Theory);
              setCommunication(res.data.find[0].Attempted.Level.Communication);
              setCoding(res.data.find[0].Attempted.Level.Coding);
            }
          }
        })
        .catch((err) => console.log(err));
    },
    [id, feedback, location, userind]
  );
  const calculatePoint = (data) => {
    let sum = 0,
      total = 0;
    if (data) {
      data.Answers.forEach((element) => {
        if (element?.Type === "Passage") {
          element?.answer?.forEach((ele) => {
            sum += Number(ele?.point);
          });
          element?.Question?.passageQuestions?.forEach((ele1) => {
            total += Number(ele1?.point);
          });
        } else {
          sum += Number(element.point);
          total += Number(element.mainpoints);
        }
      });
      return `${sum} / ${total}`;
    }
  };

  useEffect(() => {
    if (user !== null) {
      fetcher(user);
    }
    //eslint-disable-next-line
  }, [user, userind]);

  // AntDesign table Data
  const deStucherData = (data) => {
    let arr = [];
    let countKey = 1;
    data?.Answers?.forEach((item, index) => {
      const Status = (val) => {
        if (val.Manual !== undefined && !val.Manual) {
          return "AI";
        } else if (val.Manual === undefined || val.Manual) {
          return "Manually";
        } else if (
          val.feedback === " " &&
          val.point === 0 &&
          val.screenRecord.length > 0
        ) {
          return "Pending";
        }
      };
      const QuestionStatus = ({ val }) => {
        if (
          ((val.feedback === "" || val.feedback === null) && val.point === 0) ||
          (val.screenRecord.length > 0 && val.feedbackby === "AI")
        ) {
          return "Evaluate";
        } else {
          return "Evaluated";
        }
      };
      {
        // console.log(item);
      }
      if (item?.Type === "Passage") {
        const Passagearr = item?.answer?.map((ele, index) => {
          let key = countKey++;
          return {
            key2: key,
            index: index + 1,
            question: item?.Question?.passageQuestions[index]?.description,
            marks: `${ele?.point}/${item?.Question?.passageQuestions[index]?.point}`,
            incidents: item?.screenRecord?.length,
            tags: finalTag,
            maintag: item?.language,
            concept: item?.concept,
            type: item.Type,
            level: item?.Question?.passageQuestions[index]?.level,
            status: Status(item),
            questionstatus: QuestionStatus({ val: item }),
            ...(item && {
              ...item,
            }),
          };
        });
        arr.push(...Passagearr);
      } else if (item?.Type !== "Passage") {
        let obj = {
          key2: countKey++,
          question: item.title ? item.title : item?.Question.question,
          marks: `${item?.point}/${item?.mainpoints}`,
          incidents: item?.screenRecord?.length,
          tags: finalTag,
          maintag: item.language,
          concept: item.concept,
          type: item.Type,
          level: item.level,
          status: Status(item),
          questionstatus: QuestionStatus({ val: item }),
          ...(item && {
            ...item,
          }),
        };
        arr.push(obj);
      }
    });
    const uniqueArray = arr.filter(
      (obj, index, self) =>
        index === self.findIndex((t) => t.question === obj.question)
    );
    setNewData(uniqueArray);
  };

  useEffect(() => {
    const difference = +new Date(endtime) - +new Date(starttime);
    let timeleft = {};
    if (difference > 0) {
      timeleft = {
        hours:
          Math.floor(difference / (1000 * 60 * 60)) < 10
            ? "0" + Math.floor(difference / (1000 * 60 * 60))
            : Math.floor(difference / (1000 * 60 * 60)),
        minutes:
          Math.floor((difference / 1000 / 60) % 60) < 10
            ? "0" + Math.floor((difference / 1000 / 60) % 60)
            : Math.floor((difference / 1000 / 60) % 60),
        seconds:
          Math.floor((difference / 1000) % 60) < 10
            ? "0" + Math.floor((difference / 1000) % 60)
            : Math.floor((difference / 1000) % 60),
      };
    }

    setDuration(timeleft);
  }, [endtime, starttime]);

  const handleModal = () => {
    setShow(false);
    fetcher();
  };
  function convertUTCtoIST(utcTime) {
    // Create a Date object from the UTC time string
    const utcDate = new Date(utcTime);

    // Convert to IST by adding 5 hours and 30 minutes
    utcDate.setUTCHours(utcDate.getUTCHours() + 5);
    utcDate.setUTCMinutes(utcDate.getUTCMinutes() + 30);

    // Get year, month, day, hours, minutes, and seconds in IST
    const year = utcDate.getUTCFullYear();
    const month = utcDate.getUTCMonth() + 1; // Months are zero-based, so add 1
    const day = utcDate.getUTCDate();
    const hours = utcDate.getUTCHours();
    const minutes = utcDate.getUTCMinutes();
    const seconds = utcDate.getUTCSeconds();

    // Determine AM or PM
    const ampm = hours >= 12 ? "PM" : "AM";
    // Convert hours to 12-hour format
    const formattedHours = hours % 12 || 12;

    // Format the IST time as "YYYY-MM-DD hh:mm:ss AM/PM"
    const istTime = `${String(day).padStart(2, "0")}-${String(month).padStart(
      2,
      "0"
    )}-${year} ${formattedHours}:${String(minutes).padStart(2, "0")}:${String(
      seconds
    ).padStart(2, "0")} ${ampm}`;

    return istTime;
  }

  const getColumnSearchProps = (dataIndex) => ({
    render: (text) => (
      <div
        dangerouslySetInnerHTML={{ __html: text }}
        style={{ height: "auto", maxHeight: "200px", overflow: "auto" }}
      />
    ),
  });

  // Ant Design table
  const columns = [
    {
      title: "No.",
      dataIndex: "key2",
      rowScope: "row",
      width: "4%",
    },

    {
      title: "Subject",
      dataIndex: "maintag",
      width: "8%",
      filters: finalMainTag.map((ele) => ({
        text: ele,
        value: ele,
      })),
      onFilter: (value, record) => record.maintag === value,
    },
    {
      title: "Topic",
      dataIndex: "concept",
      width: "8%",
      filters: finalTag.map((ele) => ({
        text: ele,
        value: ele,
      })),
      onFilter: (value, record) => record.concept === value,
    },
    {
      title: "Type",
      dataIndex: "type",
      width: "8%",
      filters: [
        { value: "Mcq", text: "Mcq" },
        { value: "Question", text: "Question" },
        { value: "Coding", text: "Coding" },
        { value: "Passage", text: "Passage" },
      ],
      onFilter: (value, record) => record.type === value,
    },
    {
      title: "Difficulty",
      dataIndex: "level",
      width: "10%",
      filters: [
        {
          value: "Easy",
          text: "Easy",
        },
        {
          value: "Medium",
          text: "Meduim",
        },
        {
          value: "Hard",
          text: "Hard",
        },
      ],
      onFilter: (value, record) => record.level === value,
    },

    {
      title: "Question",
      dataIndex: "question",
      width: "45%",
      ...getColumnSearchProps("question"),
    },
    {
      title: "Status",
      dataIndex: "questionstatus",
      width: "10%",
      filters: [
        {
          text: "Evaluated",
          value: "Evaluated",
        },
        {
          text: "Evaluate",
          value: "Evaluate",
        },
      ],
      onFilter: (value, record) => record.questionstatus === value,
      render: (_, record) => (
        <>
          <Tag color={record.questionstatus === "Evaluated" ? "green" : "blue"}>
            {record.questionstatus}
          </Tag>
        </>
      ),
    },
    {
      title: "Incidents",
      dataIndex: "incidents",
      width: "8%",
      filterMode: "menu",
      filterSearch: true,
      render: (_, record) => (
        <>
          <Tag color={record.incidents > 0 ? "red" : "green"}>
            {record.incidents}
          </Tag>
        </>
      ),
    },
    {
      title: "Marks",
      dataIndex: "marks",
      width: "7%",
    },
  ];

  const Submit = (e) => {
    if (e) {
      let points = calculatePoint(MockData);
      let percentage = Math.round(
        (points.split("/")[0] / points.split("/")[1]) * 100
      );
      let obj = {
        Mockid: id,
        index: userind,
        Email: MockData?.Email,
        name: user?.FullName,
        Overall: overallfeedback,
        Level: {
          Communication: communication,
          Theory: theory,
          Coding: coding,
        },
        TestName: location.state.MockTitle,
        GivenDate: MockData.starttime,
        TestMark: points.split("/")[1],
        ObtainMark: points.split("/")[0],
        Result: percentage > 65 ? "Pass" : "Fail",
        userName: MockData?.FullName,
        Percentage: percentage,
      };
      postrequest(`/questionfeedback/${user?.subdomain}`, obj)
        .then((res) => {
          fetcher();
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className="d-flex justify-content-center">
      {show && (
        <QuestionReview
          setShow={handleModal}
          ind={ind}
          item={newData}
          // item={MockData.Answers}
          feedback={feedback}
          overallfeedback={overallfeedback}
          level={{ communication, theory, coding }}
          index={userind}
          email={MockData.Email}
          fetcher={fetcher}
          newData={newData}
        />
      )}
      <div className={styles.maincon}>
        {/* Heading User Data */}
        <div className={styles.dashboard}>
          <DashboardSlider />
        </div>
        {loading ? (
          <div className={styles.spinner}>
            <Spinner
              animation="border"
              size="lg"
              variant="warning"
              className="spinner"
            />
          </div>
        ) : (
          <div className={styles.table}>
            <LatestNavbar setOpenDwa={setOpenDwa} openDwa={openDwa} />

            <div className={styles.backbutton}>
              <label
                className={styles.backbuttonlabel}
                onClick={() => navigate("/admin")}
              >
                Home
              </label>
              &gt;
              <label
                className={styles.backbuttonlabel}
                onClick={() => navigate(`/mockdetails/${id}`)}
              >
                {location.state.MockTitle}
              </label>
              &gt;
              {
                <Select
                  className={styles.backbuttonlabel}
                  size="small"
                  filterOption={(input, option) => {
                    (option?.label).toLowerCase().includes(input.toLowerCase());
                  }}
                  style={{
                    width: "180px",
                  }}
                  value={userind}
                  options={
                    ReviewedList.length > 0 &&
                    ReviewedList.map((item, index) => {
                      return {
                        value: index,
                        label: (
                          <div className="d-flex justify-content-between">
                            {item.FullName}{" "}
                            {item.FeedBack && (
                              <CheckOutlined style={{ color: "green" }} />
                            )}
                          </div>
                        ),
                      };
                    })
                  }
                  onChange={(e) => setUserind(e)}
                />
              }
            </div>

            <Table
              columns={columns}
              dataSource={newData}
              loading={newData.length <= 0}
              style={{
                width: "100%",
                margin: "5px auto auto auto",
                padding: "15px",
              }}
              caption={true}
              scroll={{
                y: 310,
              }}
              size="small"
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    let ind = MockData?.Answers?.findIndex(
                      (item) => item.Question.question === record.question
                    );
                    setShow(true);
                    setInd(rowIndex);
                  },
                };
              }}
            />
            {/* OverAll FeedBack */}
            <div>
              <div className="d-flex justify-content-evenly mb-4">
                <div className="d-flex justify-content-center">
                  <label>
                    <b> Overall FeedBack : </b>
                  </label>
                  <textarea
                    className={styles.feedback}
                    placeholder="Write the feedback here for student"
                    value={overallfeedback}
                    onChange={(e) => {
                      setOverAllfeedback(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <Popconfirm
                    onConfirm={Submit}
                    title="Submit"
                    okText="Yes"
                    description="Are you sure to submit?"
                    cancelText="No"
                  >
                    <Button>Submit</Button>
                  </Popconfirm>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        onOk={() => setInfo()}
        onCancel={() => setInfo(false)}
        open={info}
        title="Test Information"
      >
        {!loading && (
          <div className={styles.studentsdetails}>
            <div className={styles.userfulldetails}>
              <div className={styles.userdetails}>
                <h4>Test Name : {location.state.MockTitle}</h4>
                <label style={{ fontWeight: "600" }}>
                  Student Name : {MockData?.FullName}
                </label>
              </div>
              <div className={styles.userdetails}>
                <label>Student Email : {MockData?.Email}</label>
                <label>
                  Duration : {duration.hours}:{duration.minutes}:
                  {duration.seconds}
                </label>
              </div>
              <div className={styles.userdetails}>
                <label>
                  Start Time : {MockData && convertUTCtoIST(MockData.starttime)}
                </label>
                <label>TotalPoints : {calculatePoint(MockData)}</label>
              </div>
            </div>
          </div>
        )}
      </Modal>
      <ProfileSlider setOpenDwa={setOpenDwa} openDwa={openDwa} />
    </div>
  );
};

export default UserReview;
