import { Button, Input, message, Modal, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import styles from "./UploadOMR.module.css";
import { postrequest } from "../../Middleware/managerequest";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";
const OMRUpload = ({ Extra }) => {
  const videoRef = useRef(null); // Reference for the video element
  const canvasRef = useRef(null); // Reference for the canvas element
  const { user } = useSelector((state) => state.Data);
  const [loding, setLoding] = useState(false); // State to store the captured photo
  const [selectedFile, setSelectedFile] = useState(null);
  const [currectStep, setCurrectStep] = useState(0);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const url = process.env.REACT_APP_BACKEND_SERVER;
  // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleUpload = async () => {
    if (!selectedFile) {
      message.warning("Select image");
      return;
    }
    setLoding(true);
    const formData = new FormData();
    formData.append("omrImage", selectedFile);
    try {
      const response = await axios.post(
        `${url}/home/omr_sheet/${id}/${user?.Email}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        }
      );
      setLoding(false);
      setData(response.data);
      if (!response.data?.success) {
        message.warning(response.data?.error);
      } else {
        message.success("OMR Uploaded Successfully");
      }
    } catch (error) {
      console.error("Error uploading file", error);
      setLoding(false);
    }
  };
  const returnFileName = (file) => {
    if (file === "omr") {
      return "OMR Sheet";
    } else if (file === "modelAnswer") {
      return "Answers Sheet";
    } else if (file === "questionPaper") {
      return "Question Paper";
    } else {
      return "Answer Keys";
    }
  };
  return (
    <>
      {loding ? (
        <div className={styles.container}>
          <Spin />
        </div>
      ) : currectStep === 1 ? (
        <div className={styles.omrmainContainer}>
          <div className={styles.container}>
            <h1>Upload you OMR Sheet</h1>
            <div className={styles.uploadGallery}>
              <input
                type="file"
                id="filesupload"
                style={{ display: "none" }}
                multiple
                onChange={handleFileChange}
              />
              <label className={styles.uploadfiles} htmlFor="filesupload">
                <UploadOutlined /> Upload File
              </label>
              <Button className="mt-2">Click from camera</Button>
            </div>

            <Button type="primary" onClick={() => handleUpload(true)}>
              Upload Image And Submit
            </Button>
          </div>
          <div className={styles.container}>
            <h1>Download Answers</h1>
            {Extra?.testFiles?.map((ele) => {
              if (ele.fileName !== "omr" && ele.fileName !== "questionPaper") {
                return (
                  <li>
                    <Button style={{ margin: "5px 0px" }} type="primary">
                      <a href={ele?.filepath} download={true}>
                        {returnFileName(ele?.fileName)}
                      </a>
                    </Button>
                  </li>
                );
              }
            })}
          </div>
        </div>
      ) : (
        <div className={styles.filesSection}>
          <h4> Download below files:</h4>
          <ul>
            {Extra?.testFiles?.map((ele) => {
              if (ele.fileName === "omr" || ele.fileName === "questionPaper") {
                return (
                  <li>
                    <label> {returnFileName(ele?.fileName)}:</label>
                    <a href={ele?.filepath} download={true}>
                      Download
                    </a>
                  </li>
                );
              }
            })}
          </ul>
          <Button type="primary" onClick={() => setCurrectStep(1)}>
            Next
          </Button>
        </div>
      )}
    </>
  );
};
export default OMRUpload;
