import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Collapse, Divider, Layout, Typography } from "antd";
import {
  HomeOutlined,
  ContainerOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  ReconciliationOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import DashboardSlider from "../../Navigator_Footer/DashboardSlider";
import LatestNavbar from "../../Navigator_Footer/LatestNavbar";
import ProfileSlider from "../../Navigator_Footer/ProfileSlider";
import styles from "../AdminHome.module.css";
import styles1 from "./styles/courseview.module.css";
import { getrequest } from "../../Middleware/managerequest";
const { Sider, Content } = Layout;
const { Text } = Typography;
// Menu
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const CurrciulumView = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const data = useSelector((state) => state.Data);
  const { user, access, clientdetails } = data;
  const [openDwa, setOpenDwa] = useState(false);
  const [loading, setLoading] = useState(true);
  const [Curriculum, setCurriculum] = useState([]);
  const [CourseTitle, setCourseTitle] = useState();
  const [seletctedcontent, setSelectedcontent] = useState("");
  const [activekey, setActiveKey] = useState("");

  // Fetch Content of course
  const Fetcher = useCallback(() => {
    
    getrequest(`/get_curriculum/${searchParams.get("id")}`)
      .then((res) => {
        setLoading(false);

        setCourseTitle(res.data.CourseTitle);
        setCurriculum(res.data.sections);
        setSelectedcontent(res.data.sections[0].content[0]);
        setActiveKey([res.data.sections[0].section_id]);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [searchParams]);

  useEffect(() => {
    Fetcher();
  }, [Fetcher]);

  // Menu Items
  const menuItems = [
    getItem(
      <span className={styles.menulabel}>Dashboard</span>,
      "admin",
      <HomeOutlined style={{ fontWeight: "600", fontSize: "17px" }} />
    ),
    getItem(
      <span className={styles.menulabel}>Create One Time Test</span>,
      "createmock",
      <ContainerOutlined style={{ fontWeight: "600", fontSize: "17px" }} />,
      null,
      null,
      true
    ),

    getItem(
      <span className={styles.menulabel}>Question Bank</span>,
      "questions",
      <UnorderedListOutlined style={{ fontWeight: "600", fontSize: "17px" }} />,
      null,
      null
    ),
    getItem(
      <span className={styles.menulabel}>Courses</span>,
      "course",
      <ReconciliationOutlined
        style={{ fontWeight: "600", fontSize: "17px" }}
      />,
      null,
      null
    ),
    clientdetails?.isgroup &&
      getItem(
        <span className={styles.menulabel}>Batches</span>,
        "managegroups",
        <UsergroupAddOutlined
          style={{ fontWeight: "600", fontSize: "17px" }}
        />,
        null,
        null
      ),
    access &&
      access?.role === "Admin" &&
      clientdetails?.isusers &&
      getItem(
        <span className={styles.menulabel}>Manage Users</span>,
        "manageusers",
        <UsergroupAddOutlined
          style={{ fontWeight: "600", fontSize: "17px" }}
        />,
        null,
        null
      ),
    "main" === user?.subdomain &&
      getItem(
        <span className={styles.menulabel}>Manage Organizations</span>,
        "my_organizations",
        <UsergroupAddOutlined
          style={{ fontWeight: "600", fontSize: "17px" }}
        />,
        null,
        null
      ),
  ];

  // Sidebar Navigation
  const handlerClick = (e) => {
    if (
      ["admin", "managegroups", "questions", "createmock", "course","manageusers","my_organizations"].includes(
        e.key
      )
    ) {
      navigate(`/${e.key}`);
    }
  };

  const lblname = (content, arr) => {
    switch (content.type) {
      case "lession":
       
        return (
          <label className={styles1.lablename}>
            Live Session 
          </label>
        );
      case "test":
       
        return <label className={styles1.lablename}>Test  </label>;
      case "practice":
        return (
          <label className={styles1.lablename}>Assignment  </label>
        );
      default:
        return -1;
    }
  };

  const RenderSections = (curriculum) => {
    return curriculum.map((section, sectionid) => {
      return {
        key: section.section_id,
        label: <b>{section.sectionName}</b>,
        children: section.content.map((content, contentid, arr) => {
          return (
            <div
              key={contentid}
              style={{
                backgroundColor:
                  content.content_id === seletctedcontent?.content_id
                    ? "lightgray"
                    : null,
              }}
              onClick={() => setSelectedcontent(content)}
              className={styles1.content_name}
            >
              {lblname(content, arr)}{" "}
              <Text
                style={{ width: "250px" }}
                ellipsis={{ tooltip: content.content_Name }}
              >
                {content.content_Name}
              </Text>
            </div>
          );
        }),
      };
    });
  };

  const Skills = (val) => {
    return val.map((item, ind) => {
      return (
        <span key={ind}>
          {item.MainTags}
          {val.length - 1 !== ind && ","}
        </span>
      );
    });
  };

  const ConvertToHours = (val) => {
    if (val === 0) {
      return 0;
    } else if (val <= 60) {
      return val + " min(s)";
    } else {
      const hours = Math.floor(val / 60);
      const remainingMinutes = val % 60;
      if (hours > 0 || remainingMinutes > 0) {
        return `${hours} hr ${remainingMinutes} min(s)`;
      } else {
        return 0;
      }
    }
  };

  const RenderDetails = () => {
    return (
      <div className={styles1.details_main}>
        {/* For Live Session and Recorded Sessions */}
        {seletctedcontent &&
          (seletctedcontent?.type === "lession" ) && (
            <>
              <div className="w-50">
                <label className={styles1.lablename}>Description</label>
                <br />
                <label>{seletctedcontent?.Description}</label>
              </div>
              {seletctedcontent.type === "lession" && (
                <>
                {seletctedcontent?.attachtype==="link"&&!seletctedcontent?.islive_session&&<div className="w-50">
                    <label className={`${styles1.lablename}`}>
                      External Link
                    </label>

                    <a
                      href={seletctedcontent?.external_link?.link}
                      rel="noreferrer"
                      target="_blank"
                    >
                      Click Here
                    </a>
                  </div>}
                  {(seletctedcontent?.attachtype==="video"||seletctedcontent?.attachtype==="pdf") &&!seletctedcontent?.islive_session&& <div className={`${styles1.lablename}`}>
                    <label>Attachments</label>
                    <br />
                   
                    {seletctedcontent?.attachtype==="video"?<video controls width={300}>
                      <source
                        src={seletctedcontent?.attachments}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>:<a rel="noreferrer"  href={seletctedcontent?.attachments} target="_blank">View PDF</a>}
                    
                  </div>}
                </>
              )}
            </>
          )}

        {seletctedcontent &&
          (seletctedcontent?.type === "test" ||
            seletctedcontent.type === "practice") && (
            <>
              <div className="w-50">
                <label className={styles1.lablename2}>Category</label>
                <br />
                <label>{seletctedcontent?.Test_Details?.type}</label>
                <br />
              </div>
              <div className="w-50">
                <label className={styles1.lablename2}>Test Name</label>
                <br />
                <label>{seletctedcontent?.Test_Details?.Test_Name}</label>
                <br />
              </div>
              {seletctedcontent?.type === "test" && (
                <div className="w-50">
                  <label className={styles1.lablename2}>Duration</label>
                  <br />
                  <label>
                    {ConvertToHours(seletctedcontent?.Test_Details?.Duration)}
                  </label>
                  <br />
                </div>
              )}

              <div className="w-50">
                <label className={styles1.lablename2}>No of Questions</label>
                <br />
                <label>{seletctedcontent?.Test_Details?.NoofQ}</label>
                <br />
              </div>
              <div className="w-50">
                <label className={styles1.lablename2}>Topic</label>
                <br />
                <label>
                  {Skills(seletctedcontent?.Test_Details?.MockData)}
                </label>
                <br />
              </div>
            </>
          )}
      </div>
    );
  };

  return (
    <>
      <Layout>
        <Sider
          className={styles.slider}
          breakpoint="lg"
          collapsedWidth="0"
          width={window.innerWidth < 350 ? 200 : "18%"}
          trigger={null}
        >
          <DashboardSlider />
        </Sider>
        <Layout className={styles1.left_side_course}>
          <LatestNavbar openDwa={openDwa} setOpenDwa={setOpenDwa} />

          <Content style={{ marginTop: "60px" }}>
            <center>
              {loading && <LoadingOutlined className={styles1.loading_btn} />}
              {!loading &&Curriculum.length===0 && <><div className={styles1.course_details}>
                  <label className={styles1.courseTitle}>{CourseTitle}</label>
                  <label style={{cursor:"pointer"}} className="mx-3" onClick={()=>navigate(`/createcurriculum?id=${searchParams.get("id")}`)}>Edit</label>
                </div>
                <center><label>No content added yet</label><br/><br/>
                <u style={{cursor:"pointer"}} onClick={()=>navigate(`/createcurriculum?id=${searchParams.get("id")}`)}>Add Content</u></center>
                </>}
            </center>
           
            {!loading &&Curriculum.length>0 && (
              <div>
                <div className={styles1.course_details}>
                  <label className={styles1.courseTitle}>{CourseTitle}</label>
                  <label style={{cursor:"pointer"}} className="mx-3" onClick={()=>navigate(`/createcurriculum?id=${searchParams.get("id")}`)}>Edit</label>
                </div>
                <Divider orientation="left" />
                {/* Curriculum */}
                <div className={styles1.curriculum_main}>
                  {/* Left Side view */}
                  <div className={styles1.left_side}>
                    <RenderDetails />
                  </div>
                  {/* Right Side View */}
                  <div className={styles1.right_side}>
                    <Collapse
                      activeKey={activekey}
                      bordered={false}
                      accordion
                      onChange={(val) => setActiveKey(val)}
                      expandIconPosition={"end"}
                      style={{ width: "100%" }}
                      items={RenderSections(Curriculum)}
                    />
                  </div>
                </div>
              </div>
            )}
          </Content>
        </Layout>
      </Layout>
      <ProfileSlider
        setOpenDwa={setOpenDwa}
        openDwa={openDwa}
     
      />
    </>
  );
};

export default CurrciulumView;
