import omrSheetUrl from "./pdf.pdf";
import jsPDF from "jspdf";
import QRCode from "qrcode";
import "jspdf-autotable";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { message } from "antd";
import { getrequest } from "../Middleware/managerequest";

export const convertImageToBase64 = async (url) => {
  try {
    const response = await getrequest(`/logo?url=${encodeURIComponent(url)}`, {
      responseType: "arraybuffer", // Add this option to handle the binary data
    });

    if (response?.status !== 200) {
      throw new Error(`Failed to fetch image: ${response.statusText}`);
    }
    return response?.data?.base64;
  } catch (error) {
    console.error("Error converting image to Base64:", error);
  }
};
const convertDateIntoNormalend = (val) => {
  // Create a Date object from the input string
  const date = new Date(val);

  // Extract the day, month, and year components
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  // Extract the hours and minutes components
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Construct the formatted date string
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
  return formattedDate;
};
// Function to generate the PDF
export const generatePDF = async (
  data,
  filename,
  logo,
  instruction,
  TestName,
  set
) => {
  const doc = new jsPDF();
  let questionCount = 1;
  let companyURL = "Unoprep";
  let yPosition = 10;
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const leftMargin = 10;
  const rightMargin = pageWidth - 10;
  const bottomMargin = 20;
  const maxLineWidth = rightMargin - leftMargin;
  let pageNum = 1;
  const date1 = new Date(data.MockStart);
  const date2 = new Date(data.Mockend);
  const differenceInMs = Math.abs(date2 - date1);
  const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
  const differenceInHours = Math.floor(
    (differenceInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const differenceInMinutes = Math.floor(
    (differenceInMs % (1000 * 60 * 60)) / (1000 * 60)
  );
  if (differenceInDays > 0 || differenceInHours > 6) {
    message.warning(
      "Please change the end date, You are not allowed to put this time the time is too much."
    );
    return false;
  }
  // Helper function to create a new page when yPosition exceeds page height
  const checkPageOverflow = () => {
    if (yPosition >= pageHeight - bottomMargin) {
      addFooter(pageNum++); // Add footer before starting a new page
      doc.addPage();
      yPosition = 10; // Reset y position for new page
    }
  };

  //   const logoBase64 = await convertImageToBase64(clientdetails?.logo);

  const addHeader = async (doc, TestName) => {
    const pageWidth = doc.internal.pageSize.width;
    const leftMargin = 10;
    const rightMargin = pageWidth - 10;

    await doc.addImage(logo, "JPEG", 2, 2, 15, 12); // (image, type, x, y, width, height)
    doc.setFontSize(12);
    doc.setFont("bold");
    doc.text(
      "DO NOT OPEN THIS TEST BOOKLET UNTIL YOU ARE TOLD TO DO SO",
      pageWidth / 2,
      20,
      { align: "center" }
    );

    doc.setFontSize(17);
    doc.setFont("bold");
    doc.text(TestName, pageWidth / 2, 30, { align: "center" });

    doc.setFontSize(12);
    doc.text(filename, pageWidth / 2, 40, { align: "center" });

    doc.setFontSize(10);
    doc.text(
      `Time Allowed: ${differenceInHours} hours, ${differenceInMinutes} minutes`,
      leftMargin,
      48
    );
    doc.text("Maximum Marks: 200", rightMargin - 50, 48);
    // Divider line before instructions
    doc.line(leftMargin, 51, rightMargin, 51);

    // Instructions Section
    doc.setFontSize(10);
    doc.setFont("normal");

    doc.setFontSize(19);
    doc.setFont("bold");
    doc.text("Instructions", 10, 62, { align: "left" });
    doc.setFontSize(12);
    let instructionY = 72;
    const maxLineWidth = 190;
    instruction.forEach((instruction) => {
      const splitText = doc.splitTextToSize(instruction, maxLineWidth);
      doc.text(splitText, leftMargin, instructionY);
      instructionY += splitText.length * 6;
    });
    yPosition = instructionY;
  };

  await addHeader(
    doc,
    TestName,
    convertDateIntoNormalend(data.startdate),
    convertDateIntoNormalend(data.enddate),
    "12012"
  );

  const addFooter = (pageNum) => {
    const footerYPosition = pageHeight - 10; // Position at the bottom of the page
    doc.setFontSize(10);
    const footerText = `Page ${pageNum} | ${companyURL}`;
    const footerTextWidth = doc.getTextWidth(footerText);

    // Align the footer text to be centered
    doc.text(footerText, (pageWidth - footerTextWidth) / 2, footerYPosition);
    doc.setFontSize(12);
  };

  // Helper function to split text into multiple lines if it exceeds max width
  const splitText = (text) => {
    return doc.splitTextToSize(text, maxLineWidth);
  };

  await set.forEach((item) => {
    // Add title for each question type
    doc.setFontSize(16); // Set the font size for the title
    if (item?.type === "Passage") {
      const passage = item.qstn.question.replace(/<[^>]+>/g, "");
      yPosition += 7;
      doc.text(`Passage:`, leftMargin, yPosition);
      yPosition += 8;

      // Reset font size for passage content
      doc.setFontSize(12);
      const passageLines = splitText(passage);

      passageLines.forEach((line) => {
        checkPageOverflow();
        doc.text(line, leftMargin, yPosition);
        yPosition += 5.5;
      });
      yPosition += 10;
      doc.setFontSize(15);
      doc.text(`Passage Questions`, leftMargin, yPosition);
      doc.setFontSize(12);
      yPosition += 8;
      doc.setFontSize(12); // Consistent font size for passage questions
      item.qstn.passageQuestions.forEach((q) => {
        const questionText = `${questionCount++}. ${q.description} (Marks: ${
          q.point
        })`;
        const questionLines = splitText(questionText);
        checkPageOverflow();
        doc.text(questionLines, leftMargin, yPosition);
        yPosition += questionLines.length * 8;

        // Add options
        q.options.forEach((option, idx) => {
          const optionText = `${String.fromCharCode(65 + idx)}. ${option}`;
          const optionLines = splitText(optionText);
          doc.text(optionLines, leftMargin + 10, yPosition); // Indent options
          yPosition += optionLines.length * 8;
          checkPageOverflow();
        });

        yPosition += 8; // Add space after each question
      });
    }

    if (item.type === "Mcq") {
      doc.setFontSize(16); // Set the font size for MCQ question title
      const mcqQuestion = item.qstn.question.replace(/<[^>]+>/g, "");
      const mcqQuestionLines = splitText(
        `${questionCount++}. ${mcqQuestion} (Marks: 1)`
      );

      checkPageOverflow();
      doc.text(mcqQuestionLines, leftMargin, yPosition);
      yPosition += mcqQuestionLines.length * 10;

      doc.setFontSize(12); // Set font size for MCQ options

      // Add MCQ options
      ["opt1", "opt2", "opt3", "opt4"].forEach((opt, idx) => {
        checkPageOverflow();
        doc.text(
          `${String.fromCharCode(65 + idx)}. ${item[opt]}`,
          leftMargin + 10,
          yPosition
        );
        yPosition += 8;
      });

      yPosition += 8; // Space after each MCQ question
    }
  });

  addFooter(pageNum); // Add footer to the last page
  doc.save(`${filename}.pdf`);
};
export const replaceHeader = async (
  TestName,
  SetNo,
  url,
  data,
  logo,
  instructionsArray
) => {
  const existingPdfBytes = await fetch(omrSheetUrl).then((res) =>
    res.arrayBuffer()
  );
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const firstPage = pdfDoc.getPages()[0];
  const { width, height } = firstPage.getSize();
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
  const date1 = new Date(data.MockStart);
  const date2 = new Date(data.Mockend);
  const differenceInMs = Math.abs(date2 - date1);
  const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
  const differenceInHours = Math.floor(
    (differenceInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const differenceInMinutes = Math.floor(
    (differenceInMs % (1000 * 60 * 60)) / (1000 * 60)
  );
  if (differenceInDays > 0 || differenceInHours > 6) {
    message.warning(
      "Please change the end date, You are not allowed to put this time the time is too much."
    );
    return false;
  }
  //   const logoBase64 = await convertImageToBase64(clientdetails?.logo);
  const logoImage = await pdfDoc.embedPng(logo); // or use embedJpg if it's a JPG
  const qrCodeDataUrl = await QRCode.toDataURL(url);
  const QRCodeURL = await pdfDoc.embedPng(qrCodeDataUrl);
  const addHeader = (page) => {
    const leftMargin = 10;
    const rightMargin = width - 10;
    page.drawRectangle({
      x: 0,
      y: height - 271,
      width: width,
      height: 271,
      color: rgb(0.9, 0.9, 0.9),
    });

    page.drawImage(logoImage, {
      x: leftMargin, // Adjust X position as needed
      y: height - 50, // Adjust Y position
      width: 45,
      height: 40,
    });
    page.drawText("DO NOT OPEN THIS TEST BOOKLET UNTIL YOU ARE TOLD TO DO SO", {
      x: width / 2 - 200,
      y: height - 30,
      size: 12,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });

    page.drawText(TestName, {
      x: width / 2 - 30,
      y: height - 60,
      size: 17,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });

    page.drawText(`${TestName} OMR Set ${SetNo}`, {
      x: width / 2 - 50,
      y: height - 80,
      size: 12,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
    page.drawImage(QRCodeURL, {
      x: width - 95, // Adjust X position as needed
      y: height - 220, // Adjust Y position
      width: 85,
      height: 85,
    });

    page.drawText(
      `Time Allowed: ${differenceInHours} hours, ${differenceInMinutes} minutes`,
      {
        x: leftMargin,
        y: height - 120,
        size: 10,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      }
    );

    page.drawText("Maximum Marks: 200", {
      x: rightMargin - 100,
      y: height - 120,
      size: 10,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });

    // Divider Line
    page.drawLine({
      start: { x: leftMargin, y: height - 125 },
      end: { x: rightMargin, y: height - 125 },
      thickness: 1,
      color: rgb(0, 0, 0),
    });

    // Instructions Section
    page.drawText("Instructions", {
      x: leftMargin,
      y: height - 145,
      size: 19,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });

    // Instructions from editorHtml
    // const instructionsArray = htmlToInstructionsArray(editorHtml);
    let instructionY = height - 165;
    const maxLineWidth = 500;

    instructionsArray.forEach((instruction) => {
      page.drawText(instruction, {
        x: leftMargin,
        y: instructionY,
        size: 12,
        font: helveticaFont,
        color: rgb(0, 0, 0),
        maxWidth: maxLineWidth,
      });
      instructionY -= 20; // Adjust spacing between instructions
    });
  };

  // Add header to the first page
  addHeader(firstPage);
  // Save the updated PDF as bytes
  const pdfBytes = await pdfDoc.save();
  // Trigger download for the updated PDF
  download(pdfBytes, `${TestName} OMR Sheet Set ${SetNo}`, "application/pdf");
};
// Helper function to trigger a download of the updated PDF
const download = (data, fileName, mimeType) => {
  const blob = new Blob([data], { type: mimeType });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};
