import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "./questionreview.module.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Typography,
  InputNumber,
  Image,
  Tabs,
  Modal,
  Button,
  message,
} from "antd";
import { toast, ToastContainer } from "react-toastify";
import Editor from "@monaco-editor/react";

import { postrequest } from "../../Middleware/managerequest";

const QuestionReview = ({
  setShow,
  item,
  ind,
  feedback,
  overallfeedback,
  level,
  index,
  fetcher,
  newData,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [QNO, setQno] = useState(ind);
  const [activekey, setActiveKey] = useState(1);
  const [output, setOutput] = useState("");
  const [compilestatus, setCompilerStatus] = useState(false);
  const [Isinfinite, setInfinite] = useState(false);
  const [code, setJavascript] = useState("");
  const [csscode, setCsscode] = useState({ csscode: "", scriptcode: "" });
  const [Answerdata, setAnswerData] = useState(item);
  const [isclosemodal, setIscloseModal] = useState(false);
  const [submitmodal, setsubmitmodal] = useState(false);
  const [ischanged, setIschanged] = useState(false);
  const { id } = useParams();
  const [testpass, setTestPass] = useState(false);
  const [openmodal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { Paragraph } = Typography;
  const modalref = useRef(null);
  const { user } = useSelector((state) => state.Data);
  const key = "updatable";
  //Setting Camera and Screen Recording
  useEffect(() => {
    if (item[QNO]?.Type === "Coding") {
      setTestPass(item[QNO]?.isTestPass);
    }

    if (item[QNO]?.Type === "Coding") {
      setJavascript(item[QNO]?.answer);
      if (item[QNO]?.extension === "html") {
        setCsscode((prev) => ({ ...item[QNO]?.HTMLCode }));
      }
    }
  }, [QNO, item]);

  const HTML_Code = () => {
    const modifiedScript = `<!DOCTYPE html> <html>
    <head>
      <style>${csscode?.csscode}</style>
    </head>
    <body>${code}</body>
    <script>${csscode?.scriptcode}</script>
    </html>`;

    setOutput(modifiedScript);
    setOpenModal(true);
  };

  // Run the code
  const RunCode = () => {
    setOutput("");
    const languages = [
      { language: "python", extension: "py" },
      { language: "javascript", extension: "js" },
      { language: "mysql", extension: "sql" },
    ];
    let find = null;
    if (item[QNO].extension) {
      find = languages.find((val) => val.extension === item[QNO].extension);
    } else {
      find = { language: "javascript", extension: "js" };
    }
    let data = ["mysql", "postgresql", "mongodb"].includes(find.language)
      ? {
          language: find.language,
          stdin: "",
          files: [
            {
              name: `index.${find.extension}`,
              content: code,
            },
          ],
        }
      : {
          files: [
            {
              name: `Main.${find.extension}`,
              content:
                code +
                "\n" +
                (item[QNO]?.TestCode ? item[QNO]?.TestCode?.trim() : ""),
            },
          ],
        };
    setActiveKey(6);
    if (code.length !== 0) {
      setCompilerStatus(true);
      postrequest(`/compiler/${find.language}`, data)
        .then((res) => {
          setOutput(res.data.data);
          setCompilerStatus(false);
          setInfinite(false);
          if (item[QNO]?.Outputtocheck && res?.data?.data?.stdout !== "") {
            let data = res.data?.data?.stdout;
            if (typeof data === "string") {
              data = data.replace(/'/g, '"').trim();
              function isJSONString(str) {
                try {
                  JSON.parse(str);
                  return true;
                } catch (error) {
                  return false;
                }
              }
              if (isJSONString(data)) {
                try {
                  data = JSON.parse(data);

                  data = JSON.stringify(data).replace(/undefined/g, "null");

                  let isEqual =
                    JSON.stringify(item[QNO]?.Outputtocheck) ===
                    JSON.stringify(JSON.parse(data));
                  setTestPass(isEqual);
                } catch (error) {
                  alert("Code : 1");
                }
              }
            }
          } else if (item[QNO]?.TestCase?.Output) {
            //eslint-disable-next-line
            if (res.data.data.stdout == item[QNO]?.TestCase?.Output) {
              setCompilerStatus(true);
            } else {
              setCompilerStatus(false);
            }
          } else {
            setCompilerStatus(false);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 500) {
            setInfinite(true);
            setCompilerStatus(false);
          }
          if (err.response.status === 408) {
            toast.warning("server error", {
              position: "top-right",
              autoClose: 1500,
            });
          }
        });
    }
  };

  // change coding
  const onChange = useCallback((value) => {
    setJavascript(value);
    setCompilerStatus(false);
    setInfinite(false);
  }, []);

  const Closemodal = ({ show }) => {
    // setShow()
    return (
      <Modal
        open={show}
        closable
        title="Warning"
        onOk={() => setShow()}
        onCancel={() => setIscloseModal(false)}
        okText={"Yes"}
        cancelText={"No"}
        maskClosable={false}
      >
        Are you want to close without saving?
      </Modal>
    );
  };
  const Submit = ({ show }) => {
    // setShow()
    return (
      <Modal
        open={show}
        closable
        title="Confirm"
        onOk={() => ReturnMock()}
        onCancel={() => setsubmitmodal(false)}
        okText={"Save"}
        cancelText={"Cancel"}
        maskClosable={false}
        okButtonProps={{
          disabled: loading,
          loading: loading,
        }}
      >
        Do you want to save the progress?
      </Modal>
    );
  };
  const ReturnMock = () => {
    let obj = {
      Mockid: id,
      index: index,
      name: localStorage.getItem("FullName"),
      Overall: overallfeedback,
      Level: {
        Communication: level.communication,
        Theory: level.theory,
        Coding: level.coding,
      },
      answer: Answerdata,
    };
    messageApi.loading({
      type: "loading",
      content: "Saving Feedback...",
      key,
    });
    setLoading(true);
    postrequest(`/questionfeedback/${user?.subdomain}`, obj)
      .then((res) => {
        setLoading(false);
        messageApi.success({
          type: "success",
          content: "Saved successfully",
          key,
        });
        setShow();

        fetcher();
      })
      .catch((err) => {
        messageApi.error({
          type: "error",
          content: "Error while saving the feedback",
          key,
        });
        setLoading(false);
      });
  };

  const TranscribeEditor = (e) => {
    let duplicate = [...Answerdata];
    duplicate[QNO].transcribe = e;
    setAnswerData(duplicate);
    setIschanged(true);
    // setTranscribeupdate(e);
  };

  function defaultvalues(val, ind, d) {
    if (Array.isArray(val)) {
      // let array = JSON.parse(JSON.stringify(val));
      let str = "";

      val.forEach((item, index) => {
        if (index !== 0) {
          str += ","; // Add a comma separator for elements after the first one
        }
        if (Array.isArray(item)) {
          str += "[" + item.join(",") + "]"; // Join array elements with a comma
        } else if (typeof item === "object" && item !== null) {
          str += JSON.stringify(item); // Stringify objects
        } else {
          str += item.toString(); // Convert non-array elements to strings
        }
      });
      return `${d === "in" ? "Input" : "Output"} ${ind + 1} : ` + str + "\n";
    } else {
      return val;
    }
  }

  const FeedbackEditor = (e) => {
    let duplicate = [...Answerdata];
    duplicate[QNO].feedback = e;
    setAnswerData(duplicate);
    setIschanged(true);
    if (duplicate[QNO].Manual !== undefined) {
      duplicate[QNO].Manual = true;
      duplicate[QNO].feedbackby = localStorage.getItem("FullName");
    }
    setIschanged(true);
    // setFeedbackupdate(e);
  };

  const ExplanationEditor = (e) => {
    let duplicate = [...Answerdata];
    duplicate[QNO].explanation = e;
    setAnswerData(duplicate);
    setIschanged(true);
    if (duplicate[QNO].Manual !== undefined) {
      duplicate[QNO].Manual = true;
      duplicate[QNO].feedbackby = localStorage.getItem("FullName");
    }
    setIschanged(true);
    // setFeedbackupdate(e);
  };

  const PassageOption = () => {
    let passage = item[QNO]?.Question?.passageQuestions[item[QNO]?.index - 1];
    let passageAns = item[QNO]?.answer[item[QNO]?.index - 1];

    let options = passage?.options?.map((ele, index) => {
      return (
        <h6
          style={
            passageAns.option === ele && passageAns.answer
              ? { color: "green" }
              : passageAns.option === ele
              ? { color: "red" }
              : { color: "black" }
          }
        >
          {index + 1} : {ele}
        </h6>
      );
    });
    return <>{options}</>;
  };
  // ScreenShots Tabs

  const Screenshots = [
    {
      key: 1,
      label: "Camerashots",
      children:
        item[QNO]?.camRecord.length > 0 ? (
          <div style={{ height: "40vh", overflow: "auto" }}>
            <Image.PreviewGroup
              preview={{ getContainer: () => modalref.current }}
            >
              {item[QNO]?.camRecord.map((item, index) => {
                return (
                  <Image
                    width={170}
                    src={item}
                    preview={true}
                    key={index}
                    style={{ padding: "5px" }}
                  />
                );
              })}
            </Image.PreviewGroup>
          </div>
        ) : (
          <span>No Camerashots Available </span>
        ),
    },
    {
      key: 2,
      label: "Screenshots",
      children:
        item[QNO]?.screenRecord.length > 0 ? (
          <div style={{ height: "40vh", overflow: "auto" }}>
            <Image.PreviewGroup
              preview={{ getContainer: () => modalref.current }}
            >
              {item[QNO]?.screenRecord.map((item, index) => {
                return (
                  <Image
                    width={150}
                    src={item}
                    preview={true}
                    style={{ padding: "5px" }}
                    key={index}
                  ></Image>
                );
              })}
            </Image.PreviewGroup>
          </div>
        ) : (
          <span>No Screenshots available</span>
        ),
    },
  ];

  // Tabs Items
  const items = [
    {
      key: 1,
      label: `index.${item[QNO]?.extension}`,
      children: (
        <Editor
          height="40vh"
          value={code}
          defaultLanguage={item[QNO]?.language ?? "javascript"}
          onChange={(e) => onChange(e)}
          className={styles.editor}
          theme="vs-dark"
        />
      ),
    },
    item[QNO]?.extension === "html" && {
      key: 2,
      label: "styles.css",
      children: (
        <Editor
          height="40vh"
          defaultLanguage={"css"}
          value={csscode.csscode}
          theme="vs-dark"
          onChange={(val) => {
            csscode.csscode = val;
          }}
        />
      ),
    },
    item[QNO]?.extension === "html" && {
      key: 3,
      label: "script.js",
      children: (
        <Editor
          height="40vh"
          defaultLanguage={"javascript"}
          value={csscode.scriptcode}
          theme="vs-dark"
          onChange={(val) => {
            csscode.scriptcode = val;
          }}
          // onMount={handleEditorDidMount}
        />
      ),
    },
    {
      key: 4,
      label: "Example",
      children: (
        <div
          style={{ height: "auto", maxHeight: "40vh", overflow: "auto" }}
          dangerouslySetInnerHTML={{
            __html:
              item[QNO]?.Question?.example ?? item[QNO]?.Question?.question,
          }}
        />
      ),
    },
    {
      key: 5,
      label: "Testcase",
      children: (
        <Paragraph>
          <pre style={{ height: "40vh", overflow: "auto" }}>
            {item[QNO]?.TestCase?.TestName}
            <br />
            <br />
            {item[QNO]?.PreInputs !== "" && <label>Inputs are : </label>}
            <br />
            <label>
              {" "}
              {item[QNO]?.PreInputs === ""
                ? item[QNO]?.TestCase?.BaseCode
                : item[QNO]?.PreInputs?.map((val, ind) => {
                    return defaultvalues(val, ind, "in");
                  })}
            </label>
            <br />
            <br />
            <label>Output should be :-</label>
            <br />
            {item[QNO]?.Outputtocheck === ""
              ? item[QNO]?.TestCase?.Output
              : item[QNO]?.Outputtocheck?.map((val, ind) => {
                  return defaultvalues(val, ind, "out");
                })}
          </pre>
        </Paragraph>
      ),
    },
    activekey === 6 && {
      key: 6,
      label: "Output",
      children: (
        <div
          style={{
            height: "40vh",
            overflow: "auto",
            border: "1px solid",
            borderRadius: "5px",
          }}
        >
          {item[QNO]?.extension !== "html" && (
            <pre style={{ padding: "5px" }}>
              {output &&
                item[QNO]?.Outputtocheck &&
                ((output.stdout &&
                  typeof output.stdout === "string" && // Check if it's a string
                  output.stdout.trim() !== "" && // Check if it's not an empty string
                  (() => {
                    try {
                      let cleanedData = output.stdout.replace(/'/g, '"').trim(); // Replace single quotes with double quotes
                      cleanedData = cleanedData.replace(/\\"/g, '"'); // Remove backslashes before double quotes
                      function isJSONString(str) {
                        try {
                          JSON.parse(str);
                          return true;
                        } catch (error) {
                          return false;
                        }
                      }
                      // Check if the data is valid JSON before parsing
                      if (!isJSONString(cleanedData)) {
                        return <span>{cleanedData}</span>;
                      }

                      const processedData = JSON.parse(
                        cleanedData.replace(/undefined/g, "null")
                      );
                      const parsedData = Array.isArray(processedData)
                        ? processedData
                        : [processedData];

                      return parsedData.map((item, index) => (
                        <React.Fragment key={index}>
                          {Array.isArray(item) ? (
                            item.map((it, ind) => (
                              <React.Fragment key={ind}>
                                <label>{JSON.stringify(it)}</label>
                                <br />
                              </React.Fragment>
                            ))
                          ) : (
                            <label>{JSON.stringify(item)}</label>
                          )}
                          <br />
                        </React.Fragment>
                      ));
                    } catch (error) {
                      console.error("Error parsing JSON:", error);
                      return null; // Handle the error, return null, or an error message
                    }
                  })()) ||
                  output.stderr)}

              {output &&
                !item[QNO]?.Outputtocheck &&
                (output.stdout || output.stderr)}

              {compilestatus && "Running"}
              {Isinfinite && "Infinite Loop"}
            </pre>
          )}
        </div>
      ),
    },
  ];
  const handlePointChange = (e) => {
    const updatedData = [...Answerdata];
    const questionKey = item[QNO]?.index - 1;

    if (updatedData[QNO]?.answer[questionKey]) {
      // Update point value
      updatedData[QNO].answer[questionKey].point = e;

      // Set Manual flag and feedback details
      if (updatedData[QNO].Manual !== undefined) {
        updatedData[QNO].Manual = true;
        updatedData[QNO].feedbackby = localStorage.getItem("FullName");
      }

      // Update state and trigger feedback
      setAnswerData(updatedData);
      setIschanged(true);
      feedback(updatedData);
    }
  };
  const HeadingData = () => {
    return (
      <div
        style={{ borderBottom: "1px solid" }}
        className="d-flex align-items-start"
      >
        <select
          className={styles.selectionquestion}
          onChange={(e) => setQno(Number(e.target.value))}
          value={QNO}
        >
          {item?.map((item, index) => {
            return (
              <option
                key={index}
                value={index}
                style={{
                  color: item?.answer === "don't know" ? "red" : "white",
                }}
              >
                Question {index + 1} :
                {item?.Type === "Question" ? "Theory" : item?.Type}
              </option>
            );
          })}
        </select>
        {/* Question */}
        <div>
          {item[QNO]?.Type === "Passage" ? (
            <label
              style={{ height: "auto", maxHeight: "100px", overflow: "auto" }}
              dangerouslySetInnerHTML={{ __html: item[QNO]?.title }}
            ></label>
          ) : (
            <label
              className="mx-3"
              style={{ height: "auto", maxHeight: "100px", overflow: "auto" }}
              dangerouslySetInnerHTML={{
                __html: item[QNO]?.Question?.question,
              }}
            ></label>
          )}

          <br />
          {item[QNO]?.attachments?.length > 0 && (
            <>
              {" "}
              <label>AttachMents :</label>
              <br />
              <Image.PreviewGroup>
                {item[QNO]?.attachments.map((item, index) => {
                  return <Image src={item} alt="attached" width={100} />;
                })}
              </Image.PreviewGroup>
            </>
          )}
        </div>
      </div>
    );
  };
  return (
    <Modal
      open={true}
      width={"100vw"}
      centered
      onCancel={() => (ischanged ? setIscloseModal(true) : setShow())}
      title={<HeadingData />}
      ref={modalref}
      closable={false}
      onOk={() => {
        setsubmitmodal(true);
      }}
      footer={[
        <Button key="back" onClick={() => setIscloseModal(true)}>
          Close
        </Button>,
        <Button
          key="Prev"
          type="primary"
          onClick={() => {
            setQno(QNO - 1);
          }}
          disabled={QNO === 0}
        >
          {"Prev"}
        </Button>,
        <Button
          key="Next"
          type="primary"
          onClick={() => {
            setQno(QNO + 1);
          }}
          disabled={QNO === item.length - 1}
        >
          Next
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            setsubmitmodal(true);
          }}
        >
          Save
        </Button>,
      ]}
      okText="Save"
      cancelText="Close"
      style={{ overflowY: "auto", height: "100vh" }}
      maskClosable={false}
    >
      <ToastContainer />
      {contextHolder}
      <Closemodal show={isclosemodal} />
      <Submit show={submitmodal} />
      <div>
        {/* Recording and Code Editor */}
        <div className={styles.recordingsmain}>
          {item[QNO]?.Type === "Question" ? (
            <>
              {item[QNO]?.camRecord.length > 0 && (
                <>
                  <div className={styles.screenRecords}>
                    {item[QNO]?.Type !== "Question" ? (
                      <Image.PreviewGroup
                        preview={{ getContainer: () => modalref.current }}
                      >
                        {item[QNO]?.camRecord.map((item, index) => {
                          return (
                            <Image
                              style={{ zIndex: "1000", padding: "5px" }}
                              width={150}
                              preview={true}
                              src={item}
                              key={index}
                            ></Image>
                          );
                        })}
                      </Image.PreviewGroup>
                    ) : (
                      <video
                        src={item[QNO]?.camRecord[0]}
                        controls
                        className={styles.camvideo}
                      />
                    )}
                  </div>
                </>
              )}
              {item[QNO]?.Type === "Question" &&
                item[QNO]?.answer === "don't know" && (
                  <h4 style={{ textAlign: "center" }}>Don't Know</h4>
                )}
              <div className="w-75">
                {/* Transcript */}
                {item[QNO]?.Type === "Question" &&
                  item[QNO]?.screenRecord.length === 0 && (
                    <div className="mx-5 ">
                      <label className={styles.transcriptheading}>
                        Transcribe
                      </label>
                      <br />

                      <Paragraph
                        ellipsis={{
                          rows: 3,
                          expandable: true,
                          symbol: "more",
                        }}
                        editable={{
                          onChange: TranscribeEditor,
                          text: item[QNO]?.transcribe,
                        }}
                        className={styles.transcribe}
                      >
                        {item[QNO]?.transcribe
                          ? item[QNO]?.transcribe
                          : "No Transcript Available"}
                      </Paragraph>
                    </div>
                  )}

                <div className="mx-5 mt-2">
                  {/* Question Wise FeedBack */}
                  <label className={styles.transcriptheading}>Feedback</label>
                  <br />
                  <div className="d-flex align-items-center justify-content-between">
                    <Paragraph
                      className={styles.feedbacktextarea}
                      editable={{
                        onChange: FeedbackEditor,
                        text: Answerdata[QNO].feedback,
                      }}
                      ellipsis={{
                        rows: 2,
                        expandable: true,
                        symbol: "more",
                      }}
                    >
                      {Answerdata[QNO].feedback}
                    </Paragraph>
                    <div>
                      <label className={styles.point}>Point :</label>
                      <InputNumber
                        value={Answerdata[QNO].point}
                        className={styles.pointinput}
                        onChange={(e) => {
                          let mockdata = [...Answerdata];

                          mockdata[QNO].point = e;
                          if (mockdata[QNO].Manual !== undefined) {
                            mockdata[QNO].Manual = true;
                            mockdata[QNO].feedbackby =
                              localStorage.getItem("FullName");
                          }
                          setAnswerData(mockdata);

                          setIschanged(true);
                          feedback(mockdata);
                        }}
                        min={0}
                        max={Answerdata[QNO].mainpoints}
                        addonAfter={Answerdata[QNO].mainpoints}
                        controls={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {item[QNO]?.screenRecord.length > 0 && (
                <div className={styles.screenRecords}>
                  <Image.PreviewGroup
                    preview={{ getContainer: () => modalref.current }}
                  >
                    {item[QNO]?.screenRecord.map((item, index) => {
                      return (
                        <Image
                          width={150}
                          preview={true}
                          src={item}
                          key={index}
                        ></Image>
                      );
                    })}
                  </Image.PreviewGroup>
                </div>
              )}
            </>
          ) : (
            <>
              <div style={{ width: "55%" }}>
                {/* MCQ */}
                {item[QNO]?.Type === "Mcq" ? (
                  <>
                    <div className={styles.mcqcontainer}>
                      <label
                        style={{
                          color:
                            (item[QNO]?.Options.opt1 === item[QNO]?.answer &&
                            item[QNO].point > 0
                              ? "green"
                              : "") ||
                            (item[QNO]?.Options.opt1 === item[QNO]?.answer &&
                            //eslint-disable-next-line
                            item[QNO]?.point == 0
                              ? "red"
                              : ""),
                        }}
                      >
                        1. {item[QNO].Options.opt1}
                      </label>
                      <label
                        style={{
                          color:
                            (item[QNO].Options.opt2 === item[QNO].answer &&
                            item[QNO].point > 0
                              ? "green"
                              : "") ||
                            (item[QNO].Options.opt2 === item[QNO].answer &&
                            //eslint-disable-next-line
                            item[QNO].point == 0
                              ? "red"
                              : ""),
                        }}
                      >
                        2. {item[QNO].Options.opt2}
                      </label>
                      <label
                        style={{
                          color:
                            (item[QNO].Options.opt3 === item[QNO].answer &&
                            item[QNO].point > 0
                              ? "green"
                              : "") ||
                            (item[QNO].Options.opt3 === item[QNO].answer &&
                            //eslint-disable-next-line
                            item[QNO].point == 0
                              ? "red"
                              : ""),
                        }}
                      >
                        3. {item[QNO].Options.opt3}
                      </label>
                      <label
                        style={{
                          color:
                            (item[QNO].Options.opt4 === item[QNO].answer &&
                            item[QNO].point > 0
                              ? "green"
                              : "") ||
                            (item[QNO].Options.opt4 === item[QNO].answer &&
                            //eslint-disable-next-line
                            item[QNO].point == 0
                              ? "red"
                              : ""),
                        }}
                      >
                        4. {item[QNO].Options.opt4}
                      </label>
                    </div>
                    <div className="mx-5 mt-2">
                      {/* Question Wise FeedBack */}
                      <label className={styles.transcriptheading}>
                        Feedback
                      </label>
                      <br />
                      <div className="d-flex align-items-center justify-content-between">
                        <Paragraph
                          className={styles.feedbacktextarea}
                          editable={{
                            onChange: FeedbackEditor,
                            text: Answerdata[QNO].feedback,
                          }}
                          ellipsis={{
                            rows: 2,
                            expandable: true,
                            symbol: "more",
                          }}
                        >
                          {Answerdata[QNO].feedback}
                        </Paragraph>
                        <div>
                          <label className={styles.point}>Point :</label>
                          <InputNumber
                            value={Answerdata[QNO].point}
                            className={styles.pointinput}
                            onChange={(e) => {
                              let mockdata = [...Answerdata];

                              mockdata[QNO].point = e;
                              if (mockdata[QNO].Manual !== undefined) {
                                mockdata[QNO].Manual = true;
                                mockdata[QNO].feedbackby =
                                  localStorage.getItem("FullName");
                              }
                              setAnswerData(mockdata);

                              setIschanged(true);
                              feedback(mockdata);
                            }}
                            min={0}
                            max={Answerdata[QNO].mainpoints}
                            addonAfter={Answerdata[QNO].mainpoints}
                            controls={false}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  item[QNO]?.Type === "Coding" &&
                  item[QNO].answer !== "don't know" && (
                    <Tabs
                      defaultActiveKey={activekey}
                      items={items}
                      style={{ backgroundColor: "white", height: "350px" }}
                      activeKey={activekey}
                      onChange={(val) => setActiveKey(val)}
                      tabBarExtraContent={
                        <>
                          {/* Output */}
                          {item[QNO].Type === "Coding" &&
                            item[QNO].answer !== "don't know" && (
                              <span>
                                {item[QNO]?.extension !== "html" ? (
                                  testpass ? (
                                    <label style={{ color: "green" }}>
                                      Test Pass
                                    </label>
                                  ) : (
                                    <label style={{ color: "red" }}>
                                      Test Fail
                                    </label>
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            )}
                          <Button
                            onClick={() => {
                              if (item[QNO]?.extension === "html") {
                                HTML_Code();
                              } else {
                                RunCode();
                              }
                            }}
                            size="medium"
                            className={styles.runbtn}
                          >
                            {item[QNO]?.extension === "html"
                              ? "Click To View"
                              : "Run"}
                          </Button>
                        </>
                      }
                    />
                  )
                )}
              </div>

              {item[QNO]?.Type === "Coding" &&
                item[QNO].answer !== "don't know" &&
                (item[QNO]?.Settings?.capture_user ?? true) &&
                (item[QNO]?.Settings?.capture_screen ?? true) && (
                  <div style={{ height: "400px", width: "40%" }}>
                    <Tabs items={Screenshots} defaultActiveKey={1} />
                  </div>
                )}
            </>
          )}
        </div>

        <div>
          {/* Transcript */}
          {item[QNO]?.Type === "Question" &&
            item[QNO]?.screenRecord.length > 0 && (
              <div className="mx-5 mt-4">
                <label className={styles.transcriptheading}>Transcribe</label>
                <br />

                <Paragraph
                  ellipsis={{ rows: 3, expandable: true, symbol: "more" }}
                  editable={{
                    onChange: TranscribeEditor,
                    text: item[QNO].transcribe,
                  }}
                  className={styles.transcribe}
                >
                  {item[QNO].transcribe
                    ? item[QNO].transcribe
                    : "No Transcript Available"}
                </Paragraph>
              </div>
            )}
        </div>

        {item[QNO]?.Type === "Coding" && (
          <div className={styles.feedback_point}>
            {/* Question Wise FeedBack */}

            <div>
              <label className={styles.transcriptheading}>Feedback</label>
              <br />
              <Paragraph
                className={styles.feedbacktextarea}
                editable={{
                  onChange: FeedbackEditor,
                  text: Answerdata[QNO].feedback,
                }}
                ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
              >
                {Answerdata[QNO].feedback}
              </Paragraph>
            </div>
            {Answerdata[QNO].Type === "Coding" && (
              <div className="mx-2">
                <label className={styles.transcriptheading}>Explanation</label>
                <br />
                <Paragraph
                  className={styles.feedbacktextarea}
                  editable={{
                    onChange: ExplanationEditor,
                    text: Answerdata[QNO].explanation,
                  }}
                  ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
                >
                  {Answerdata[QNO].explanation}
                </Paragraph>
              </div>
            )}
            <div>
              <label className={styles.point}>Point :</label>
              <InputNumber
                value={Answerdata[QNO].point}
                className={styles.pointinput}
                onChange={(e) => {
                  let mockdata = [...Answerdata];

                  mockdata[QNO].point = e;
                  if (mockdata[QNO].Manual !== undefined) {
                    mockdata[QNO].Manual = true;
                    mockdata[QNO].feedbackby = localStorage.getItem("FullName");
                  }
                  setAnswerData(mockdata);

                  setIschanged(true);
                  feedback(mockdata);
                }}
                min={0}
                max={Answerdata[QNO].mainpoints}
                addonAfter={Answerdata[QNO].mainpoints}
                controls={false}
              />
            </div>
          </div>
        )}
        {item[QNO]?.Type === "Passage" && (
          <>
            <div className={styles.passageQues}>
              <div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: item[QNO]?.Question?.question,
                  }}
                />
              </div>
              <section>
                <PassageOption />
              </section>
            </div>
            {item[QNO]?.Type === "Passage" && (
              <>
                <div className="mx-5 mt-2">
                  {/* Question Wise FeedBack */}
                  <label className={styles.transcriptheading}>Feedback</label>
                  <br />
                  <div className="d-flex align-items-center justify-content-between">
                    <Paragraph
                      className={styles.feedbacktextarea}
                      editable={{
                        onChange: FeedbackEditor,
                        text: Answerdata[QNO].feedback,
                      }}
                      ellipsis={{
                        rows: 2,
                        expandable: true,
                        symbol: "more",
                      }}
                    >
                      {Answerdata[QNO].feedback}
                    </Paragraph>
                    <div>
                      <label className={styles.point}>Point :</label>
                      <InputNumber
                        value={
                          item[QNO]?.answer[item[QNO]?.index - 1]?.point || 0
                        }
                        className={styles.pointinput}
                        onChange={handlePointChange}
                        min={0}
                        max={
                          item[QNO]?.Question?.passageQuestions[
                            item[QNO]?.index - 1
                          ]?.point || 0
                        }
                        addonAfter={
                          item[QNO]?.Question?.passageQuestions[
                            item[QNO]?.index - 1
                          ]?.point || 0
                        }
                        controls={false}
                      />
                    </div>
                  </div>
                </div>

                {item[QNO]?.screenRecord.length > 0 && (
                  <div className={styles.screenRecords}>
                    <Image.PreviewGroup
                      preview={{ getContainer: () => modalref.current }}
                    >
                      {item[QNO]?.screenRecord.map((item, index) => {
                        return (
                          <Image
                            width={150}
                            preview={true}
                            src={item}
                            key={index}
                          ></Image>
                        );
                      })}
                    </Image.PreviewGroup>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
      <Modal
        open={openmodal}
        centered
        title="Webview"
        onCancel={() => setOpenModal(false)}
        width={"90vw"}
        footer={null}
      >
        <iframe title="HTML" srcDoc={output} style={{ height: "80vh" }} />
      </Modal>
    </Modal>
  );
};

export default QuestionReview;
