import React from "react";
import check from "../../icons/check.webp";
import { useNavigate } from "react-router-dom";
const Subscriptionthanks = () => {
  const navigate = useNavigate();
  return (
    <center>
      {" "}
      <div className="mt-5 pt-5">
        <img src={check} alt="subscribed" width="100" height="auto" />
        <br />
        <br />
        <h3>Thank You!</h3>
        <label className="mx-5">
          <b>We are delighted to welcome you to the Unoprep platform.</b>
          <br />
          You will receive a confirmatory email within 24 hours once your
          account has been activated.
          <br />
          <br />
          Contact us on
          <a href="mailto:contact@unoprep.com">contact@unoprep.com</a>
          <br />
          <br />
          <u onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
            Home
          </u>
        </label>
      </div>
    </center>
  );
};

export default Subscriptionthanks;
