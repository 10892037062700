import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import React, { useState, useRef, useEffect } from "react";
import { Drawer } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { UnlockOutlined, UserOutlined } from "@ant-design/icons";
import {
  setuser,
  setAdmin,
  setIslogin,
  setIssubscribed,
  setIsSuperAdmin,
} from "../../store/Reducer";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import home from "../../icons/home.svg";
import create from "../../icons/create.svg";
import group from "../../icons/people.svg";
import { setLoginModalVisibility } from "../../store/ToolFlagsSlice";
import questions from "../../icons/question-and-answer.svg";
// import Avatar from "react-avatar";
import { toast, ToastContainer } from "react-toastify";
import "../../styles/navbar.css";
import logo from "../../icons/logounoprep.png";
import Button from "react-bootstrap/esm/Button";
import { Avatar } from "antd";
import Cookies from "js-cookie";
import LoginModal from "../HomeComponents/LoginModal";
const paths = [
  "/organization",
  "/signup",
  "/signup/:subdomain",
  "/personal",
  "/forget",
  "/user",
  "/practice/:qid/:gid",
  "/admin",
  "/managegroups",
  "/questions",
  "/createmock",
  "/assignments",
  "/manageusers",
  "/profile",
  "/course",
  "/createcourse",
  "/createcurriculum",
  "/settings",
  "/viewcourse",
  "/testseries",
  "/subscribed",
  "/predefinetest",
  "/logs",
];
function NavBar() {
  const location = useLocation();
  const [openmenu, setOpenMenu] = useState(false);
  const [openDwa, setOpenDwa] = useState(false);
  const [params, setParams] = useState({});
  const [searchParams] = useSearchParams();
  const { id, ref, client_id } = params;
  const { Data, StudentProfile } = useSelector((state) => state);
  const { user, admin, isgivingmock, clientdetails } = Data;
  const { imagesurl } = StudentProfile;

  useEffect(() => {
    let params = {};
    for (const key of searchParams) {
      key[0].split("&").forEach((item) => {
        params[item.split("=")[0]] = item.split("=")[1];
      });
    }
    setParams(params);
  }, [searchParams]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hometour = useRef(null);
  const createmock = useRef(null);
  const question = useRef(null);

  const signout = () => {
    let obj = null,
      isUsers = false,
      isGroup = false,
      ClientName = "Digikull",
      isSubscribed = false,
      MockCount = 0;
    dispatch(setuser(obj));
    dispatch(setAdmin(false));
    dispatch(setIslogin(false));
    dispatch(setIsSuperAdmin(null));
    localStorage.removeItem("FullName");
    localStorage.removeItem("my_profile_image");
    dispatch(
      setIssubscribed({ isUsers, isGroup, ClientName, isSubscribed, MockCount })
    );
    navigate("/");
    process.env.REACT_APP_ENVIRONMENT === "local"
      ? Cookies.remove("digikull_local")
      : Cookies.remove("unoprep", { domain: ".unoprep.com" });
    sessionStorage.clear();
  };

  const activetab = (val) => {
    if (location.pathname === val) return "active";
    else return "notactive";
  };

  return (
    <>
      {!paths.includes(location.pathname) &&
        location.pathname.split("/").includes("MyMock") === false &&
        location.pathname.split("/").includes("mockdetails") === false &&
        location.pathname.split("/").includes("createmock") === false &&
        location.pathname.split("/").includes("group") === false &&
        location.pathname.split("/").includes("access") === false &&
        location.pathname.split("/").includes("instructions") === false &&
        location.pathname.split("/").includes("review_mock") === false &&
        location.pathname.split("/").includes("practice") === false &&
        location.pathname.split("/").includes("login") === false && (
          <Navbar collapseOnSelect expand="lg" className="mynavbar">
            <ToastContainer />
            <Navbar.Brand className="d-flex" href="/" disabled={isgivingmock}>
              <img
                src={
                  Cookies.get("unoprep") === undefined &&
                  Cookies.get("digikull_local") === undefined
                    ? logo
                    : clientdetails.logo
                }
                alt="Logo"
                style={{ width: "auto", height: "65px", objectFit: "contain" }}
                id="company_logo"
              />
              {/* <h3 className="company_title mx-2">{clientdetails?.companyname}</h3> */}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>

              <div>
                {Cookies.get("unoprep") === undefined &&
                Cookies.get("digikull_local") === undefined ? (
                  <div className="d-flex flex-row justify-content-end align-items-center mobileNav">
                    <Button
                      className="btn_navbar"
                      onClick={() => {
                        dispatch(setLoginModalVisibility(true));
                      }}
                      style={{
                        marginRight: "40px",
                        backgroundColor: "#228fda",
                      }}
                    >
                      Log In
                    </Button>
                    <Button
                      variant="warning"
                      onClick={() => {
                        navigate("/signup", {
                          state: {
                            id: id,
                            client_id: client_id,
                            external: ref ? true : false,
                          },
                        });
                        dispatch(setLoginModalVisibility(false));
                      }}
                    >
                      Sign Up
                    </Button>
                  </div>
                ) : admin === false || admin === "false" ? (
                  <>
                    <Avatar
                      src={imagesurl ?? ""}
                      className="profile"
                      onClick={() => {
                        setOpenMenu(!openmenu);
                        setOpenDwa(true);
                      }}
                      icon={<UserOutlined />}
                      // size={"large"}

                      size={{
                        xs: 25,
                        sm: 25,
                        md: 35,
                        lg: 35,
                        xl: 40,
                        xxl: 50,
                      }}
                    >
                      {user?.FullName}
                    </Avatar>
                    <Drawer
                      title="Your Profile"
                      placement={window.innerWidth < 550 ? "left" : "right"}
                      style={{ backdropFilter: "blur(10px)" }}
                      onClose={() => setOpenDwa(false)}
                      open={openDwa}
                      closeIcon={true}
                      width={window.innerWidth < 550 ? "65%" : 400}
                    >
                      <div className="userProfile">
                        <Avatar
                          src={imagesurl ?? ""}
                          className="profile"
                          onClick={() => {
                            setOpenMenu(!openmenu);
                            setOpenDwa(true);
                          }}
                          icon={<UserOutlined />}
                          // size={"large"}
                          shape="circle"
                          size={{
                            xs: 30,
                            sm: 40,
                            md: 45,
                            lg: 50,
                            xl: 60,
                            xxl: 70,
                          }}
                        >
                          {user?.FullName}
                        </Avatar>
                        <div>
                          <label>
                            {user?.FullName}
                            <span>{user?.Email}</span>
                          </label>
                        </div>
                      </div>
                      <main
                        onClick={() => {
                          signout();
                          setOpenDwa(false);
                        }}
                        className="signoutbtn"
                      >
                        <span>
                          <UnlockOutlined />
                        </span>

                        <label>Sign out</label>
                      </main>
                    </Drawer>
                  </>
                ) : (
                  ""
                )}
              </div>

              {(Cookies.get("unoprep") !== undefined ||
                Cookies.get("digikull_local") !== undefined) &&
                (admin === true || admin === "true") && (
                  <Nav
                    className="d-flex justify-content-evenly"
                    style={{ width: "70%" }}
                  >
                    <Nav.Link
                      className="d-flex align-items-center nav_menu"
                      onClick={() => {
                        navigate("/");
                      }}
                      title="Home"
                      id={activetab("/admin")}
                      ref={hometour}
                    >
                      <img src={home} alt="Course" className="nav_img mx-1" />
                      <label className="nav_title">Home</label>
                    </Nav.Link>
                    <Nav.Link
                      className="d-flex align-items-center nav_menu"
                      onClick={() => {
                        if (!clientdetails?.issubscribed) {
                          toast.warning("Subscription Expired", {
                            position: "top-right",
                            autoClose: 1500,
                          });
                        } else {
                          navigate("/createmock");
                        }
                      }}
                      title="Create New Mock"
                      id={activetab("/createmock")}
                      ref={createmock}
                    >
                      <img src={create} alt="Course" className="nav_img mx-1" />
                      <label className="nav_title">Create Test</label>
                    </Nav.Link>
                    <Nav.Link
                      className="d-flex align-items-center nav_menu"
                      onClick={() => {
                        navigate("/questions");
                      }}
                      title="Create and Manage Questions"
                      id={activetab("/questions")}
                      ref={question}
                    >
                      <img
                        src={questions}
                        alt="Course"
                        className="nav_img mx-1"
                      />
                      <label className="nav_title">Questions</label>
                    </Nav.Link>

                    {(clientdetails?.isgroup ?? false) && (
                      <Nav.Link
                        className="d-flex align-items-center nav_menu"
                        onClick={() => {
                          navigate("/managegroups");
                        }}
                        title="Create and Manage Batches"
                        id={activetab("/managegroups")}
                      >
                        <img
                          src={group}
                          alt="Course"
                          className="nav_img mx-1"
                        />
                        <label className="nav_title">Batches</label>
                      </Nav.Link>
                    )}
                    <>
                      <Avatar
                        className="profile"
                        disabled={isgivingmock}
                        name={user?.FullName}
                        size="40px"
                        round="50%"
                        color="black"
                        onClick={() => {
                          setOpenDwa(true);
                        }}
                      />
                      <Drawer
                        title="Your Profile"
                        placement={window.innerWidth < 550 ? "left" : "right"}
                        style={{ backdropFilter: "blur(10px)" }}
                        onClose={() => setOpenDwa(false)}
                        open={openDwa}
                        closeIcon={true}
                        width={window.innerWidth < 550 ? "65%" : 400}
                      >
                        <div>
                          <div className="userDetails">
                            <span>{user?.FullName}</span>
                            <span>{user?.Email}</span>
                          </div>
                          <span className="profile_menu">
                            {user?.subdomain === "main" && admin && (
                              <label
                                className="menu"
                                onClick={() => {
                                  navigate("/organizations");
                                  setOpenDwa(false);
                                }}
                              >
                                Organizations
                              </label>
                            )}

                            <>
                              <label
                                className="menu"
                                onClick={() => {
                                  navigate("/manageusers");
                                  setOpenDwa(false);
                                }}
                              >
                                Manage Users
                              </label>{" "}
                            </>

                            <label
                              id="logout"
                              className="menu"
                              onClick={() => {
                                signout();
                                setOpenDwa(false);
                              }}
                            >
                              Logout
                            </label>
                          </span>
                        </div>
                      </Drawer>
                    </>
                  </Nav>
                )}
            </Navbar.Collapse>
          </Navbar>
        )}

      <LoginModal />
    </>
  );
}

export default NavBar;
