import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/esm/Spinner";
import NavHead from "./Navbar";
import Warning from "./Warning";
import Introduction from "./Introduction";
import Question from "./Question";
import ThankYou from "./ThankYou";
import { getrequest } from "../../Middleware/managerequest";
import IsMockStarted from "./IsMockStarted";
import Isexpiredmock from "./Isexpiredmock";
import NewInstruction from "./NewInstruction";
import PracticeInstruction from "../../Practice_Mode/PracticeInstruction";
import OMRUpload from "./OMRUpload";

const Main = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const { user } = useSelector((state) => state.Data);
  const navigate = useNavigate();
  const [mockData, setMockData] = useState(null);
  const [loader, setLoader] = useState(true);
  const [Settings, setSettings] = useState("");
  const [Extra, setExtra] = useState([]);
  const [instructions, setInstructions] = useState(false);
  const [checking, setChecking] = useState(false);
  const [introduction, setIntroduction] = useState(false);
  const [OMRBasedTest, setOMRBasedTest] = useState(false);
  const [mockStart, setMockStart] = useState(false);
  const [isAlreadyGiven, setIsAlreadyGiven] = useState(false);
  const [thankYou, setThankYou] = useState(false);
  const [notstarted, setIsnotstarted] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [mockTitle, setMockTitle] = useState("");
  const [testDuration, setTestDuration] = useState("");
  const [Mockdate, setMockdate] = useState("");
  const [MockDetails, setMockDetails] = useState([]);

  useEffect(() => {
    if (mockData === null && user) {
      let subdomain = user?.subdomain;
      let Email = user?.Email;
      let FullName = user?.FullName;
      getrequest(
        `/user/MyMock/${id}/${subdomain}/${Email}/?username=${FullName}&external=${state?.external}`
      )
        .then((res) => {
          const {
            finaldata,
            isgivemock,
            isnotstarted,
            isexpired,
            Mockdate,
            Settings,
            mockdetails,
            unautherized,
            title,
            duration,
            Extra,
          } = res.data;
          if (unautherized) {
            navigate("/*");
          }
          setExtra(Extra);
          setMockDetails(mockdetails);
          setMockData(finaldata);
          setIsnotstarted(isnotstarted);
          setIsExpired(isexpired);
          setMockTitle(title);
          setTestDuration(duration);
          setMockdate(Mockdate);
          setIsAlreadyGiven(isgivemock);
          setSettings(Settings);
          if (finaldata) {
            setInstructions(true);
          }

          setLoader(false);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            navigate("/*");
          } else {
            toast.error("Internal Server Error", {
              position: "top-center",
              autoClose: 1500,
            });
          }
        });
    }
  }, [id, mockData, navigate, state, user]);

  if (loader) {
    return (
      <div className="d-flex justify-content-center align-items-end h-50 mt-5 pt-5">
        <Spinner
          animation="border"
          size="lg"
          variant="warning"
          className="spinner"
        />
        <ToastContainer />
      </div>
    );
  }

  return (
    <div>
      {!instructions && <NavHead />}
      {notstarted && <IsMockStarted Mockdate={Mockdate} />}
      {isExpired && <Isexpiredmock Mockdate={Mockdate} />}
      {isAlreadyGiven && <Warning Settings={Settings} />}
      {instructions && (
        <NewInstruction
          mockdetails={MockDetails}
          instructions={setInstructions}
          external={state?.external ?? false}
          setmockstart={setMockStart}
          checking={setChecking}
          Settings={Settings}
          mockData={mockData}
          mockTitle={mockTitle}
          duration={testDuration}
          setIntroduction={setIntroduction}
          setOMRBasedTest={setOMRBasedTest}
          Extra={Extra}
        />
      )}
      {checking && (
        <PracticeInstruction
          setIntroduction={setIntroduction}
          setmockstart={setMockStart}
          checking={setChecking}
          Settings={Settings}
        />
      )}
      {introduction && (
        <Introduction
          setIntroduction={setIntroduction}
          setmockstart={setMockStart}
        />
      )}
      {mockStart && (
        <Question
          mock={mockData}
          setThankyou={setThankYou}
          setmockstart={setMockStart}
          Settings={Settings}
          duration={testDuration}
        />
      )}
      {OMRBasedTest && <OMRUpload Extra={Extra} />}
      {thankYou && <ThankYou Settings={Settings} />}
    </div>
  );
};

export default Main;
