import React, { useState, useRef, useEffect, useCallback } from "react";

import { Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./createquestion.module.css";
import {
  LoadingOutlined,
  UploadOutlined,
  SyncOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";

import { toast, ToastContainer } from "react-toastify";
import {
  AutoComplete,
  Button,
  Input,
  InputNumber,
  Modal,
  Select,
  Tag,
  Tooltip,
  Upload,
  message,
} from "antd";
import DashboardSlider from "../../Navigator_Footer/DashboardSlider";
import ProfileSlider from "../../Navigator_Footer/ProfileSlider";

import { postrequest } from "../../Middleware/managerequest";
import LatestNavbar from "../../Navigator_Footer/LatestNavbar";
import Sider from "antd/es/layout/Sider";
const QuestionWiseTime = {
  Question: 300,
  Mcq: 180,
  Coding: 1800,
};

const minutesMapping = {
  Coding: {
    Easy: 600,
    Medium: 900,
    Hard: 1200,
  },
  Subjective: {
    Easy: 50,
    Medium: 60,
    Hard: 100,
  },
  Mcq: {
    Easy: 50,
    Medium: 70,
    Hard: 110,
  },
  Passage: {
    Easy: 3000,
    Medium: 6000,
    Hard: 10000,
  },
};

const CreateQuestion = ({
  fetcher,
  MainTopics,
  setFlag,
  user,
  list,
  Topics,
  access,
  editdata,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [options, setOptions] = useState([]);
  const [openDwa, setOpenDwa] = useState(false);
  const [searching, setSearching] = useState(false);
  const [editorHtml, setEditorHtml] = useState(
    editdata ? editdata?.qstn?.question : ""
  );
  const [qstn, setQstn] = useState(editdata ? editdata?.qstn?.question : "");
  const [level, setLevel] = useState(editdata ? editdata.level : "Easy");
  const [type, setType] = useState(
    editdata
      ? editdata.type === "Question"
        ? "Subjective"
        : editdata.type
      : "Subjective"
  );
  const [selectedsubTopic, setSelectedSubTopics] = useState(
    editdata && editdata?.concept ? editdata?.concept?.map((ele) => ele) : []
  );
  const [MainTags, setMainTags] = useState(
    editdata && editdata?.maintopics
      ? editdata?.maintopics?.map((ele) => ({ value: ele, lebel: ele }))
      : "Select Topic"
  );
  const [Points, setPoints] = useState(editdata ? editdata.point : 0);

  const [time, setTime] = useState(editdata ? editdata.time : 0);
  const [selectedFiles, setSelectedFiles] = useState(
    editdata && editdata?.attachments ? editdata?.attachments : []
  );
  const [title, setTitle] = useState(editdata ? editdata.title : "");
  const [issendrequest, setIssendrequest] = useState(false);
  const [passageQuestions, setPassageQuestions] = useState(
    editdata && editdata?.qstn?.passageQuestions
      ? editdata?.qstn?.passageQuestions
      : []
  );
  const [Options, setOption] = useState(
    editdata && editdata.type === "Mcq"
      ? {
          option1: editdata.opt1,
          option2: editdata.opt2,
          option3: editdata.opt3,
          option4: editdata.opt4,
        }
      : {
          option1: "",
          option2: "",
          option3: "",
          option4: "",
        }
  );
  // MCQ Answer
  const [answer, setAnswer] = useState(
    editdata && editdata.type === "Mcq" ? editdata.ans : ""
  );

  useEffect(() => {
    setTime(minutesMapping[type][level]);
  }, [type, level]);

  // MCQ Answer
  const setAnswers = (e) => {
    console.log(e.target.value);
    setAnswer(e.target.value);
  };
  // Handle Options
  const Onchange = (e) => {
    let value = { ...Options, [e.target.name]: e.target.value };
    setOption(value);
  };

  const editorRef = useRef(null);

  const handleEditorChange = (html) => {
    setEditorHtml(html);
    setQstn({ ...qstn, question: html });
  };

  // Handle Image Change
  useEffect(() => {
    const editor = editorRef.current;
    if (!editor || !editor.editor || !editor.editor.root) return;
    const editorRoot = editor.editor.root;
    const handleImageInserted = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
          const images = editorRoot.querySelectorAll("img");

          images.forEach((image) => {
            image.setAttribute("width", "150"); // Default width
            image.setAttribute("height", "100"); // Default height
          });
        }
      });
    };

    const observer = new MutationObserver(handleImageInserted);
    observer.observe(editorRoot, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  // Editor Modules
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  // Editor Formats
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const parseHTMLText = (htmlString) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText || "";
  };

  // Handle Question Search
  const handleSearch = (value) => {
    setSearching(true);
    let arr = list
      .filter(
        (item) =>
          item?.title?.toString().toLowerCase().includes(value.toLowerCase()) ||
          parseHTMLText(item.qstn?.question)
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
      )
      .map((item, index) => ({
        value: index.toString(),
        label: parseHTMLText(
          (item.title?.toString().toLowerCase().includes(value.toLowerCase()) &&
            item.title) ||
            (item.qstn?.question
              ?.toString()
              .toLowerCase()
              .includes(value.toLowerCase()) &&
              item.qstn?.question)
        ),
        disabled: true,
        style: { color: "black" },
      }));

    setOptions(arr);
    setSearching(false);
  };

  // Upload Attachments
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
  };

  // Convert Files to Blob
  function base64ToBlob(base64Content) {
    const byteCharacters = atob(base64Content);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: "image/jpeg" }); // Specify the MIME type accordingly
  }

  // Warning
  const notificationmessage = useCallback(
    (val, err) => {
      messageApi.open({
        type: "warning",
        content: val,
      });
    },
    [messageApi]
  );
  // Validate array of objects
  const validateQuestions = (questions) => {
    return questions.every((question) => {
      // Check if description, answer, and level are non-empty
      const isDescriptionValid = question.description.trim() !== "";
      const isAnswerValid = question.answer.trim() !== "";

      const isPointValid = question.point !== 0;
      const isLevelValid = question.level !== "";
      const areOptionsValid = question.options.every(
        (option) => option.trim() !== ""
      );
      if (!isDescriptionValid) {
        message.warning("Enter sub Question");
      } else if (!isAnswerValid) {
        message.warning("Please mark the correct answer");
      } else if (!isPointValid) {
        message.warning("Please enter valid sub question mark");
      } else if (!isLevelValid) {
        message.warning("Please select difficulty lavel");
      } else if (!areOptionsValid) {
        message.warning("Please fill all option");
      }
      return (
        isDescriptionValid &&
        isAnswerValid &&
        isLevelValid &&
        areOptionsValid &&
        isPointValid
      );
    });
  };

  //Final Submit
  const submit = () => {
    const formData = new FormData();
    const parser = new DOMParser();
    const doc = parser.parseFromString(qstn.question, "text/html");

    const imgElement = doc.querySelectorAll("img");
    let images = [...selectedFiles];
    if (imgElement.length > 0) {
      imgElement.forEach((item, ind) => {
        const srcData = item.getAttribute("src");
        const blob1 = base64ToBlob(srcData.split(",")[1]);

        const file = new File([blob1], `questioneditor-${ind}.jpeg`, {
          type: "image/jpeg",
        });

        images.push(file);
      });
    }

    imgElement.forEach((img) => {
      img.removeAttribute("src");
    });

    const updatedHTML = doc.documentElement.outerHTML;
    // Append each selected file to the FormData object
    images?.forEach((file, index) => {
      formData.append(`questionimg`, file);
    });

    let arrValuesInTopics = Topics.map((obj) => obj.value);
    let NewValues = selectedsubTopic.filter(
      (value) => !arrValuesInTopics.includes(value)
    );
    const arr = NewValues.map((item) => ({ value: item, label: item }));
    const isAnyOptionEmpty = Object.values(Options).some(
      (option) => option === ""
    );

    const inputValidation = () => {
      if (title === "" && qstn === "") {
        notificationmessage("Please enter the title or Description");
        return false;
      } else if (MainTags === "Select Topic") {
        notificationmessage("Please select Main Topic");
        return false;
      } else if (selectedsubTopic.length === 0) {
        notificationmessage("Please select at least one Sub Tag");
        return false;
      } else if (type === "Mcq" && (isAnyOptionEmpty || answer === "")) {
        notificationmessage(
          "Please enter all options and select right answer."
        );
        return false;
      }
      return true;
    };

    const typeConfig = {
      Mcq: {
        type: "Mcq",
        point:
          Points > 0
            ? Points
            : level === "Easy"
            ? 1
            : level === "Medium"
            ? 2
            : 3,
        options: [
          Options.option1,
          Options.option2,
          Options.option3,
          Options.option4,
        ],
      },
      Coding: {
        type: "Coding",
        point:
          Points > 0
            ? Points
            : level === "Easy"
            ? 3
            : level === "Medium"
            ? 4
            : 5,
      },
      Subjective: {
        type: "Question",
        point:
          Points > 0
            ? Points
            : level === "Easy"
            ? 2
            : level === "Medium"
            ? 3
            : 4,
      },
      Passage: {
        type: "Passage",
        point:
          Points > 0
            ? Points
            : level === "Easy"
            ? 5
            : level === "Medium"
            ? 10
            : 15,
      },
    };

    if (!inputValidation()) return;

    const config = typeConfig[type];

    if (config) {
      const obj = {
        newtopics: arr,
        details: {
          ...(editdata ? { qid: editdata.qid } : { qid: uuidv4() }),

          type: config.type,
          qstn: {
            // ...qstn,
            question:
              parseHTMLText(updatedHTML) === "undefined" ? "" : updatedHTML,

            ...(type === "Passage" && {
              passageQuestions,
            }),
          },
          point: config.point,
          level: level,
          title: title !== "" ? title : undefined,
          concept: selectedsubTopic,
          time: time,
          attachments: [],
          modelans: [],
          createdby: `${user?.FullName} on ${new Date()}`,
          maintopics: editdata
            ? MainTags[0]?.value
              ? [MainTags[0]?.value]
              : [MainTags]
            : [MainTags],
          ...(config.options && {
            opt1: config.options[0],
            opt2: config.options[1],
            opt3: config.options[2],
            opt4: config.options[3],
            ans: answer,
          }),
          ...(editdata && {
            lastupdateby: `${user?.FullName} on ${new Date()}`,
            createdby: editdata?.createdby ?? "",
          }),
        },
      };
      formData.append("questiondata", JSON.stringify(obj));
      if (type === "Passage" && passageQuestions.length < 2) {
        toast.warning("Add 2 Question Minimum", {
          position: "top-right",
          autoClose: 1500,
        });
        return false;
      } else if (!validateQuestions(passageQuestions)) {
        return false;
      }
      setIssendrequest(true);
      postrequest(
        `/home/${editdata ? "edit" : "questions"}/${user?.subdomain}`,
        formData
      )
        .then((res) => {
          toast.success("Successfully Added", {
            position: "top-center",
            autoClose: 1500,
          });
          setIssendrequest(false);
          setSelectedSubTopics([]);
          setQstn("");
          setLevel("Easy");
          setType("Subjective");
          fetcher();
          setFlag();
        })
        .catch((err) => {
          setIssendrequest(false);
          toast.warning("server error", {
            position: "top-right",
            autoClose: 1500,
          });
        });
    }
  };
  const handleQuestionChange = (index, event) => {
    const updatedQuestions = [...passageQuestions];
    updatedQuestions[index].description = event.target.value;
    setPassageQuestions(updatedQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, event) => {
    const updatedQuestions = [...passageQuestions];
    updatedQuestions[questionIndex].options[optionIndex] = event.target.value;
    setPassageQuestions(updatedQuestions);
  };

  const handleAnswerChange = (questionIndex, event) => {
    const updatedQuestions = [...passageQuestions];
    updatedQuestions[questionIndex].answer = event.target.value;
    setPassageQuestions(updatedQuestions);
  };
  const handleTypeChange = (questionIndex, event) => {
    const updatedQuestions = [...passageQuestions];
    updatedQuestions[questionIndex].level = event;
    setPassageQuestions(updatedQuestions);
  };
  const handlePointChange = (questionIndex, event) => {
    const updatedQuestions = [...passageQuestions];
    updatedQuestions[questionIndex].point = event;
    setPassageQuestions(updatedQuestions);
  };

  return (
    <>
      <ToastContainer />
      {contextHolder}

      <div className="d-flex">
        <Sider
          className={styles.slider}
          breakpoint="lg"
          collapsedWidth="0"
          width={window.innerWidth < 350 ? 200 : "18%"}
          trigger={null}
        >
          <DashboardSlider />
        </Sider>

        <div>
          {/* Navbar */}
          <LatestNavbar openDwa={openDwa} setOpenDwa={setOpenDwa} />
        </div>
        {/* Create Question Section */}
        <section className={styles.createquestion_main}>
          <div className={styles.leftside}>
            <Button
              style={{ marginBottom: "10px" }}
              onClick={() => setFlag(false)}
            >
              All Question
            </Button>
            <div>
              {" "}
              <AutoComplete
                className={styles.titleStyle}
                options={options}
                value={title}
                // onSelect={onSelect}
                onSearch={handleSearch}
              >
                <Input
                  size="large"
                  className={styles.titleStyle}
                  placeholder="Question Title"
                  onChange={(val) => setTitle(val.target.value)}
                />
              </AutoComplete>
              {type === "Passage" && (
                <p className={styles.passageTitle}>Reading Comprehension</p>
              )}
              {searching && <LoadingOutlined />}{" "}
            </div>
            <div className={styles.descriptioneditor}>
              <ReactQuill
                theme="snow"
                value={editorHtml}
                onChange={handleEditorChange}
                modules={modules}
                formats={formats}
                style={{ height: "250px", borderRadius: "5px" }}
                ref={editorRef}
                placeholder="Question Descriptions"
              />
            </div>
            {/* MCQ Options */}
            {type === "Mcq" && (
              <>
                <div className="d-flex mt-5 pt-3 justify-content-around">
                  <div>
                    <Form.Group className="d-flex align-items-center w-100">
                      <Form.Check
                        type="radio"
                        name="option"
                        checked={answer === Options.option1 && answer !== ""}
                        value={Options.option1}
                        className="mx-2"
                        onChange={(e) => setAnswers(e)}
                      />
                      <Form.FloatingLabel label="Option 1" className="Options">
                        <Form.Control
                          value={Options.option1}
                          name="option1"
                          onChange={(e) => Onchange(e)}
                        />
                      </Form.FloatingLabel>
                      <Tooltip
                        placement="top"
                        title="Check the radio button that is correct"
                      >
                        <InfoCircleOutlined
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                            color: "#1890ff",
                          }}
                        />
                      </Tooltip>
                    </Form.Group>

                    <Form.Group className="d-flex align-items-center mt-3">
                      <Form.Check
                        type="radio"
                        name="option"
                        checked={answer === Options.option3 && answer !== ""}
                        value={Options.option3}
                        className="mx-2"
                        onChange={(e) => setAnswers(e)}
                      />
                      <Form.FloatingLabel label="Option 3" className="Options">
                        <Form.Control
                          value={Options.option3}
                          name="option3"
                          onChange={(e) => Onchange(e)}
                        />
                      </Form.FloatingLabel>
                      <Tooltip
                        placement="top"
                        title="Check the radio button that is correct"
                      >
                        <InfoCircleOutlined
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                            color: "#1890ff",
                          }}
                        />
                      </Tooltip>
                    </Form.Group>
                  </div>
                  <div>
                    <Form.Group className="d-flex align-items-center">
                      <Form.Check
                        type="radio"
                        name="option"
                        checked={answer === Options.option2 && answer !== ""}
                        value={Options.option2}
                        className="mx-2"
                        onChange={(e) => setAnswers(e)}
                      />
                      <Form.FloatingLabel label="Option 2" className="Options">
                        <Form.Control
                          value={Options.option2}
                          name="option2"
                          onChange={(e) => Onchange(e)}
                        />
                      </Form.FloatingLabel>
                      <Tooltip
                        placement="top"
                        title="Check the radio button that is correct"
                      >
                        <InfoCircleOutlined
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                            color: "#1890ff",
                          }}
                        />
                      </Tooltip>
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center mt-3">
                      <Form.Check
                        type="radio"
                        name="option"
                        value={Options.option4}
                        checked={answer === Options.option4 && answer !== ""}
                        className="mx-2"
                        onChange={(e) => setAnswers(e)}
                      />
                      <Form.FloatingLabel label="Option 4" className="Options">
                        <Form.Control
                          value={Options.option4}
                          name="option4"
                          onChange={(e) => Onchange(e)}
                        />
                      </Form.FloatingLabel>
                      <Tooltip
                        placement="top"
                        title="Check the radio button that is correct"
                      >
                        <InfoCircleOutlined
                          style={{
                            cursor: "pointer",
                            marginLeft: "5px",
                            color: "#1890ff",
                          }}
                        />
                      </Tooltip>
                    </Form.Group>
                  </div>
                </div>
                <label style={{ marginLeft: "40px", marginTop: "10px" }}>
                  Please Mark the correct answer*
                </label>
              </>
            )}
            {/* {type === "Passage" && <Button className="mt-6">Add Questions</Button>} */}
            {type === "Passage" && (
              <div className={styles.mainPassageContainer}>
                {passageQuestions?.map((question, index) => (
                  <div className={styles.passage} key={index}>
                    <label>
                      Question : {index + 1}
                      <span
                        onClick={() => {
                          passageQuestions.splice(index, 1);
                          setPassageQuestions([...passageQuestions]);
                        }}
                      >
                        Close
                      </span>
                    </label>
                    <input
                      placeholder="Write question here...."
                      value={question.description}
                      onChange={(e) => handleQuestionChange(index, e)}
                    />
                    <section id={styles.passagePoint}>
                      <InputNumber
                        max={20}
                        placeholder="Point"
                        addonAfter={"Max " + 20 + " Point"}
                        style={{ width: "160px" }}
                        controls={false}
                        onChange={(val) => {
                          handlePointChange(index, val);
                        }}
                        value={question?.point}
                      />
                    </section>
                    <Select
                      placeholder="Select Difficulty"
                      // variant="filled"
                      value={question?.level ? question?.level : "Difficulty"}
                      onChange={(e) => handleTypeChange(index, e)}
                      size="large"
                      style={{
                        width: "160px",
                      }}
                      options={[
                        {
                          value: "Easy",
                          label: "Easy",
                        },
                        {
                          value: "Medium",
                          label: "Medium",
                        },
                        {
                          value: "Hard",
                          label: "Hard",
                        },
                      ]}
                    />

                    <div>
                      {question.options.map((option, optionIndex) => (
                        <Form.Group
                          className={styles.formGroup}
                          key={optionIndex}
                        >
                          <Form.Check
                            type="radio"
                            name={`option-${index}`}
                            value={option}
                            className="mx-2"
                            checked={
                              option === question?.answer &&
                              question?.answer !== ""
                            }
                            onChange={(e) => handleAnswerChange(index, e)}
                          />
                          <Form.FloatingLabel
                            label={`Option ${optionIndex + 1}`}
                            className="Options"
                          >
                            <Form.Control
                              value={option}
                              onChange={(e) =>
                                handleOptionChange(index, optionIndex, e)
                              }
                            />
                          </Form.FloatingLabel>
                          <Tooltip
                            placement="top"
                            title="Check the radio button that is correct"
                          >
                            <InfoCircleOutlined
                              style={{
                                cursor: "pointer",
                                marginLeft: "5px",
                                color: "#1890ff",
                              }}
                            />
                          </Tooltip>
                        </Form.Group>
                      ))}
                    </div>
                  </div>
                ))}
                <Button
                  disabled={passageQuestions.length >= 8}
                  style={{ marginTop: "20px" }}
                  onClick={() => {
                    setPassageQuestions([
                      ...passageQuestions,
                      {
                        description: "",
                        options: ["", "", "", ""],
                        answer: "",
                        level: "",
                        point: 0,
                      },
                    ]);
                  }}
                >
                  Add Questions
                </Button>
              </div>
            )}
            <div className="mt-5"> {/* Test Cases */}</div>
          </div>

          <div className={styles.rightside}>
            {/* Difficulty  */}
            <div className="d-flex flex-column">
              <span>Difficulty*</span>
              <Select
                placeholder="Select Difficulty"
                // variant="filled"
                value={level}
                onChange={(e) => setLevel(e)}
                size="large"
                style={{
                  width: "200px",
                }}
                options={[
                  {
                    value: "Easy",
                    label: "Easy",
                  },
                  {
                    value: "Medium",
                    label: "Medium",
                  },
                  {
                    value: "Hard",
                    label: "Hard",
                  },
                ]}
              />
            </div>
            {/* Question Type (Theory/Coding/MCQ) */}
            <div className="d-flex flex-column">
              <span>Question Type*</span>
              <Select
                placeholder="Select Question Type"
                // variant="filled"
                onChange={(e) => setType(e)}
                size="large"
                value={type}
                style={{
                  width: "200px",
                }}
                options={[
                  {
                    value: "Subjective",
                    label: "Subjective",
                  },
                  {
                    value: "Mcq",
                    label: "MCQ",
                  },
                  {
                    value: "Coding",
                    label: "Coding",
                  },
                  {
                    value: "Passage",
                    label: "Passage",
                  },
                ]}
              />
            </div>

            {/* Main Topic */}
            <div className="d-flex flex-column">
              <span>Subject*</span>
              <Select
                placeholder="Select Main Topic"
                // variant="filled"
                onChange={(val) => setMainTags(val)}
                size="large"
                style={{
                  width: "200px",
                }}
                options={MainTopics}
                value={MainTags}
              />
            </div>

            {/* SubTags */}
            <div className="d-flex flex-column">
              <span>Tags*</span>
              <Select
                placeholder="Select Sub Tag"
                // variant="filled"
                mode={access && access?.role === "Admin" ? "tags" : "multiple"}
                maxTagCount={"responsive"}
                onChange={(val) => setSelectedSubTopics(val)}
                size="large"
                style={{
                  width: "200px",
                }}
                options={Topics}
                value={selectedsubTopic}
              />
            </div>

            {/* Question Point */}
            <div className="d-flex flex-column">
              <span>Points (Optional)</span>
              <InputNumber
                max={5}
                addonAfter={"Max " + 5}
                style={{ width: "200px" }}
                controls={false}
                onChange={(val) => setPoints(val)}
                value={Points}
              />
            </div>
            {/* Question Time */}
            <div className="d-flex flex-column">
              <span>Question Time (Optional)</span>
              <InputNumber
                onChange={(val) => setTime(val)}
                value={time}
                max={QuestionWiseTime[type] ?? 300}
                addonBefore={`Max-${QuestionWiseTime[type] ?? 300}`}
                addonAfter={"/Sec"}
                style={{ width: "200px" }}
                controls={false}
                min={minutesMapping[type][level]}
              />
            </div>

            <div>
              <input
                type="file"
                accept=".png, .jpeg"
                multiple={true}
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="fileupload"
              />
              <label htmlFor="fileupload" className="fileuploadbutton">
                <UploadOutlined /> Upload
              </label>{" "}
              {selectedFiles?.length} files selected
            </div>
            <div className="d-flex ">
              <label className={`m-1`} style={{ width: "230px" }}>
                Attachments (PNG/JPEG)
              </label>
              <Upload
                accept=".png,.jpeg"
                listType="picture-card"
                // fileList={fileList}
                // onPreview={handlePreview}
                // onChange={handleChange}
                className="m-1"
              >
                {/* {fileList.length >= 8 ? null : uploadButton} */}
              </Upload>
              <Modal
                // open={previewOpenAns}
                // title={previewTitleAns}
                footer={null}
                // onCancel={handleCancelAns}
              >
                <img
                  alt="example"
                  style={{
                    width: "100%",
                  }}
                  // src={previewImageAns}
                />
              </Modal>
            </div>
            <div className="d-flex justify-content-center align-items-center w-100">
              {issendrequest ? (
                <Tag
                  bordered={false}
                  icon={<SyncOutlined spin />}
                  color="processing"
                >
                  {editdata ? "Updating" : "Creating"}
                </Tag>
              ) : (
                <Button
                  className="d-flex justify-content-center align-items-center w-100"
                  onClick={() => submit()}
                  disabled={type === "Mcq" && answer === ""}
                  ghost
                  type="primary"
                >
                  {editdata ? "Update" : "Submit"}
                </Button>
              )}
            </div>
          </div>
        </section>
      </div>
      <ProfileSlider setOpenDwa={setOpenDwa} openDwa={openDwa} />
    </>
  );
};

export default CreateQuestion;
